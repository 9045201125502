import React from 'react';
import { greatColors, menuControlItems, controls, mappingItems } from '../objects/Enums';
import { withTranslation } from 'react-i18next';
import ShareAndPaypal from './ShareAndPaypal';
 
export class TitleScreen extends React.Component {

    componentDidMount(){
    }
    
    
    render() {

        const { t } = this.props;
        let keyboardCount = this.props.players.filter(pl=>pl.controlledBy === controls.KEYBOARD || pl.controlledBy === controls.GAMEPAD).length ;
        let keyboardCount0 = this.props.players.filter(pl=>pl.controlledBy === controls.KEYBOARD && pl.controllerId === 0).length ;
        let keyboardCount1 = this.props.players.filter(pl=>pl.controlledBy === controls.KEYBOARD && pl.controllerId === 1).length ;
        let keyboardCount2 = this.props.players.filter(pl=>pl.controlledBy === controls.KEYBOARD && pl.controllerId === 2).length ;
        let keyboardCount3 = this.props.players.filter(pl=>pl.controlledBy === controls.KEYBOARD && pl.controllerId === 3).length ;

        return (
                <div className="TitleContent">

                    <div className="players noselect">

                        <div className="lobbyBoxPlus">
                            <span className="bigNumberWhiteSmall">{t('game.press')}</span>
                            
                            <p className="lobbyBoxPlusText spacer1">                                
                                {keyboardCount <= 4 &&
                                    keyboardCount0 === 0 ? <><div className="emptyKey"><span className="keyLetter">↑</span></div> {t('game.or')} </> 
                                    : keyboardCount1 === 0 ? <><div className="emptyKey"><span className="keyLetter">W</span></div> {t('game.or')} </>
                                    : keyboardCount2 === 0 ? <><div className="emptyKey"><span className="keyLetter">8</span></div> {t('game.or')} </>   
                                    : keyboardCount3 === 0 && <><div className="emptyKey"><span className="keyLetter">I</span></div> {t('game.or')} </>}
                                
                                <span className="xboxJoin">J</span>
                            </p>

                            <p className="lobbyBoxPlusText spacer2">
                                <br/>{t('game.toJoin')} <br/> {t('game.player2')} P{keyboardCount}
                            </p>
                        </div>


                        {this.props.players.map( pi=> {
                            
                            const style = {
                                backgroundColor : greatColors[pi.color]
                                ,backgroundImage: pi.type === 3 ?"url(/trike.png)":"url(/quad.png)"
                            }
                            
                            const fontClass = pi.specialFontClass!=null ? pi.specialFontClass : ''

                            return (
                            <div className="lobbyBox" key={pi.guid} style={style}>

                                {pi.controlledBy === controls.AI &&                                     
                                    <div className="onlyKeyboard">
                                        <div className="rotatedInfo">
                                            {t('game.onlyKeyboard')}<br/>
                                            {t('game.orGamepad')}
                                        </div>
                                    </div>
                                }

                                {/* <div className="playerType">
                                    { pi.controlledBy === controls.AI && <img className='playerTypeImg' src={process.env.PUBLIC_URL + '/processor.svg'} ></img>}
                                </div> */}


                                <div className="bigNumber">
                                    { pi.controlledBy === controls.AI && <>AI{pi.index}</>}
                                    { pi.controlledBy !== controls.AI && <>P{pi.index}</>}
                                    { pi.team > 0 && <span className="teamNumber">T{pi.team}</span>}
                                </div>

                                

                                <div className="plaseInfo">
                                    { pi.inMenuCurrent === menuControlItems.indexOf('controls') 
                                        && pi.isMapped 
                                        && <div className="playerPhaseControls">
                                            {t('game.move')}: <span className={fontClass}>{pi.controlTextMove} {pi.controlTextMoveAlt}</span><br/>
                                            {t('game.portal')}: <span className={fontClass}>{pi.controlTextPortal}</span><br/>
                                            {t('game.dial')}: <span className={fontClass}>{pi.controlTextDial}</span><br/>
                                            {t('game.hammer')}: <span className={fontClass}>{pi.controlTextRam}</span><br/>
                                            {t('game.mines')}: <span className={fontClass}>{pi.controlTextMines}</span><br/>
                                            {t('game.pause')}: <span className={fontClass}>{pi.controlTextPauseMenu}</span>

                                        </div>}
                                    
                                    { pi.inMenuCurrent === menuControlItems.indexOf('controls') 
                                        && !pi.isMapped 
                                        && <div className="playerPhaseControls">
                                            {t('game.setupKey')}<br/>
                                            {t('game.for')} "{t('control.'+ mappingItems[pi.mappingCurrent])}"<br/>
                                            <br/>
                                            {t('game.step')} {pi.mappingCurrent+1}/{mappingItems.length}<br/>
                                            {t('game.for2')} {(pi.mappingStepTimeout/1000).toFixed(0)} {t('game.sec')}
                                        </div>}

                                    { pi.inMenuCurrent === menuControlItems.indexOf('ready') 
                                        && this.props.startCountdownRunning 
                                        && <div className="phaseTextRdyCountdown">{this.props.startCountdown}
                                        </div>}

                                </div>


                                <div className="playerPhase">
                                    
                                    { pi.inMenuCurrent > 0 && pi.controlledBy !== controls.AI && <img src={process.env.PUBLIC_URL + '/arrowUp.svg'} alt="up" className="arrowUp" />}
                                    { (pi.inMenuCurrent === 0 || pi.controlledBy === controls.AI) && <div className="arrowUp" />}

                                    { (pi.inMenuCurrent === 0 || pi.controlledBy === controls.AI) && <span className="phaseTextSmaller">&nbsp;</span>}
                                    { pi.controlledBy !== controls.AI && pi.inMenuCurrent > 0 && <span className="phaseTextSmaller">{ t('menu.'+ menuControlItems[pi.inMenuCurrent -1])}</span>}

                                    { pi.inMenuCurrent === menuControlItems.indexOf('ready') && this.props.startCountdownRunning && <span className="phaseTextRdy">{ t('menu.'+ menuControlItems[pi.inMenuCurrent])}</span>}
                                    { pi.inMenuCurrent === menuControlItems.indexOf('ready') && !this.props.startCountdownRunning && <span className="phaseTextRdy">{ t('menu.'+ menuControlItems[pi.inMenuCurrent])}</span>}

                                    { pi.inMenuCurrent === menuControlItems.indexOf('controls') 
                                        && !pi.isSetCustomMapping 
                                        && <span className="phaseText">
                                            { t('menu.'+ menuControlItems[pi.inMenuCurrent])}
                                        </span>}

                                    { pi.inMenuCurrent !== menuControlItems.indexOf('ready')  
                                        && (pi.inMenuCurrent !== menuControlItems.indexOf('controls') || pi.isSetCustomMapping)
                                        && <>
                                            <span className="phaseText">
                                            <img src={process.env.PUBLIC_URL + '/arrowLe.svg'} alt="left" className="arrow" />
                                            { t('menu.'+ menuControlItems[pi.inMenuCurrent])}
                                            <img src={process.env.PUBLIC_URL + '/arrowRi.svg'} alt="right" className="arrow" />
                                            </span>
                                        </>}

                                    { (pi.inMenuCurrent === menuControlItems.length-1 || pi.controlledBy === controls.AI) && <span className="phaseTextSmaller">&nbsp;</span>}
                                    { pi.controlledBy !== controls.AI && pi.inMenuCurrent < menuControlItems.length-1 && <span className="phaseTextSmaller">{ t('menu.'+ menuControlItems[pi.inMenuCurrent +1])}</span>}


                                    { pi.inMenuCurrent < menuControlItems.length-1 && pi.controlledBy !== controls.AI && <img src={process.env.PUBLIC_URL + '/arrowDo.svg'} alt="down" className="arrowDown" />}
                                    { (pi.inMenuCurrent === menuControlItems.length-1 || pi.controlledBy === controls.AI) && <div className="arrowDown" />}

                                </div>
                            </div>
                            );
                            })
                        }

                    </div>

                    {/* share buttons */}
                    <ShareAndPaypal/>

                    <span className="cookies">
                        {t('game.cookieLine1')}<br/> 
                        {t('game.cookieLine2')}<br/>  
                        {t('game.cookieLine3')}<br/>
                        {t('game.fontLine')}<br/>
                    </span>
                </div>
            );

    }
}

export default withTranslation('common')(TitleScreen);