//enum tabulka klíčů
export var specialKeys = {
    8: "backspace", 9: "tab", 13: "return", 16: "shift", 17: "ctrl", 18: "alt", 19: "pause",
    20: "capslock", 27: "esc", 32: "space", 33: "pageup", 34: "pagedown", 35: "end", 36: "home",
    37: "left", 38: "up", 39: "right", 40: "down", 45: "insert", 46: "del",
    96: "0", 97: "1", 98: "2", 99: "3", 100: "4", 101: "5", 102: "6", 103: "7",
    104: "8", 105: "9", 106: "*", 107: "+", 109: "-", 110: ".", 111: "/",
    112: "f1", 113: "f2", 114: "f3", 115: "f4", 116: "f5", 117: "f6", 118: "f7", 119: "f8",
    120: "f9", 121: "f10", 122: "f11", 123: "f12", 144: "numlock", 145: "scroll", 191: "/", 224: "meta",
    403: "red", 404: "green", 405: "yellow", 406: "blue"
};


//enum typ ovládání
export var controls = {
    GAMEPAD: "Gamepad",
    KEYBOARD: "Keyboard",
    AI: "Artificial Inteligence"
};


//enum průběhu hry
export var playerPhaseEnum = {
    DEATH: "death",
    INMENU: "in menu",
    READY: "ready to game",
    GAME: "game"
};

//enum průběhu hry
export var gamePhaseEnum = {
    LOBBY: "loby",
    TUTORIAL: "tutorial",
    GAME: "game",
    RESULTS: "results",
    PAUSE: "pause"
};



//orientace brány
export var orientation = {
    VERTICAL: "vertical",
    HORIZONTAL: "horizontal"
};


//typ speciální zóny
export var zoneType = {
    ERASER: "eraser" /* mazající se zóna (úklid levelu) */,
    SNAKEU: "snakeu",
    SNAKED: "snaked",
    ROTODEATHR: "rotodeathr" /* tunel s rotátorem */,
    ROTODEATHL: "rotodeathl" /* tunel s rotátorem */,
    SPEEDTUNNEL: "speedtunnel",
    HEARTH: "hearth",
    MAZE: "maze",
    MONKEY: "monkey",
    OWL: "owl",
    RABBIT: "rabbit",
    KILLER: "killer"
};

//typy exploze
export var explosionType = {
    PLAYER: "player",
    CLEAR: "clear"
}

export var mappingType = {
    BUTTON: "button",
    AXIS: "axis"
}


export var connectionType = {
    PORTAL: "portal",
    DEATH: "death",
}



export var menuControlItems = ['leave', 'lang', 'musicVol','music','screen', 'AI color', 'time', 'team', 'controls', 'tracer', 'color', 'AI count', 'ready'];

//fáze konfigurace ovládání
export var mappingItems = ['up','down','left','right','portal','mines','hammer', 'dial' ,'pause'];


//skvělé barvy pro hru
export var greatColors = ['#1a77ea', '#a200FF', '#FF0000', '#FF8000', '#00FF00', '#fcFF00', '#00F6FF', '#FF05DB', '#FFFFFF', '#009e1a', '#75400b'
, '#0b755d'/* matna zelena */, '#191970'/*midnightblue*/, '#ffd700'/* gold */, '#696969' /* dimgray */, '#808000' /* olivova */, '#ff1493' /* deep pink */,
'#8b0000' /* darkred */, '#cd853f' /* peru */, '#fa8072' /* lososova */ ];


//export var greatColors = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#00FFFF', '#FF00FF', '#FFFFFF'];
// export var greatColors = ['#F44336', '#448AFF', '#388E3C', '#FFEB3B', '#FF9800', '#673AB7'];

//uděláme jim mix
greatColors = greatColors
.map((a) => ({sort: Math.random(), value: a}))
.sort((a, b) => a.sort - b.sort)
.map((a) => a.value);

//console.log(greatColors);


export var gateColor = '#ffffff33';