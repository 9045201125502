import { greatColors } from './Enums';

export default class Rotator {

    constructor(game, x, y, r, permanent, duration, id){

        this.game = game;

        //vzdy menší hodnota, než má konec
        this.x = Math.floor(x);
        this.y = Math.floor(y);
    
        this.color = Math.floor(Math.random() * greatColors.length);
    
        //životnost
        this.duration = duration;
        //nezničitelný
        this.permanent = permanent;

        //poloměr
        this.radius = r;

        //poloha čáry překážky
        this.step = 0;
        this.oldStep = 0;

        //identifikátor, pro hledání jiného portálu, než ten ze kterého jdu
        this.id = id;
    }


    timeoutsProcess () {

        //odpočet pauzy brány
        if (this.permanent === false && this.duration > 0){
            this.duration -= this.game.delay;
        }

        //posunuju rotátorem
        this.step += 0.05 * this.game.delay;

    };

    //update portálů
    update () {

        //zprocesuju odpočty
        this.timeoutsProcess();

    };


    drawHand(x,y,pos, length, width) {
        this.game.ctx.beginPath();
        this.game.ctx.lineWidth = width;
        this.game.ctx.lineCap = "round";
        this.game.ctx.moveTo(x,y);
        
        let xx = Math.cos(pos * Math.PI/120);
        let yy = Math.sin(pos * Math.PI/120);

        this.game.ctx.lineTo(this.x + (xx * length), this.y + (yy * length));
        this.game.ctx.stroke();
    }



    //kreslení portálů
    draw () {

        let fillStyle = this.game.ctx.fillStyle;
        let strokeStyle = this.game.ctx.strokeStyle;
        let InfofillStyle = this.game.infoctx.fillStyle;
        let InfostrokeStyle = this.game.infoctx.strokeStyle;

        //vykreslování bude jen v celých číselch (výpočet však bude v přesnějších)
        if (this.duration > 0) {
            
            //exploze pouze v info canvasu
            this.game.ctx.strokeStyle = "#999999FF";

            //smažu starou čáru
            this.game.ctx.globalCompositeOperation = 'destination-out';
            this.drawHand(this.x,this.y, this.oldStep , this.radius, 5);
            this.game.ctx.globalCompositeOperation = 'source-over';


            //nakreslím novou
            this.drawHand(this.x,this.y, this.step , this.radius, 2);

            //zapíšu pro vymazání
            this.oldStep = this.step;
        }

        this.game.infoctx.fillStyle = InfofillStyle;
        this.game.infoctx.strokeStyle = InfostrokeStyle;

        this.game.ctx.fillStyle = fillStyle;
        this.game.ctx.strokeStyle = strokeStyle;
    };

}
