import React from 'react';
import { gamePhaseEnum } from '../objects/Enums';
import { withTranslation } from 'react-i18next';

export class Countdown extends React.Component {

    render() {

        const { t } = this.props;

        const phase = this.props.phase; 

        return(
        <div className="countDown noselect">
            {this.props.gameCountdownMin.padStart(2, '0')}:{this.props.gameCountdownSec.padStart(2, '0')}
            {phase === gamePhaseEnum.PAUSE && <><br/><span className="pulsingPause">{t('game.pause')}</span></>}
        </div>
        );
    }
}

export default withTranslation('common')(Countdown);