import {} from './Enums';

/**
 * jedno konkrétní mapování, pamatuje si typ a index mapovaného talčítka
 * pokud jde o osu, udává, jestli lde o positive - +0 až 1 nebo negative 0 až -1
 */
export default class Mapping {

    constructor(type, index, isPositiveAxis){

        this.type = type;
        this.index = index;
        this.isPositiveAxis = isPositiveAxis;
    }
}