import { greatColors, explosionType } from './Enums';
import { clearCircle } from './Support'

export default class Explosion {


    //smer: 0- nahoru, 1-doprava, 2-dolu, 3-doleva
    constructor(game, x,y, greatColor, type, smer, speed, team){

        this.game = game;

        this.x = Math.floor(x);
        this.y = Math.floor(y);
    
        //this.color = Math.floor(Math.random() * greatColors.length);
        this.greatColor = greatColor;
    
        //životnost
        this.expansionDefault = 100;
        this.duration = 700;
        this.durationDefault = 700;

        this.nextLineTime = 0;

        this.explosion =false;

        this.bonusDropped = false;

        //typ exploze
        this.type = type;

        //vlastnosti z vozítka
        this.smer = smer;
        this.speed = speed;

        //posuv efektu dle smeru
        this.speedDiameter = 0;

        //pro pozice čar exploze (max 10)
        this.partsArray =[];

        //team toho kdo explodoval
        this.team = team;
    }


    timeoutsProcess () {

        //odpočet životnosti zóny
        if (this.duration > 0){
            this.duration -= this.game.delay;
        }

        //pro linky
        this.nextLineTime += this.game.delay;
        
        if(this.duration <= 0 && this.type === explosionType.PLAYER)
        {
            if(this.bonusDropped === false)
            {
                //vypadne bonus 10s
                this.game.addBonusPoint(this.x,this.y, false, 10000, 1, 0, this.team);
                this.bonusDropped = true;
            }
        }
    };

    //update portálů
    update () {

        //zprocesuju odpočty
        this.timeoutsProcess();

    };

    //kreslení explozí
    drawPlayerExplosion(){

        //vymazání kruhu

        if(this.duration>=0)
        {
            //exploze pouze v info canvasu
            this.game.infoctx.fillStyle = greatColors[this.greatColor];
            this.game.infoctx.strokeStyle = greatColors[this.greatColor];

            //expansionDefault
            let plus = (this.durationDefault - this.duration)* 0.1;
            
            if(plus > 9)
                plus = 9;

            let diameter = 30 / 81 * plus * plus;


            this.game.infoctx.beginPath();
            this.game.infoctx.arc(this.x, this.y, 7, 0, 2 * Math.PI);
            this.game.infoctx.fill();


            this.game.infoctx.filter = "blur(10px)";
            this.game.infoctx.beginPath();
            this.game.infoctx.lineWidth = 4;
            this.game.infoctx.arc(this.x, this.y, diameter*0.9, 0, 2 * Math.PI);
            this.game.infoctx.stroke();
            this.game.infoctx.filter = "none";



            this.game.infoctx.beginPath();
            this.game.infoctx.lineWidth = 4;
            this.game.infoctx.arc(this.x, this.y, diameter, 0, 2 * Math.PI);
            this.game.infoctx.stroke();



            let stepAmount = this.speed * this.game.movestep;

            //úprava nové pozice
            //smer: 0- nahoru, 1-doprava, 2-dolu, 3-doleva
            this.speedDiameter += stepAmount;

            this.game.infoctx.strokeStyle = greatColors[this.greatColor];
            this.game.infoctx.lineWidth = 0.5;
            
            this.game.infoctx.beginPath(); 
            this.game.infoctx.lineWidth = 1;
            this.game.infoctx.arc(this.x, this.y, this.speedDiameter, 0, 2 * Math.PI);
            this.game.infoctx.stroke();

            //this.game.ctx.clearRect(x - 16, y - 16, 32, 32);
            //pokus o kruhové mazání
            clearCircle(this.game.ctx, this.x, this.y, 40);

            this.explosion = false;
        }
    }

    //kreslení explozí
    drawEffectExplosion(){

        //vymazání kruhu

        if(this.duration>=0)
        {
            //exploze pouze v info canvasu
            this.game.infoctx.fillStyle = greatColors[this.greatColor];
            this.game.infoctx.strokeStyle = greatColors[this.greatColor];

            //this.game.ctx.clearRect(x - 16, y - 16, 32, 32);
            //pokus o kruhové mazání
            clearCircle(this.game.ctx, this.x, this.y, 40);

            this.explosion = false;
        }
    }



    //kreslení explozí
    draw () {

        let fillStyle = this.game.ctx.fillStyle;
        let strokeStyle = this.game.ctx.strokeStyle;


        if(this.type === explosionType.PLAYER){

            this.drawPlayerExplosion();
        }

        if(this.type === explosionType.CLEAR){

            this.drawEffectExplosion();
        }

        

        this.game.ctx.fillStyle = fillStyle;
        this.game.ctx.strokeStyle = strokeStyle;
    };

}
