import { greatColors, connectionType } from './Enums';

export default class Connection {

    constructor(game, bx, by, ex, ey, color, type){

        this.game = game;

        //vzdy menší hodnota, než má konec
        this.beginx = Math.floor(bx);
        this.beginy = Math.floor(by);

        //vždy vvětší hodnota, než má začátek
        this.endx = Math.floor(ex);
        this.endy = Math.floor(ey);
    
        //this.color = Math.floor(Math.random() * greatColors.length);
        this.color = color;
        this.type = type;
    
        //životnost
        this.connectionDuration = 500;

    }


    timeoutsProcess () {

        //odpočet pauzy brány
        if (this.connectionDuration > 0){
            this.connectionDuration -= this.game.delay;
        }

    };

    //update portálů
    update () {

        //zprocesuju odpočty
        this.timeoutsProcess();

    };

    //kreslení čáry při přesunu hráče
    draw () {

        let fillStyle = this.game.infoctx.fillStyle;
        let strokeStyle = this.game.infoctx.strokeStyle;

        //vykreslování bude jen v celých číselch (výpočet však bude v přesnějších)
        let bx = this.beginx;
        let by = this.beginy;

        let ex = this.endx;
        let ey = this.endy;

        if (this.connectionDuration > 0) {
            
            if(this.type === connectionType.PORTAL)
            {
                this.game.infoctx.strokeStyle = greatColors[this.color];
                this.game.infoctx.lineWidth = 2;
            }
            else
            {
                this.game.infoctx.strokeStyle = "#22222288";
                //this.game.infoctx.setLineDash([5, 15]);
                this.game.infoctx.lineWidth = 5;
            }

            //exploze pouze v info canvasu
            this.game.infoctx.beginPath();
            this.game.infoctx.moveTo(bx, by);
            this.game.infoctx.lineTo(ex, ey);
            this.game.infoctx.stroke();
        }

        this.game.infoctx.fillStyle = fillStyle;
        this.game.infoctx.strokeStyle = strokeStyle;
    };

}
