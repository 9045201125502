import React from 'react';
import { greatColors, controls } from '../objects/Enums';
import { withTranslation } from 'react-i18next';
import ShareAndPaypal from './ShareAndPaypal';
 
export class Results extends React.Component {

    componentDidMount(){
    }
    
    
    render() {

        const { t } = this.props;

        return (
                <div className="ResultsContent">
                    
                    <div className="results">
                        <h2>{t('game.scoreboard')}</h2>

                        <div className="resultsBox smallResultsBox">
                            <span className="resultName">{t('game.player')}</span>        
                            <span className="resultScore">{t('game.score')}</span>
                        </div>

                        {this.props.players.map( pi=> {
                            
                            const style = {
                                backgroundColor : greatColors[pi.color]
                                ,backgroundImage: pi.type === 3 ?"url(/trike.png)":"url(/quad.png)"
                            }    

                        return (
                            <div className="resultsBox" key={pi.index} >

                                <div className="resultImg" style={style} />

                                <span className="resultName">
                                    { pi.controlledBy === controls.AI && <>AI{pi.index}</>}
                                    { pi.controlledBy !== controls.AI && <>P{pi.index}</>}
                                    { pi.team > 0 && <span className="resultTeam">T{pi.team}</span>}
                                </span>

                                <span className="resultScore">{pi.score}</span>

                            </div>
                        );
                        })
                        }

                    </div>

                    {/* share buttons */}
                    <ShareAndPaypal/>

                </div>
            );

    }
}


export default withTranslation('common')(Results);