import { greatColors, zoneType, orientation } from './Enums';

export default class SpecialZone {

    constructor(game, permanent, type, duration, id){

        this.game = game;
        //typ zóny
        this.type = type;
        //identifikátor, pro hledání jiné zóny, než ta o kterou se zrovna jedná
        this.id = id;

        this.greatColor = Math.floor(Math.random() * greatColors.length);
        this.color = '#FFFFFF77';
    
        //nezničitelný
        this.permanent = permanent;

        //bylo vymazáno před vložením
        this.cleared = false;

        //životnost, pokud duration klesne k 0, rozhodnu o smazání
        this.durationDefault = duration;
        this.duration = this.durationDefault;
        this.toErase = false;

        this.toSwitch = 5000;
        this.toSwitchDefault = 5000;
        this.switch = false;


        //přesunu a upravím velikost dle typu
        this.moveAway();
    }

    //přesunutí zóny na nové místo
    moveAway(){
        
        let bottomHudsHeight = this.game.getBottomHudsHeight();
        let canvasWidth = this.game.canvas.width;
        let canvasHeight = this.game.canvas.height - bottomHudsHeight;

        let colide = true;
        let cycles = 0;
        let boxAdd = 150;

        while(colide === true){

            cycles++;
            if(cycles> 10 && boxAdd >=10)
            {
                //po deseti pokusech začnu box zmenšovat, celkem 30 cyklů do nuly
                boxAdd-=10;
            }

            if(cycles> 100)
            {
                //nelze umístit smažu jej
                this.toErase = true;
                console.log('mažu zónu, příliš malá plocha pro její umístění ' + this.type);
                break;
            }

            //střed        
            this.x = Math.floor(Math.random() * canvasWidth);
            this.y = Math.floor(Math.random() * canvasHeight);

            this.baseWidth = Math.floor(boxAdd + Math.random() * 100);
            this.baseHeight = Math.floor(boxAdd + Math.random() * 100);

            let borderSpacer = 0;


            if(this.type !== zoneType.ERASER){
                
                //větší mezera od okraje
                borderSpacer = 60;
            }

            if(this.type === zoneType.SNAKEU || this.type === zoneType.SNAKED)
            {
                //pevná velikost
                this.baseWidth = 80;
                this.baseHeight = 250;
            }

            if(this.type === zoneType.SPEEDTUNNEL)
            {
                //pevná velikost
                this.baseWidth = 200;
                this.baseHeight = 160;
            }

            if(this.type === zoneType.ROTODEATHR || this.type === zoneType.ROTODEATHL )
            {
                //pevná velikost
                this.baseWidth = 260;
                this.baseHeight = 60;
            }

            if(this.type === zoneType.HEARTH)
            {
                //pevná velikost
                this.baseWidth = 140;
                this.baseHeight = 140;
            }

            if(this.type === zoneType.MAZE)
            {
                //pevná velikost
                this.baseWidth = 140;
                this.baseHeight = 140;
            }

            if(this.type === zoneType.MONKEY)
            {
                //pevná velikost
                this.baseWidth = 240;
                this.baseHeight = 200;
            }

            if(this.type === zoneType.OWL)
            {
                //pevná velikost
                this.baseWidth = 160;
                this.baseHeight = 160;
            }

            if(this.type === zoneType.RABBIT)
            {
                //pevná velikost
                this.baseWidth = 160;
                this.baseHeight = 120;
            }

            if(this.type === zoneType.KILLER)
            {
                //pevná velikost
                this.baseWidth = 60;
                this.baseHeight = 60;
            }

            let presahXdoprava = (this.x + this.baseWidth/2) - (canvasWidth - (5+borderSpacer));
            //upravíme aby nebylo mimo hřiště
            if(presahXdoprava > 0){
                this.x -= presahXdoprava;
            }

            let presahXdoleva = (this.x - this.baseWidth/2) - (5+borderSpacer);
            //upravíme aby nebylo mimo hřiště
            if(presahXdoleva < 0){
                this.x -= presahXdoleva;
            }

            let presahYdolu = (this.y + this.baseHeight/2) - (canvasHeight - (borderSpacer));
            //upravíme aby nebylo mimo hřiště
            if(presahYdolu > 0){
                this.y -= presahYdolu;
            }

            let presahYnahoru = (this.y - this.baseHeight/2) - (5+borderSpacer);
            //upravíme aby nebylo mimo hřiště
            if(presahYnahoru < 0){
                this.y -= presahYnahoru;
            }


            this.width = this.baseWidth;
            this.height = this.baseHeight;

            this.previousWidth = this.baseWidth;
            this.previousHeight = this.baseHeight


            let playerColide = false;
            let portalColide = false;
            let zoneColide = this.game.findZoneZoneColision(this.x-10,this.y-10,this.baseWidth+20,this.baseHeight+20, this.id);
            
            if(this.type !== zoneType.ERASER)
            {
                //pokud nejde o danger kontroluju i kolizi s hráčem
                playerColide = this.game.findPlayerColision(this.x,this.y,this.baseWidth,this.baseHeight);
                portalColide = this.game.findPortalColision(this.x,this.y,this.baseWidth,this.baseHeight);
            }

            //generuju znova pokud koliduje kterákoliv možnost
            colide = zoneColide || playerColide || portalColide;
        }

        if(this.type === zoneType.SNAKEU)
        {
            //portál
            this.game.addPortal(this.x - 20, this.y - 125, this.x + 20, this.y - 125, orientation.HORIZONTAL, false, 100000, 0 );

            //bonus
            this.game.addBonusPoint(this.x,this.y + 100, false, 120000, 3, 0, 0);
        }

        if(this.type === zoneType.SNAKED)
        {
            //portál
            this.game.addPortal(this.x - 20, this.y + 125, this.x + 20, this.y + 125, orientation.HORIZONTAL, false, 100000, 0 );

            //bonus
            this.game.addBonusPoint(this.x,this.y - 100, false, 120000, 3, 0, 0);
        }        

        if(this.type === zoneType.SPEEDTUNNEL)
        {
            //portál
            this.game.addPortal(this.x - 100, this.y -78, this.x - 100, this.y - 42, orientation.VERTICAL, false, 100000, 0 );

            //bonus
            this.game.addBonusPoint(this.x - 60,this.y - 60, false, 120000, 3, 0, 0);

        }

        
        if(this.type === zoneType.ROTODEATHR)
        {
            //portál
            this.game.addPortal(this.x + 130, this.y -20, this.x  + 130, this.y + 20, orientation.VERTICAL, false, 100000, 0 );

            //bonus
            this.game.addBonusPoint(this.x - 110,this.y , false, 120000, 3, 0, 0);

            //test rotátoru
            this.game.addRotator(this.x, this.y, 26, false, 120000);
        }


        if(this.type === zoneType.ROTODEATHL)
        {
            //portál
            this.game.addPortal(this.x - 130, this.y -20, this.x  - 130, this.y + 20, orientation.VERTICAL, false, 100000, 0 );

            //bonus
            this.game.addBonusPoint(this.x + 110,this.y , false, 120000, 3, 0, 0);

            //test rotátoru
            this.game.addRotator(this.x, this.y, 26, false, 120000);
        }



        if(this.type === zoneType.HEARTH)
        {
            //portál
            this.game.addPortal(this.x -20, this.y +70, this.x +20, this.y + 70, orientation.HORIZONTAL, false, 100000, 0 );

            //bonus
            this.game.addBonusPoint(this.x, this.y , false, 120000, 3, 0, 0);
        }

        if(this.type === zoneType.MAZE)
        {
            //bonus
            this.game.addBonusPoint(this.x, this.y , false, 120000, 3, 0, 0);
        }

        if(this.type === zoneType.MONKEY)
        {
            //portál
            this.game.addPortal(this.x -40, this.y +60, this.x, this.y + 60, orientation.HORIZONTAL, false, 100000, 0 );

            //bonus
            this.game.addBonusPoint(this.x+20, this.y-80 , false, 120000, 1, 0, 0);
            this.game.addBonusPoint(this.x-20, this.y-80 , false, 120000, 1, 0, 0);

            this.game.addBonusPoint(this.x+100, this.y+80 , false, 120000, 1, 0, 0);
            this.game.addBonusPoint(this.x-100, this.y+80 , false, 120000, 1, 0, 0);
        }

        if(this.type === zoneType.OWL)
        {
            //portál
            this.game.addPortal(this.x -20, this.y +80, this.x + 20, this.y + 80, orientation.HORIZONTAL, false, 100000, 0 );

            //bonus
            this.game.addBonusPoint(this.x+20, this.y-50 , false, 120000, 2, 0, 0);
            this.game.addBonusPoint(this.x-20, this.y-50 , false, 120000, 2, 0, 0);
        }


        if(this.type === zoneType.RABBIT)
        {
            //portál
            this.game.addPortal(this.x +80, this.y+10, this.x + 80, this.y + 40, orientation.VERTICAL, false, 100000, 0 );

            //bonus
            this.game.addBonusPoint(this.x-65, this.y-25 , false, 120000, 3, 0, 0);
            
        }

        
        if(this.type === zoneType.KILLER)
        {
            if (Math.floor(Math.random() * 2)===0)
            {
                //energie
                this.game.addBonusPoint(this.x,this.y, false, 30000, 0, 1, 0);
            }
            else
            {
                //bonus
                this.game.addBonusPoint(this.x, this.y , false, 30000, 2, 0, 0);
            }
        }

    };



    timeoutsProcess () {

        //odpočet životnosti zóny
        if (this.duration > 0){
            this.duration -= this.game.delay;
        }

        //přepínač killera
        if(this.type === zoneType.KILLER)
        {
            if (this.toSwitch > 0){
                this.toSwitch -= this.game.delay;
            }
            else{
                this.toSwitch = this.toSwitchDefault;
                this.switch = !this.switch;
            }
        }

        //SNAKE - do odstranění
        if(this.type !== zoneType.ERASER)
        {
            if(this.duration <= 0)
            {
                //doba vypršela změníme na ganger, což jej smaže
                this.type = zoneType.ERASER;
            }

        }

        //ERASER - zmenšování
        if(this.type === zoneType.ERASER)
        {
            //výbuchy
            if ( this.duration <= 0){

                //zmenšování
                let speed = 1;

                if(this.width <= 1 || this.height <=1)
                {
                    speed = 6;
                }

                if(this.width - this.game.movestep * speed * 2 > 1)
                {
                    this.width -= this.game.movestep * speed * 2;
                }
                else
                {
                    this.width = 1;
                }

                if(this.height - this.game.movestep * speed * 2 > 1)
                {
                    this.height -= this.game.movestep * speed * 2;
                }
                else
                {
                    this.height = 1;
                }
            }
        }
    };

    //update portálů
    update () {

        //přesuny zóny
        if (this.width <= 1 && this.height <= 1)
        {
            if(this.permanent === true)
            {
                //přesunu
                this.moveAway();
                //reset počítadla pro zmenšování
                this.duration = this.durationDefault;
            }
            else
            {
                //smažu
                this.toErase = true;
            }
        }

        //zprocesuju odpočty
        this.timeoutsProcess();
    };

    //kreslení portálů
    draw () {

        let fillStyle = this.game.ctx.fillStyle;
        let strokeStyle = this.game.ctx.strokeStyle;

        //vzdy menší hodnota, než má konec
        this.beginx = Math.floor(this.x - this.width/2);
        this.beginy = Math.floor(this.y - this.height/2);

        //vždy vvětší hodnota, než má začátek
        this.endx =  Math.floor(this.width);
        this.endy =  Math.floor(this.height);

        if(this.type !== zoneType.ERASER){
            
            //pokud nejde o nebezpečí, na začátku smažu
            if(this.cleared === false)
            {
                this.game.ctx.beginPath();
                this.game.ctx.lineWidth = 2;
                this.game.ctx.clearRect(this.beginx - 20, this.beginy - 20 , this.endx + 40, this.endy + 40);

                this.cleared = true;
            }

            //odpočet
            this.game.infoctx.textAlign = "center";
            this.game.infoctx.fillStyle = "#ffffff33";
            this.game.infoctx.font = "30px expansivabold";
            this.game.infoctx.fillText((this.duration/1000).toFixed(0), this.x, this.y+10);

            //zakázaná oblast
            this.game.ctx.fillStyle = "#777777FF";
            this.game.ctx.strokeStyle = "#777777FF";
            

            //blikání před změnama
            if(this.type === zoneType.KILLER && this.toSwitch > 500 && this.toSwitch < 1000){
                //zakázaná oblast
                this.game.ctx.fillStyle = "#FFFFFFFF";
                this.game.ctx.strokeStyle = "#FFFFFFFF";
            }

            //kolizní bílá linka
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.strokeRect(this.beginx, this.beginy , this.endx, this.endy);
        }


        if(this.type === zoneType.SPEEDTUNNEL)
        {
            //mažu otvory
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.beginx -1 , this.beginy +121 , 2 , 38);

            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.beginx -1 , this.beginy + 1 , 2 , 38);

            //rozděluju
            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.beginx, this.y - 40);
            this.game.ctx.lineTo(this.beginx + 160, this.y - 40);
            this.game.ctx.stroke();

            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.beginx + 40, this.y);
            this.game.ctx.lineTo(this.beginx + 200, this.y);
            this.game.ctx.stroke();            
            
            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.beginx, this.y + 40);
            this.game.ctx.lineTo(this.beginx + 160, this.y + 40);
            this.game.ctx.stroke();
        }


        if(this.type === zoneType.SNAKEU)
        {            
            //mažu otvory
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x -20 , this.y -126 , 40 , 2);

            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x-40, this.y - 50);
            this.game.ctx.lineTo(this.x+10, this.y - 50);
            this.game.ctx.stroke();

            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x -10, this.y + 50);
            this.game.ctx.lineTo(this.x + 40, this.y + 50);
            this.game.ctx.stroke();
        }

        if(this.type === zoneType.SNAKED)
        {            
            //mažu otvory
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x -20 , this.y +124 , 40 , 2);

            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x -10, this.y - 50);
            this.game.ctx.lineTo(this.x +40, this.y - 50);
            this.game.ctx.stroke();

            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x -40, this.y + 50);
            this.game.ctx.lineTo(this.x +10, this.y + 50);
            this.game.ctx.stroke();
        }




        if(this.type === zoneType.ROTODEATHR)
        {
            //mažu otvory
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x + 129 , this.y -20 , 2 , 40);

            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x - 80, this.y);
            this.game.ctx.lineTo(this.x + 60, this.y);
            this.game.ctx.stroke();
        }

        if(this.type === zoneType.ROTODEATHL)
        {
            //mažu otvory
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x - 131 , this.y -20 , 2 , 40);

            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x - 80, this.y);
            this.game.ctx.lineTo(this.x + 60, this.y);
            this.game.ctx.stroke();
        }


        if(this.type === zoneType.HEARTH)
        {
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.strokeRect(this.x - 35, this.y -35 , 70, 70);

            //mažu otvory
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x - 20 , this.y +69 , 40 , 2);

            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x - 20 , this.y -36 , 40 , 2);
        }


        if(this.type === zoneType.MAZE)
        {
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.strokeRect(this.x - 50, this.y -50 , 100, 100);

            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.strokeRect(this.x - 30, this.y -30 , 60, 60);

            //mažu otvory
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x - 71 , this.y -10 , 2 , 20);

            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x + 69 , this.y -10 , 2 , 20);

            //mažu otvory
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x - 10 , this.y -51 , 20 , 2);

            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x - 10 , this.y +49 , 20 , 2);

            //mažu otvory
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x - 31 , this.y -10 , 2 , 20);

            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x + 29 , this.y -10 , 2 , 20);

            //mažu otvory
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.strokeRect(this.x - 12 , this.y -10 , 0 , 20);

            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.strokeRect(this.x + 12 , this.y -10 , 0 , 20);
            
        }

        if(this.type === zoneType.MONKEY)
        {
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;

            //vertikální
            //stred
            this.game.ctx.moveTo(this.x,this.y-80);
            this.game.ctx.lineTo(this.x,this.y);
            this.game.ctx.stroke();
            //spodni stred
            this.game.ctx.moveTo(this.x,this.y+60);
            this.game.ctx.lineTo(this.x,this.y+100);
            this.game.ctx.stroke();

            //spodni mantinely
            this.game.ctx.moveTo(this.x+40,this.y+20);
            this.game.ctx.lineTo(this.x+40,this.y+60);
            this.game.ctx.stroke();
            
            this.game.ctx.moveTo(this.x-40,this.y+20);
            this.game.ctx.lineTo(this.x-40,this.y+60);
            this.game.ctx.stroke();

            //horní mantinely
            this.game.ctx.moveTo(this.x+40,this.y-100);
            this.game.ctx.lineTo(this.x+40,this.y-60);
            this.game.ctx.stroke();
            
            this.game.ctx.moveTo(this.x-40,this.y-100);
            this.game.ctx.lineTo(this.x-40,this.y-60);
            this.game.ctx.stroke();

            //boční mantinely
            this.game.ctx.moveTo(this.x+80,this.y-60);
            this.game.ctx.lineTo(this.x+80,this.y+100);
            this.game.ctx.stroke();
            
            this.game.ctx.moveTo(this.x-80,this.y-60);
            this.game.ctx.lineTo(this.x-80,this.y+100);
            this.game.ctx.stroke();

            //vertikální
            this.game.ctx.moveTo(this.x-40,this.y+20);
            this.game.ctx.lineTo(this.x+40,this.y+20);
            this.game.ctx.stroke();
            
            this.game.ctx.moveTo(this.x-80,this.y-10);
            this.game.ctx.lineTo(this.x-40,this.y-10);
            this.game.ctx.stroke();

            this.game.ctx.moveTo(this.x+40,this.y-10);
            this.game.ctx.lineTo(this.x+80,this.y-10);
            this.game.ctx.stroke();

        }

        if(this.type === zoneType.OWL)
        {
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;

            //smazu rohy pravy horni
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x + 41 , this.y -81 , 40 , 2);

            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x + 79 , this.y -80 , 2 , 39);

            //smazu rohy levy horni
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x - 81 , this.y -81 , 40 , 2);

            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x - 81 , this.y -80 , 2 , 39);

            //smazu rohy pravy dolni
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x + 79 , this.y +61 , 2 , 39);

            //smazu rohy levy dolni
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x - 81 , this.y +61 , 2 , 39);

            //dolni linka
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x - 81 , this.y +79 , 160 , 2);

            //brisko
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.strokeRect(this.x - 20, this.y -20 , 40, 60);

            //vertikální
            //stred
            this.game.ctx.moveTo(this.x,this.y-40);
            this.game.ctx.lineTo(this.x,this.y-20);
            this.game.ctx.stroke();

            //pravy roh
            this.game.ctx.moveTo(this.x+40,this.y-80);
            this.game.ctx.lineTo(this.x+40,this.y-40);
            this.game.ctx.lineTo(this.x+80,this.y-40);
            this.game.ctx.stroke();

            //levy roh
            this.game.ctx.moveTo(this.x-40,this.y-80);
            this.game.ctx.lineTo(this.x-40,this.y-40);
            this.game.ctx.lineTo(this.x-80,this.y-40);
            this.game.ctx.stroke();

            //dole
            //pravy roh
            this.game.ctx.moveTo(this.x+20,this.y+80);
            this.game.ctx.lineTo(this.x+20,this.y+60);
            this.game.ctx.lineTo(this.x+80,this.y+60);
            this.game.ctx.stroke();

            //levy roh
            this.game.ctx.moveTo(this.x-20,this.y+80);
            this.game.ctx.lineTo(this.x-20,this.y+60);
            this.game.ctx.lineTo(this.x-80,this.y+60);
            this.game.ctx.stroke();

            //bludiste napravo
            this.game.ctx.moveTo(this.x+20,this.y-20);
            this.game.ctx.lineTo(this.x+60,this.y-20);
            this.game.ctx.lineTo(this.x+60,this.y+40);
            this.game.ctx.stroke();

            this.game.ctx.moveTo(this.x+40,this.y);
            this.game.ctx.lineTo(this.x+40,this.y+60);
            this.game.ctx.stroke();

            //bludiste nalevo
            this.game.ctx.moveTo(this.x-20,this.y-20);
            this.game.ctx.lineTo(this.x-60,this.y-20);
            this.game.ctx.lineTo(this.x-60,this.y+40);
            this.game.ctx.stroke();

            this.game.ctx.moveTo(this.x-40,this.y);
            this.game.ctx.lineTo(this.x-40,this.y+60);
            this.game.ctx.stroke();
        }


        if(this.type === zoneType.RABBIT)
        {
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;


            //mazání okrajů
            //pravy horni
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x + 41 , this.y -61 , 40 , 2);

            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x + 79 , this.y -60 , 2 , 120);

            //pravy spodni
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x + 61 , this.y +59 , 40 , 2);

            //smazu rohy levy horni
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x - 80 , this.y -61 , 19 , 2);

            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x - 81 , this.y -61 , 2 , 20);

            //smazu nad tapkou
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 2;
            this.game.ctx.clearRect(this.x - 81 , this.y+1 , 2 , 18);



            //horizontální
            //hlavicka
            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x-80,this.y-40);
            this.game.ctx.lineTo(this.x-60,this.y-40);
            this.game.ctx.stroke();

            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x-40,this.y-40);
            this.game.ctx.lineTo(this.x+20,this.y-40);
            this.game.ctx.stroke();

            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x-20,this.y-20);
            this.game.ctx.lineTo(this.x+60,this.y-20);
            this.game.ctx.stroke();

            //stred
            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x-80,this.y);
            this.game.ctx.lineTo(this.x-60,this.y);
            this.game.ctx.stroke();
            
            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x-40,this.y);
            this.game.ctx.lineTo(this.x+40,this.y);
            this.game.ctx.stroke();

            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x+60,this.y+10);
            this.game.ctx.lineTo(this.x+80,this.y+10);
            this.game.ctx.stroke();            

            //pod stredem
            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x-80,this.y+20);
            this.game.ctx.lineTo(this.x-60,this.y+20);
            this.game.ctx.stroke();
            
            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x-20,this.y+20);
            this.game.ctx.lineTo(this.x+20,this.y+20);
            this.game.ctx.stroke();

            //dole
            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x-60,this.y+40);
            this.game.ctx.lineTo(this.x-40,this.y+40);
            this.game.ctx.stroke();
            
            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x,this.y+40);
            this.game.ctx.lineTo(this.x+40,this.y+40);
            this.game.ctx.stroke();

            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x+60,this.y+40);
            this.game.ctx.lineTo(this.x+80,this.y+40);
            this.game.ctx.stroke();


            //vertikální
            //cumak
            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x-60,this.y-60);
            this.game.ctx.lineTo(this.x-60,this.y-40);
            this.game.ctx.stroke();

            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x-60,this.y);
            this.game.ctx.lineTo(this.x-60,this.y+20);
            this.game.ctx.stroke();

            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x-40,this.y-40);
            this.game.ctx.lineTo(this.x-40,this.y+40);
            this.game.ctx.stroke();

            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x-20,this.y+20);
            this.game.ctx.lineTo(this.x-20,this.y+60);
            this.game.ctx.stroke();

            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x+40,this.y-60);
            this.game.ctx.lineTo(this.x+40,this.y-20);
            this.game.ctx.stroke();

            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x+40,this.y);
            this.game.ctx.lineTo(this.x+40,this.y+40);
            this.game.ctx.stroke();

            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x+60,this.y-20);
            this.game.ctx.lineTo(this.x+60,this.y+10);
            this.game.ctx.stroke();

            this.game.ctx.beginPath();
            this.game.ctx.moveTo(this.x+60,this.y+40);
            this.game.ctx.lineTo(this.x+60,this.y+60);
            this.game.ctx.stroke();
        }

        if(this.type === zoneType.KILLER)
        {
            if(this.switch){
                //mažu otvory
                this.game.ctx.beginPath();
                this.game.ctx.lineWidth = 2;
                this.game.ctx.clearRect(this.x - 31 , this.y -15 , 2 , 30);

                this.game.ctx.beginPath();
                this.game.ctx.lineWidth = 2;
                this.game.ctx.clearRect(this.x + 29 , this.y -15 , 2 , 30);    

                //mažu linky okolo (aby to bylo krásné)
                this.game.ctx.beginPath();
                this.game.ctx.lineWidth = 2;
                this.game.ctx.clearRect(this.x - 15 , this.y -33 , 30 , 2);
                this.game.ctx.clearRect(this.x - 15 , this.y -29 , 30 , 2);

                this.game.ctx.beginPath();
                this.game.ctx.lineWidth = 2;
                this.game.ctx.clearRect(this.x - 15 , this.y +31 , 30 , 2);  
                this.game.ctx.clearRect(this.x - 15 , this.y +27 , 30 , 2);  
            }
            else{            
                //mažu otvory
                this.game.ctx.beginPath();
                this.game.ctx.lineWidth = 2;
                this.game.ctx.clearRect(this.x - 15 , this.y -31 , 30 , 2);

                this.game.ctx.beginPath();
                this.game.ctx.lineWidth = 2;
                this.game.ctx.clearRect(this.x - 15 , this.y +29 , 30 , 2);  

                //mažu linky okolo (aby to bylo krásné)
                this.game.ctx.beginPath();
                this.game.ctx.lineWidth = 2;
                this.game.ctx.clearRect(this.x - 33 , this.y -15 , 2 , 30);
                this.game.ctx.clearRect(this.x - 29 , this.y -15 , 2 , 30);

                this.game.ctx.beginPath();
                this.game.ctx.lineWidth = 2;
                this.game.ctx.clearRect(this.x + 31 , this.y -15 , 2 , 30);    
                this.game.ctx.clearRect(this.x + 27 , this.y -15 , 2 , 30);
            }
        }

        //mazající se zóna
        if(this.type === zoneType.ERASER){


            if(this.duration > 0)
            {
                this.game.infoctx.textAlign = "center";
                this.game.infoctx.fillStyle = "#ffffff33";
                this.game.infoctx.font = "30px expansivabold";
                this.game.infoctx.fillText((this.duration/1000).toFixed(0), this.x, this.y+10);
    
                //zakázaná oblast
                this.game.infoctx.lineWidth = 2;
                this.game.infoctx.strokeStyle = "#FFFFFF20";
                this.game.infoctx.strokeRect(this.beginx, this.beginy , this.endx, this.endy);  
            }
            else
            {
                //smazání předchozího obdelníku
                //vzdy menší hodnota, než má konec
                let prevBeginx =  Math.floor(this.x - this.previousWidth/2);
                let prevBeginy =  Math.floor(this.y - this.previousHeight/2);

                //vždy vvětší hodnota, než má začátek
                let prevEndx =  Math.floor(this.previousWidth);
                let prevEndy =  Math.floor(this.previousHeight);


                //mazací obdelník předchozích rozměrů
                this.game.ctx.strokeStyle = "#FF0000FF";
                this.game.ctx.globalCompositeOperation = 'destination-out';
                this.game.ctx.beginPath();
                this.game.ctx.lineWidth = 4;
                this.game.ctx.strokeRect(prevBeginx, prevBeginy , prevEndx, prevEndy);
                this.game.ctx.globalCompositeOperation = 'source-over';


                //pokud to má ještě rozměr, kreslím menší okno
                if(this.width >1 || this.height >1)
                {

                    //zakázaná oblast
                    this.game.infoctx.fillStyle = "#999999FF";
                    this.game.infoctx.strokeStyle = "#666666FF";
                    

                    if(this.width >35 && this.height >35)
                    {
                        this.game.infoctx.lineWidth = 2;
                        //vykřičník
                        this.game.infoctx.textAlign = "center";
                        this.game.infoctx.font = "25px expansivabold";
                        this.game.infoctx.fillText("!", this.x+1, this.y+13);

                        //trojuhelnik
                        this.game.infoctx.beginPath();
                        this.game.infoctx.moveTo(this.x,this.y+15);
                        this.game.infoctx.lineTo(this.x-15,this.y+15);
                        this.game.infoctx.lineTo(this.x,this.y-15);
                        this.game.infoctx.lineTo(this.x+15,this.y+15);
                        this.game.infoctx.lineTo(this.x,this.y+15);
                        this.game.infoctx.stroke();
                    }


                    //viditelná bílá linka
                    this.game.infoctx.beginPath();
                    
                    this.game.infoctx.lineWidth = 2;
                    this.game.infoctx.strokeRect(this.beginx, this.beginy , this.endx, this.endy);
                    
                    //kolizní bílá linka
                    this.game.ctx.beginPath();
                    this.game.ctx.lineWidth = 2;
                    this.game.ctx.strokeRect(this.beginx, this.beginy , this.endx, this.endy);
                    
                    //odložíme to co jsme kreslili naposled
                    this.previousWidth = this.width;
                    this.previousHeight = this.height;
                }
            }
        }

        this.game.ctx.fillStyle = fillStyle;
        this.game.ctx.strokeStyle = strokeStyle;
    };

}
