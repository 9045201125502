import React from 'react';
import { withTranslation } from 'react-i18next';
 
export class MusicPlayer extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            files:[
                {name:'Adamson - Tayalot', file:'Adamson - Tayalot.mp3', link: 'https://ektoplazm.com/free-music/lost-diamonds'},
                {name:'Bratenschneider - Means You', file:'Bratenschneider - Means You.mp3', link: 'https://ektoplazm.com/free-music/lost-diamonds'},
                {name:'Code Therapy - Pulse', file:'Code Therapy - Pulse.mp3', link: 'https://ektoplazm.com/free-music/lost-diamonds'},
                {name:'Ben Rama - VALIS (Crennwiick Remix)', file:'Ben Rama - VALIS (Crennwiick Remix).mp3', link: 'https://ektoplazm.com/free-music/digital-decade'},
                {name:'Simmetune - Simplify', file:'Simmetune - Simplify.mp3', link: 'https://ektoplazm.com/free-music/digital-family-vol-6'},
                {name:'Bratenschneider - Universal Control', file:'Bratenschneider - Universal Control.mp3', link: 'https://ektoplazm.com/free-music/digital-family-vol-6'},
                {name:'Code Therapy - Acid Rain', file:'Code Therapy - Acid Rain.mp3', link: 'https://ektoplazm.com/free-music/digital-family-vol-6'},
                {name:'Søren Nordström - Cyclic', file:'Søren Nordström - Cyclic.mp3', link: 'https://ektoplazm.com/free-music/hive-mind-volume-one'},
                {name:'Quirk - Superstructure', file:'Quirk - Superstructure.mp3', link: 'https://ektoplazm.com/free-music/hive-mind-volume-one'}
            ],
            playOrder:[0,1,2,3,4,5,6,7,8],
            currentPlaybackFileID: 0,
            playng: false,
            forceShow: false,
            volume: 0.2
        }
    }

    playStop(){

        let player = this.refs.mPlayer;

        if(!player.paused){
            player.pause();
        }
        else{
            player.play();
        }

        this.setState({
            forceShow: true
            }, ()=> {
        });

        setTimeout(() => {
            this.setState({
                forceShow: false
                }, ()=> {
            });  
        }, 500);          
    }

    next(){

        let player = this.refs.mPlayer;
        
        //určíme další soubor 
        let next = this.state.currentPlaybackFileID+1;
        if(next > this.state.files.length-1)
        {
            next = 0;
        }

        this.setState({
            currentPlaybackFileID: next
          }, ()=> {
            //zatím nic
            player.load();
            player.play();
            console.log('next loading ' + next);
          });

    }

    //zhlasení hudby
    volumeUp(){
        let player = this.refs.mPlayer;

        if(player.volume <= 0.95){
            player.volume = (player.volume + 0.05).toFixed(2);

        }
            

        this.setState({
            volume: player.volume,
            forceShow: true
            }, ()=> {
        });

        setTimeout(() => {
            this.setState({
                forceShow: false
                }, ()=> {
            });  
        }, 500);            
    }

    //ztišení hudby
    volumeDown(){
        let player = this.refs.mPlayer;
        
        if(player.volume >= 0.05){
            player.volume = (player.volume - 0.05).toFixed(2);

        }

        this.setState({
            volume: player.volume,
            forceShow: true
            }, ()=> {
        });

        setTimeout(() => {
            this.setState({
                forceShow: false
                }, ()=> {
            });  
        }, 500);            
    }

    componentDidMount(){

        this.props.onRef(this)

        let player = this.refs.mPlayer;
        player.volume = this.state.volume;

        //přeskládání pořadí přehrávání
        let shuffled = this.state.playOrder
        .map((a) => ({sort: Math.random(), value: a}))
        .sort((a, b) => a.sort - b.sort)
        .map((a) => a.value);

        this.setState({
            playOrder: shuffled
          }, ()=> {
              console.log(this.state.playOrder);
        });  


        //přehrávání dalších písniček po konci předchozí
        player.addEventListener('ended', (event) => {
            this.next(); 
        });

        //po spuštění přehrávání
        player.addEventListener('play', (event) => {
            
            console.log('play');
            this.setState({
                playng: !player.paused,
                forceShow: true
              }, ()=> {
            });

            setTimeout(() => {
                this.setState({
                    forceShow: false
                  }, ()=> {
                });  
            }, 500);
        });

        //po zapauzování přehrávače
        player.addEventListener('pause', (event) => {
            
            console.log('pauza');
            this.setState({
                playng: !player.paused
              }, ()=> {
              });
        });
    }
    
    
    render() {

        // const { t } = this.props;

        const style = {
            backgroundImage: 'url(' + (this.state.playng?'/pause.svg':'/play.svg') + ')'
        }

        const playerClass = this.state.forceShow? "musicPlayer": "musicPlayer withAutoHide"

        return (
                <div className={playerClass}>
                    <div className="playButton noselect" style={style} onClick={this.playStop.bind(this)}></div>
                    <div className="nextButton noselect" onClick={this.next.bind(this)}></div>
                    <div className="songName">
                        <a href={this.state.files[this.state.playOrder[this.state.currentPlaybackFileID]].link} target="_blank" rel="noopener noreferrer">{this.state.files[this.state.playOrder[this.state.currentPlaybackFileID]].name}</a><br/>
                        <span className="noselect">volume: {(this.state.volume *100).toFixed(0)}</span>
                        <span className="plusminusButton noselect" onClick={this.volumeUp.bind(this)}>+</span>
                        <span className="plusminusButton noselect" onClick={this.volumeDown.bind(this)}>-</span>
                    </div>
                    
                    <audio ref="mPlayer" autoPlay={true}><source src={'music/'+this.state.files[this.state.playOrder[this.state.currentPlaybackFileID]].file} /></audio>
                </div>
            );

    }
}


export default withTranslation('common')(MusicPlayer);