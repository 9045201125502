import { specialKeys } from "./Enums"


//reprezentace klávesy
function keyName(event) {
    return specialKeys[event.which] || String.fromCharCode(event.which).toLowerCase();
};

function rgbToHex(rgb) {
    var hex = Number(rgb).toString(16);
    if (hex.length < 2) {
        hex = "0" + hex;
    }
    return hex;
};

function fullColorHex(r, g, b) {
    var red = this.rgbToHex(r);
    var green = this.rgbToHex(g);
    var blue = this.rgbToHex(b);
    return red + green + blue;
};

/**
 * tohle umí vymazat kruh skrz nastavení kreslení
 */
function clearCircle(context, x, y, radius){

    context.globalCompositeOperation = 'destination-out';
    context.beginPath();
    context.arc(x, y, radius, 0, Math.PI*2, true);
    context.fill();
    context.globalCompositeOperation = 'source-over';
};

/**
 * generátor guid kódů pro hráče
 */
function createGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
       var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
       return v.toString(16);
    });
 }



export {keyName, rgbToHex, fullColorHex, clearCircle, createGUID};
