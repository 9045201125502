import React from 'react';
import { withTranslation } from 'react-i18next';
 
export class Tutorial extends React.Component {

    componentDidMount(){
    }    
    
    render() {

        const { t } = this.props;

        return (                
            <div className="Tutorial">
                <div className='tutorialEscape'>{t('tutorial.tutorialEscape')}</div>
                {/* <h2>{t('game.tutorial')}</h2> */}
                <h2>{t('tutorial.tutorial')} <img src={process.env.PUBLIC_URL + '/colect.png'} alt="plus" /></h2>
                {t('tutorial.line1')}<br/>
                {t('tutorial.line2')}<br/>
                {t('tutorial.line3')}<br/>
                <br/>
                <h3>{t('tutorial.unstablePortals')} <img src={process.env.PUBLIC_URL + '/portal.png'} alt="portal" /></h3>
                {t('tutorial.unstablePortalsLine1')}<br/>
                {t('tutorial.unstablePortalsLine2')}<br/>
                <br/>
                <h3>{t('tutorial.specialAbilities')}</h3>
                <div class="container">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="row">
                                    <h4 class="tableH4">
                                        {t('tutorial.mines')} <img src={process.env.PUBLIC_URL + '/missile-swarm.svg'} alt={t('tutorial.mines')} className='PowerUp'/>
                                        &nbsp;<img src={process.env.PUBLIC_URL + '/miny.png'} alt={t('tutorial.mines')} />
                                    </h4>
                                    <p class="tableText">{t('tutorial.minesLine1')}</p>
                                    
                                </div>
                            </div>
                            <div class="col-md-3">
                                <h4 class="tableH4">
                                {t('tutorial.hammer')} <img src={process.env.PUBLIC_URL + '/thor-hammer.svg'} alt={t('tutorial.hammer')} className='PowerUp' />
                                &nbsp;<img src={process.env.PUBLIC_URL + '/kladivo.png'} alt={t('tutorial.hammer')} />
                                </h4>
                                <p class="tableText">
                                    {t('tutorial.hammerLine1')}
                                </p>
                            </div>
                            <div class="col-md-3">
                                <h4 class="tableH4">
                                {t('tutorial.portal')} <img src={process.env.PUBLIC_URL + '/magic-portal.svg'} alt={t('tutorial.portal')} className='PowerUp' />
                                &nbsp;<img src={process.env.PUBLIC_URL + '/natrase.png'} alt={t('tutorial.portal')} />
                                </h4>
                                <p class="tableText">
                                    {t('tutorial.portalLine1')}
                                </p>
                            </div>
                            <div class="col-md-3">
                                <h4 class="tableH4">
                                {t('tutorial.address')} <img src={process.env.PUBLIC_URL + '/address.png'} alt={t('tutorial.address')} className='' />
                                </h4>
                                <p class="tableText">
                                    {t('tutorial.addressLine1')}
                                </p>
                                
                            </div>
                        </div>
                </div>
                <br/>

                <h3>{t('tutorial.otherImportant')}</h3>
                <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <h4 class="tableH4">
                                        {t('tutorial.tracerAnd')}
                                        &nbsp;<img src={process.env.PUBLIC_URL + '/speedslow.png'} alt={t('tutorial.tracerAnd')} />
                                    </h4>
                                    <p class="tableText">
                                        {t('tutorial.tracerAndLine1')}<br/>
                                        {t('tutorial.tracerAndLine2')}<br/>
                                        {t('tutorial.tracerAndLine3')}<br/>
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <h4 class="tableH4">
                                    {t('tutorial.deathZones')}
                                </h4>
                                <p class="tableText">
                                    {t('tutorial.deathZonesLine1')}<br/>
                                    {t('tutorial.deathZonesLine2')}<br/>
                                    {t('tutorial.deathZonesLine3')}<br/>
                                </p>
                            </div>
                        </div>
                </div>
                <br/>
            </div>
        );

    }
}


export default withTranslation('common')(Tutorial);