import { greatColors, gateColor } from './Enums';

export default class Portal {

    constructor(game, bx, by, ex, ey, ori, permanent, duration, id, dialCode){

        this.game = game;

        //vzdy menší hodnota, než má konec
        this.beginx = Math.floor(bx);
        this.beginy = Math.floor(by);

        //vždy vvětší hodnota, než má začátek
        this.endx = Math.floor(ex);
        this.endy = Math.floor(ey);

        //střed portálu, optimalizace
        let dx = Math.abs(this.beginx - this.endx)/2;
        let dy = Math.abs(this.beginy - this.endy)/2;
        this.sx =  this.beginx < this.endx ? this.beginx+dx : this.endx+dx;
        this.sy =  this.beginy < this.endy ? this.beginy+dy : this.endy+dy;

        
        this.rnd1x = this.bx; 
        this.rnd1y = this.by;

        this.rnd2x = this.ex;
        this.rnd2y = this.ey;

    
        this.color = Math.floor(Math.random() * greatColors.length);
        this.ori = ori;
    

        //odložený start portálu
        this.delayedInitDefault = 200;
        this.delayedInit = this.delayedInitDefault;


        //životnost
        this.portalDuration = duration;
        //nezničitelný
        this.permanent = permanent;

        this.changeColorTimeout = 0;
        this.changeColorTimeoutDefault = 100;

        this.shakeGateTimeout = 0;
        this.shakeGateTimeoutDefault = 50;

        //identifikátor, pro hledání jiného portálu, než ten ze kterého jdu
        this.id = id;

        //before cahnge TargetCode
        this.startTargetCodeChanging = 10000;

        this.arrowAnimationCycle = 1000;
        this.arrowAnimationCycleDefault = 1000;

        this.arrowPosition = 0;
        this.displayArrows = true;

        //náhodný dial
        this.targetCode = 1 + Math.floor(Math.random() * 4);
        if(dialCode > 0){
            this.targetCode = dialCode;
        }
    }

    timeoutsProcess () {

        //odložený start portálu
        if (this.delayedInit > 0){
            this.delayedInit -= this.game.delay;
        }

        //odpočet trvání brány
        if (this.permanent === false && this.delayedInit <=0 && this.portalDuration > 0){
            this.portalDuration -= this.game.delay;
        }

        if(this.changeColorTimeout >0)
        {
            this.changeColorTimeout -= this.game.delay
        }
        else
        {
            this.changeColorTimeout = this.changeColorTimeoutDefault;
            let ok = false;
            while(ok===false)
            {
                let newColor = Math.floor(Math.random() * greatColors.length);
                if(newColor !== this.color)
                {
                    this.color = newColor;
                    ok = true;
                }
            }
        }


        if(this.shakeGateTimeout >0)
        {
            this.shakeGateTimeout -= this.game.delay
        }
        else
        {
            this.shakeGateTimeout = this.shakeGateTimeoutDefault;
            
            this.rnd1x = this.beginx -2 + Math.floor(Math.random() * 5);
            this.rnd1y = this.beginy - 2 + Math.floor(Math.random() * 5);

            this.rnd2x = this.endx -2 + Math.floor(Math.random() * 5);
            this.rnd2y = this.endy - 2 + Math.floor(Math.random() * 5);            
        }


        if(this.startTargetCodeChanging > 0)
        {
            this.startTargetCodeChanging -= this.game.delay
        }


        if(this.arrowAnimationCycle > 0){
            this.arrowAnimationCycle -= this.game.delay

            this.arrowPosition = 5 + (5 / this.arrowAnimationCycleDefault) * this.arrowAnimationCycle;
        }
        else{
            this.arrowAnimationCycle = this.arrowAnimationCycleDefault;
        }
    };
    
    /**
     * změna cílové adresy
     */
    changeTargetCode () {

        if(this.startTargetCodeChanging <= 0){

            //náhodně určím adresu cíle 1-4 tečky
            this.targetCode = 1 + Math.floor(Math.random() * 4);
        }
    };


    //update portálů
    update () {

        //zprocesuju odpočty
        this.timeoutsProcess();

        if(this.displayArrows === true && this.game.gameCountdown < (this.game.gameCountdownDefault - 60000))
        {
            this.displayArrows = false;
        }

    };

    //kreslení portálů
    draw () {

        let fillStyle = this.game.ctx.fillStyle;
        let strokeStyle = this.game.ctx.strokeStyle;
        let InfofillStyle = this.game.infoctx.fillStyle;
        let InfostrokeStyle = this.game.infoctx.strokeStyle;


        //vykreslování bude jen v celých číselch (výpočet však bude v přesnějších)
        let bx = this.beginx;
        let by = this.beginy;

        let ex = this.endx;
        let ey = this.endy;

        if (this.delayedInit <=0 && this.portalDuration > 0) {
            
            //exploze pouze v info canvasu
            this.game.infoctx.fillStyle = "#FFFFFF";
            //this.game.infoctx.strokeStyle = "#FFFFFF";
            this.game.infoctx.strokeStyle = greatColors[this.color];

            this.game.infoctx.beginPath();
            this.game.infoctx.lineWidth = 3;
            this.game.infoctx.moveTo(bx, by);
            
            //this.game.infoctx.lineTo(bx -2 + Math.floor(Math.random() * 4), by - 2 + Math.floor(Math.random() * 4));
            this.game.infoctx.lineTo(this.rnd1x, this.rnd1y);
            this.game.infoctx.lineTo(this.rnd2x, this.rnd2y);

            //this.game.infoctx.lineTo(bx + (ex-bx)*0.33 -2 + Math.floor(Math.random() * 4), by + (ey-by)*0.33 - 2 + Math.floor(Math.random() * 4));
            //this.game.infoctx.lineTo(bx + (ex-bx)*0.66 -2 + Math.floor(Math.random() * 4), by + (ey-by)*0.66 - 2 + Math.floor(Math.random() * 4));

            //this.game.infoctx.lineTo(ex -2 + Math.floor(Math.random() * 4), ey - 2 + Math.floor(Math.random() * 4));
            
            this.game.infoctx.lineTo(ex, ey);
            this.game.infoctx.stroke();

            //smažu starou čáru
            this.game.ctx.globalCompositeOperation = 'destination-out';
            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 3;
            this.game.ctx.moveTo(bx, by);
            this.game.ctx.lineTo(ex, ey);
            this.game.ctx.stroke();
            this.game.ctx.globalCompositeOperation = 'source-over';


            //nakreslím novou
            this.game.ctx.strokeStyle = gateColor;

            this.game.ctx.beginPath();
            this.game.ctx.lineWidth = 3;
            this.game.ctx.moveTo(bx, by);
            this.game.ctx.lineTo(ex, ey);
            this.game.ctx.stroke();

            //počet breaků na vykreslovaném portálu je o jedno menší, než jeho adresa
            let lineBreaks = this.targetCode - 1;

            let partX = (this.rnd2x-this.rnd1x) / ((lineBreaks*2) +1);
            let partY = (this.rnd2y-this.rnd1y) / ((lineBreaks*2) +1);
            this.game.infoctx.strokeStyle = "#000000";
            //targetCode
            for(let part= 0; part< (lineBreaks*2)+1; part++)
            {
                //každý lichý blok
                if(part%2===1)
                {
                    this.game.infoctx.beginPath();
                    this.game.infoctx.lineWidth = 6;
                    this.game.infoctx.moveTo(this.rnd1x+(partX*part), this.rnd1y+(partY*part));
                    this.game.infoctx.lineTo(this.rnd1x+(partX*(part+1)), this.rnd1y+(partY*(part+1)));
                    this.game.infoctx.stroke();
                }
            }


            if(this.displayArrows){
                
                //šipky
                this.game.infoctx.strokeStyle = "#FFFFFF";
                this.game.infoctx.beginPath();
                this.game.infoctx.lineWidth = 2;

                if(bx==ex){
                    this.game.infoctx.moveTo(bx + this.arrowPosition , (by + ey)/2);
                    this.game.infoctx.lineTo(bx + this.arrowPosition + 10, (by + ey)/2);
                    this.game.infoctx.moveTo(bx + this.arrowPosition , (by + ey)/2);
                    this.game.infoctx.lineTo(bx + this.arrowPosition + 5, (by + ey)/2 +3 );
                    this.game.infoctx.moveTo(bx + this.arrowPosition , (by + ey)/2);
                    this.game.infoctx.lineTo(bx + this.arrowPosition + 5, (by + ey)/2 -3);

                    this.game.infoctx.moveTo(bx - this.arrowPosition , (by + ey)/2);
                    this.game.infoctx.lineTo(bx - this.arrowPosition - 10, (by + ey)/2);
                    this.game.infoctx.moveTo(bx - this.arrowPosition , (by + ey)/2);
                    this.game.infoctx.lineTo(bx - this.arrowPosition - 5, (by + ey)/2 +3 );
                    this.game.infoctx.moveTo(bx - this.arrowPosition , (by + ey)/2);
                    this.game.infoctx.lineTo(bx - this.arrowPosition - 5, (by + ey)/2 -3);
                }
                else{
                    this.game.infoctx.moveTo((bx + ex)/2, by + this.arrowPosition);
                    this.game.infoctx.lineTo((bx + ex)/2, by + this.arrowPosition + 10);
                    this.game.infoctx.moveTo((bx + ex)/2, by + this.arrowPosition);
                    this.game.infoctx.lineTo((bx + ex)/2 +3, by + this.arrowPosition + 5);
                    this.game.infoctx.moveTo((bx + ex)/2, by + this.arrowPosition);
                    this.game.infoctx.lineTo((bx + ex)/2 -3, by + this.arrowPosition + 5);

                    this.game.infoctx.moveTo((bx + ex)/2, by - this.arrowPosition);
                    this.game.infoctx.lineTo((bx + ex)/2, by - this.arrowPosition - 10);
                    this.game.infoctx.moveTo((bx + ex)/2, by - this.arrowPosition);
                    this.game.infoctx.lineTo((bx + ex)/2 +3, by - this.arrowPosition - 5);
                    this.game.infoctx.moveTo((bx + ex)/2, by - this.arrowPosition);
                    this.game.infoctx.lineTo((bx + ex)/2 -3, by - this.arrowPosition - 5);
                }
                        
                this.game.infoctx.stroke();
            }

        }

        this.game.infoctx.fillStyle = InfofillStyle;
        this.game.infoctx.strokeStyle = InfostrokeStyle;

        this.game.ctx.fillStyle = fillStyle;
        this.game.ctx.strokeStyle = strokeStyle;
    };

}
