import { controls, playerPhaseEnum, greatColors, orientation, gateColor, explosionType, menuControlItems, gamePhaseEnum, mappingItems, mappingType, connectionType } from './Enums';
import { rgbToHex, clearCircle, createGUID } from './Support'
import Mapping from './Mapping';



export default class Player {

    constructor(controlledBy, controllerId, colorId, game, isDemo){

        this.game = game;

        //unikátní index hráče, nastaví se před výpisem výsledků a seřazením
        this.uniqueIndex = -1;
        this.guid = createGUID();

        let bottomHudsHeight = this.game.getBottomHudsHeight();

        let resetWidth = game.canvas.width - 200;
        let resetHeight = game.canvas.height - bottomHudsHeight - 200;
    
        this.x = this.oldx = this.lastCheckX = Math.floor(100 + Math.random() * resetWidth);
        this.y = this.oldy = this.lastCheckY = Math.floor(100 + Math.random() * resetHeight);
    

        //poslední blízká stěna
        this.lastNeighborX = this.x;
        this.lastNeighborY = this.y;
        
        //200ms
        this.neighborDetectionInterval = 200;
        this.LastNeighborDetection = Math.floor(Math.random() * 200);
        this.neighborClose1 = false;
        this.neighborClose2 = false;
        
        //místo kam udeří blesk
        this.storm1x = this.x;
        this.storm1y = this.y;

        this.storm2x = this.x;
        this.storm2y = this.y;


        //čas do efektíku výboje
        this.toStorm = 5000 + Math.floor(Math.random() * 2000);
        this.StormPositionSet = false;


        this.color = colorId;
        
        //3 cyber-trike, 4 cyber-truck, 
        this.type = Math.floor(Math.random() * 2) + 2;
    
        //základní rychlost všech
        this.baseSpeed = 3;
        //zrychlení nebo zpomalení
        this.bonusSpeed = 0;
        this.maxBonusSpeed = 1.5;
        this.breaking = false;
    
        //skóre
        this.score = 0;
        this.isFirst = false;

        //tým 0 - bez týmu
        this.team = 0;

        this.deaths = 0;

        //pokud takto označíme hráče, tak jej hra odstraní ze hry
        this.markedForRemove = false;

        //0- nahoru, 1-doprava, 2-dolu, 3-doleva
        this.smer = Math.floor(Math.random() * 4);
        this.cycleWithoutNewSmer = false;

        //vzdálenost po kerou jedu rovně
        this.strightLenght = 0;
        //doba po kterou jedu rovně
        this.strightTime =0;
        this.maxLine = 0;
    
        //jak je hráč ovládaný
        this.controlledBy = controlledBy;
        //je to demo AI, bez interface do menu předtím, než hrají hráči
        this.isDemo = isDemo;

        this.anyAction = false;

        //identifikátor ovladače
        this.controllerId = controllerId;
        //controller je mapovaný (pro nestandardní kontrolery)
        this.isMapped = true;
        this.mappingAutoReload = true;
        //je nastavené vlastní mapování
        this.isSetCustomMapping = false;
        this.mappingCurrent = mappingItems.indexOf('up');
        this.mappingArray = []; //pole s mapováním
        this.lastPressedKeyId = null;
        this.lastPressedAxisId = null;

        //3s do dalšího kroku mapování
        this.mappingStepTimeout = 2000;
        this.mappingStepTimeoutDefault = 2000;


        this.specialFontClass = null;

        //texty k nápovědě ovládání
        this.controlTextMove = "?";
        this.controlTextMoveAlt = "?";
        this.controlTextPortal = "?";
        this.controlTextRam = "?";
        this.controlTextMines = "?";
        this.controlTextPauseMenu = "?";
        this.controlTextDial = "?";
        


        //AI startuje ve stavu READY, a pokud je hra již rozjetá, pak i běžný hráč. Jinak je ve stavu INMENU
        this.playerPhase = this.controlledBy === controls.AI ? 
            playerPhaseEnum.READY : 
            game.state.gamePhase !== gamePhaseEnum.GAME ?
                playerPhaseEnum.INMENU : playerPhaseEnum.READY;

        this.playerPhasePrevious = null;

        //pokud jsem v menu na začátku vybírám tracera ['tracer','color','ready','time']
        this.inMenuCurrent = menuControlItems.indexOf('controls');
        //ale někdy je potřeba to změnit
        this.setInMenuCurrent(controlledBy, controllerId);
        
        //update textu dle ovladače (musí být až za setInMenuCurrent)
        this.setControlTexts(controlledBy, controllerId)
        



               
        this.explosion = false;
        this.exRadius = 50;
        this.clearBehindPlayer = false;
    

    
        this.AIactionTimeout = Math.floor(Math.random() * 2000);
    
        //brány
        this.gateDuration = 0;
        this.gateDurationDefault = 400;
        
        //čas na udělání brány
        this.gateCooldownTime = 0;
        this.gateCooldownTimeDefault = 5000;
        this.gateCooldown = false;
    
        //automatické brány
        this.autoGateCooldownTime = 4000;
        this.autoGateCooldownTimeDefault = 4000;
    
        //portály
        //tato brana bude portál
        this.currentGateIsPortal = false;

        this.portalCooldownTime = 0;
        this.portalCooldownTimeDefault = 15000;
        this.portalCooldown = false;
        this.portalBeginX = null;
        this.portalBeginY = null;

        //prodleva mezi přesunem portálem
        this.transferDelay = 0;
        this.transferDelayDefault = 1000;
        //pokud jsou nastaveny a odpočet je na 0, přesouvám se
        this.transferX = null;
        this.transferY = null;


        //MINY
        this.blockadeDuration = 0;
        this.blockadeDurationDefault = 500;
        this.blockadeCooldownTime = 0;
        this.blockadeCooldownTimeDefault = 10000;
        this.blockadeCooldown = false;

        this.blockadeSpawnCurrentStep = 0;
        

        //KLADIVO
        this.ramDuration = 0;
        this.ramDurationDefault = 2000;
    
        this.ramCooldownTime = 0;
        this.ramCooldownTimeDefault = 30000;
        this.ramCooldown = false;

        //DIAL
        this.lastDial = 0;
        this.dialResetAfter =1000;
        this.portalDial = 0;

        //OVERDRIVE
        this.energy = 3;
        this.overDriveDuration = 0;
        this.overDriveDurationDefault = 10000;
        this.overDriveCooldown = false;

    
        //pauza
        this.lastPause = 0;
        this.tutorialBefore =500;

        //čas do restartu
        this.restartTimeout = this.isDemo ? 0 : 5000; 
        this.restartTimeoutDefault = 5000;

        //čas pro povel ovládání v menu
        this.menuActionTimeout = 200;
        this.menuActionTimeoutDefault = 200;
    
    
        //aktuální ovládací povely
        this.actual = { left: false, right: false, up: false, down: false, gate: false, blockade: false, ram: false, dial: false, pause: false };
        this.prev = { left: false, right: false, up: false, down: false, dial: false, pause: false };
        this.prevPriorityChecksum = 0;
    
        //pole s posledními prioritami
        this.priorityArray = [];

        this.soundReadyPlayed = false;
        this.soundReadyTimeout = 300;
        this.soundReadyTimeoutDefault = 300;
        this.soundFirstPlayed = false;
    }


    /**
     * nastaví první bod v menu pro jednotlivé typy hráčů a ovládání
     * @param {*} controlledBy 
     * @param {*} controllerId 
     */
    setInMenuCurrent(controlledBy, controllerId){

        //AI je ready, hráčí vybírají tracer
        let menuPosition = controlledBy === controls.AI ? menuControlItems.indexOf('ready') : menuControlItems.indexOf('controls');

        if(controlledBy === controls.GAMEPAD 
            && this.game.gamepads!=null 
            && this.game.gamepads[controllerId].id.indexOf('XInput STANDARD GAMEPAD') === -1 
            && this.game.gamepads[controllerId].mapping !== 'standard'
        ){
            //donutím hráče nakonfigurovat ovládání
            menuPosition = menuControlItems.indexOf('controls');
            //není nastavené ovládání
            this.isMapped = false;
        }

        //nastavíme hodnotu
        this.inMenuCurrent = menuPosition;
        //console.log(menuControlItems[this.inMenuCurrent]);
    }


    /**
     * korektní nastavení popisu ovládání
     * @param {*} controlledBy 
     * @param {*} controllerId 
     */
    setControlTexts(controlledBy, controllerId){
        
        //sipky
        if(controlledBy === controls.KEYBOARD && controllerId === 0 ){
            this.controlTextMove = "↑↓←→";
            this.controlTextMoveAlt = "";
            this.controlTextPortal = "del";
            this.controlTextRam = "ins";
            this.controlTextMines = "home";
            this.controlTextPauseMenu = "esc";
            this.controlTextDial = "end";
        }

        //wsad
        if(controlledBy === controls.KEYBOARD && controllerId === 1 ){
            this.controlTextMove = "wsad";
            this.controlTextMoveAlt = "";
            this.controlTextPortal = "z,y";
            this.controlTextRam = "q";
            this.controlTextMines = "e";
            this.controlTextPauseMenu = "esc";
            this.controlTextDial = "x";
        }

        //8546
        if(controlledBy === controls.KEYBOARD && controllerId === 2 ){
            this.controlTextMove = "8546";
            this.controlTextMoveAlt = "";
            this.controlTextPortal = "1";
            this.controlTextRam = "7";
            this.controlTextMines = "9";
            this.controlTextPauseMenu = "esc";
            this.controlTextDial = "0";
        }

        //ikjl
        if(controlledBy === controls.KEYBOARD && controllerId === 3 ){
            this.controlTextMove = "ikjl";
            this.controlTextMoveAlt = "";
            this.controlTextPortal = "n";
            this.controlTextRam = "u";
            this.controlTextMines = "o";
            this.controlTextPauseMenu = "esc";
            this.controlTextDial = "m";
        }

        //gamepad xbox
        if(controlledBy === controls.GAMEPAD && this.game.gamepads!=null){
        
            if(this.game.gamepads[controllerId].id.indexOf('XInput STANDARD GAMEPAD') !== -1)
            {
                this.controlTextMove = "D";
                this.controlTextMoveAlt = "L";
                this.controlTextPortal = "A";
                this.controlTextRam = "Y";
                this.controlTextMines = "B";
                this.controlTextPauseMenu = "V";
                this.controlTextDial = "X";

                //speciální font
                this.specialFontClass = 'xbox';
            }
            else if (this.game.gamepads[controllerId].mapping === 'standard')
            {
                this.controlTextMove = "↑↓←→";
                this.controlTextMoveAlt = "";
                this.controlTextPortal = "1";
                this.controlTextRam = "4";
                this.controlTextMines = "2";
                this.controlTextPauseMenu = "start";
                this.controlTextDial = "3";
            }
            else if (this.isMapped === true && this.isSetCustomMapping === true)
            {
                //namapovaný nestandardní gamepad
                //this.controlTextMove = "↑↓←→";
                this.controlTextMove =  (this.mappingArray[mappingItems.indexOf('up')].type === mappingType.BUTTON ? "↑b" : "↑a") + this.mappingArray[mappingItems.indexOf('up')].index + ' ';
                this.controlTextMove +=  (this.mappingArray[mappingItems.indexOf('down')].type === mappingType.BUTTON ? "↓b" : "↓a") + this.mappingArray[mappingItems.indexOf('down')].index + ' ';
                this.controlTextMove +=  (this.mappingArray[mappingItems.indexOf('left')].type === mappingType.BUTTON ? "←b" : "←a") + this.mappingArray[mappingItems.indexOf('left')].index + ' ';
                this.controlTextMove +=  (this.mappingArray[mappingItems.indexOf('right')].type === mappingType.BUTTON ? "→b" : "→a") + this.mappingArray[mappingItems.indexOf('right')].index;
                
                this.controlTextMoveAlt = "";
                this.controlTextPortal =  (this.mappingArray[mappingItems.indexOf('portal')].type === mappingType.BUTTON ? "b" : "a") + this.mappingArray[mappingItems.indexOf('portal')].index;
                this.controlTextRam = (this.mappingArray[mappingItems.indexOf('hammer')].type === mappingType.BUTTON ? "b" : "a") + this.mappingArray[mappingItems.indexOf('hammer')].index;
                this.controlTextMines = (this.mappingArray[mappingItems.indexOf('mines')].type === mappingType.BUTTON ? "b" : "a") + this.mappingArray[mappingItems.indexOf('mines')].index;
                this.controlTextPauseMenu = (this.mappingArray[mappingItems.indexOf('pause')].type === mappingType.BUTTON ? "b" : "a") + this.mappingArray[mappingItems.indexOf('pause')].index;
                this.controlTextDial = (this.mappingArray[mappingItems.indexOf('dial')].type === mappingType.BUTTON ? "b" : "a") + this.mappingArray[mappingItems.indexOf('dial')].index;

            }
        }
    }




    /**
     * nastaví unikátní index hráče v poli před sestavením výsledkové listiny
     * @param {*} uniqueIndex - unikátní index dle pořadí v poli výsledků
     */
    SetUniqueIndex(uniqueIndex){

        this.uniqueIndex = uniqueIndex;
        
    }

    respawn () {

        //vyresetuje hodnoty a znovu umístí hráče, zatím jej neoživuje

        this.explosion = false;

        let bottomHudsHeight = this.game.getBottomHudsHeight();

        let resetWidth = this.game.canvas.width - 200;
        let resetHeight = this.game.canvas.height - bottomHudsHeight - 200;

        let colision = true;
        let cycles = 0;

        let tmpx = this.x;
        let tmpy = this.y;


        while(colision === true)
        {
            cycles++;

            this.x = this.oldx = this.lastCheckX = this.storm1x = this.storm2x = Math.floor(100 + Math.random() * resetWidth);
            this.y = this.oldy = this.lastCheckY = this.storm1y = this.storm2y = Math.floor(100 + Math.random() * resetHeight);

            //zkontroluju na kolize
            if(cycles < 100)
            {
                colision = this.game.findSpecialZoneColision(this.x,this.y);
            }
            else
            {
                //zastavíme while, pokud to nejde, vrazíme jej do zóny
                colision = false;
            }
        }

        //smrtelný transfer
        if (this.deaths >0)
        {
            this.game.addConnection(tmpx,tmpy,this.x,this.y,this.color, connectionType.DEATH);
        }

        //vymazaání kolečka pod hráčem
        this.clearBehindPlayer = true;


        this.smer = Math.floor(Math.random() * 4);
        this.bonusSpeed = 0;

        //reset linky
        this.strightLenght = 0;
        this.strightTime = 0;
        this.maxLine = 0;

        //zresetuju ability
        this.ramCooldownTime = 0;
        this.blockadeCooldownTime = 0;
        this.ramCooldownTime = 0;

        //a updatuju state
        this.blockadeCooldown = false;
        this.gateCooldown = false;
        this.ramCooldown = false;
        this.game.updatePlayersInfo();
    };



    moveToXY (x, y) {

        this.x = this.oldx = this.lastCheckX = this.storm1x = this.storm2x = x;
        this.y = this.oldy = this.lastCheckY = this.storm1y = this.storm2y = y;
    };

    changeColor () {
        //todo doplnit;


    };


    /*
     * Přesun hráče v menu na controls, např po návratu z tutrialu
     */
    toMenuItemControls(){
        
        if(this.controlledBy !== controls.AI){
            this.inMenuCurrent = menuControlItems.indexOf('controls');
        }

    }



    /*
     * přepnu stav hráče na ready
     */
    playerRespawn(){

        //playerPhaseEnum.READY
        if(this.inMenuCurrent === menuControlItems.indexOf('ready')){

            //přesun v menu na controls
            this.toMenuItemControls();

            //reset počítadla score
            this.score = 0;
            this.deaths = 0;
            this.isFirst = false;
            this.energy = 3;

            //respavnuju hráče
            this.playerPhase = playerPhaseEnum.DEATH;
        }
    };


    /**
     * přepnu stav hráče do menu
     */
    playerToMenuPhase(){

        //vracím hráče do menu
        this.playerPhasePrevious = this.playerPhase;
        this.playerPhase = playerPhaseEnum.INMENU;
    }

    /**
     * vybuchnu hráče po dohrání hry
     */
    playerExplode(){

        //aktivuju výbuch
        this.explosion = true;
    }


    /**
     * přepnu stav hráče při návratu z pauzy na původní stav
     */
    playerToPreviousPhase(){

        //připravím hráče na opětovnou volbu v menu
        if(this.inMenuCurrent === menuControlItems.indexOf('ready')){

            //přesun v menu na controls
            this.toMenuItemControls();
        }

        if(this.playerPhasePrevious==null)
        {
            //pro nově připojené hráče
            this.playerPhasePrevious = playerPhaseEnum.GAME;
        }

        //vracím předchozí fázi před skokem do menu
        this.playerPhase = this.playerPhasePrevious;
    }


    /**
     * zastavitelné procesy
     */
    timeoutPausableProcess () {
        

        //počítání doby rovné jízdy
        this.strightTime += this.game.delay;
        //poslední volba adresy
        this.lastDial += this.game.delay;


        //odpočet pauzy brány
        if (this.gateCooldownTime > 0){
            this.gateCooldownTime -= this.game.delay;

            if(this.gateCooldown === false){
                this.gateCooldown = true;
                this.game.updatePlayersInfo();
            }
        }
        else{
            if(this.gateCooldown === true){
                this.gateCooldown = false;
                this.game.updatePlayersInfo();
            }
        }


        //odpočet pauzy portálu
        if (this.portalCooldownTime > 0){
            this.portalCooldownTime -= this.game.delay;

            if(this.portalCooldown === false){
                this.portalCooldown = true;
                this.game.updatePlayersInfo();
            }
        }
        else{
            if(this.portalCooldown === true){
                this.portalCooldown = false;
                this.game.updatePlayersInfo();
            }
        }


        //odpočet do přesunu
        if (this.transferDelay > 0) {
            this.transferDelay -= this.game.delay;
        }


        //odpočet pauzy blokády
        if (this.blockadeCooldownTime > 0){
            this.blockadeCooldownTime -= this.game.delay;

            if(this.blockadeCooldown === false){
                this.blockadeCooldown = true;
                this.game.updatePlayersInfo();
            }
        }
        else
        {
            if(this.blockadeCooldown === true){
                this.blockadeCooldown = false;
                this.game.updatePlayersInfo();
            }
        }


        //odpočet trvání blokády
        if (this.blockadeDuration > 0){
            this.blockadeDuration -= this.game.delay;
        }
        else {
            //reset počítadl uvolněných teček
            this.blockadeSpawnCurrentStep = 0;
        }


        //odpočet pauzy kladiva
        if (this.ramCooldownTime > 0) {
            this.ramCooldownTime -= this.game.delay;

            if(this.ramCooldown === false){
                this.ramCooldown = true;
                this.game.updatePlayersInfo();
            }
        }
        else{
            if(this.ramCooldown === true){
                this.ramCooldown = false;
                this.game.updatePlayersInfo();
            }
        }
        
        //odpočet pro zobrazení použití overdrajvu
        if (this.overDriveDuration > 0) {
            this.overDriveDuration -= this.game.delay;
        }
        else{
            if(this.overDriveCooldown === true){
                this.overDriveCooldown = false;
                this.game.updatePlayersInfo();
            }
        }

        //odpočet trvání kladiva
        if (this.ramDuration > 0) {
            this.ramDuration -= this.game.delay;
        }

        //odpočet detekce sousedů
        if(this.LastNeighborDetection > 0)
        {
            this.LastNeighborDetection -= this.game.delay;
        }


        if (this.playerPhase === playerPhaseEnum.DEATH) {

            //umístím se jinde a přepnu se do přípravného stavu
            this.respawn();

            //spustím odpočet do oživení
            this.restartTimeout = this.restartTimeoutDefault;
            this.playerPhase = playerPhaseEnum.READY;
        }


        if (this.playerPhase === playerPhaseEnum.READY) {

            //cooldown restartu hrace
            if (this.restartTimeout > 0) {
                this.restartTimeout -= this.game.delay;
            }
            else {

                //znova rozjedu hru
                this.playerPhase = playerPhaseEnum.GAME;

            }
        }


        //odpočet tvorby brány (a případné založení portálu)
        if (this.gateDuration > 0) {

            this.gateDuration -= this.game.delay;

            if(this.gateDuration <= 0 && this.currentGateIsPortal && this.strightTime >= this.gateDurationDefault)
            {
                //pokud jsem jel celou dobu vývoje brány rovně, mohu udělat portál.

                //vyblokuju výrobu portálu pro hráče po dobu nabíjení
                this.portalCooldownTime = this.portalCooldownTimeDefault;
                //posunu auto bránu o další 4s
                this.autoGateCooldownTime = this.autoGateCooldownTimeDefault;
                
                //přidáme nový portál
                this.game.addPortal(this.portalBeginX,this.portalBeginY, this.x, this.y, (this.smer === 0 || this.smer === 2) ? orientation.VERTICAL: orientation.HORIZONTAL, false, 30000, this.portalDial);
                this.game.playSound(2,1);

                this.currentGateIsPortal = false;
            }

        }

        //odpočet automatické brány, kterou dělají všichni
        if (this.autoGateCooldownTime > 0){
            this.autoGateCooldownTime -= this.game.delay;
        }


        //portál
        if (this.actual.gate && this.portalCooldownTime <= 0) {

            if(this.gateDuration <=0)
            {
                //uložím si začátek brány
                this.portalBeginX = this.x;
                this.portalBeginY = this.y;
                //začnu tvořit běžnou bránu
                this.gateDuration = this.gateDurationDefault;
            }

            //běžná brána se stane portálem
            this.currentGateIsPortal = true;
        }


        //automatická brána
        if (this.autoGateCooldownTime <= 0) {
            //sposunu auto bránu o další 4s
            this.autoGateCooldownTime = this.autoGateCooldownTimeDefault;

            if(this.gateDuration <=0)
            {
                //uložím si začátek brány
                this.portalBeginX = this.x;
                this.portalBeginY = this.y;
                //začnu tvořit běžnou bránu
                this.gateDuration = this.gateDurationDefault;
            }            
        }


        //blokáda
        if (this.actual.blockade && this.blockadeCooldownTime <= 0) {
            this.blockadeDuration = this.blockadeDurationDefault;
            this.blockadeCooldownTime = this.blockadeCooldownTimeDefault;
            this.game.playSound(6,1);
        }


        //beranidlo
        if (this.actual.ram && this.ramCooldownTime <=0) {
            this.ramDuration = this.ramDurationDefault;
            this.ramCooldownTime = this.ramCooldownTimeDefault;
            this.game.playSound(7,1);
        }
        

        //odložíme předchozí hodnotu
        let prevPortalDial = this.portalDial;

        //dial určení adresy, kazda nabezna hrana
        if(!this.prev.dial && this.actual.dial ){

            if(this.lastDial < this.dialResetAfter)
            {
                //inkrement adresy
                this.portalDial +=1;
                if(this.portalDial >4){
                    this.portalDial = 1;
                }
            }
            else
            {
                //reset adresy
                this.portalDial = 1;
            }

            //reset času
            this.lastDial = 0;
            //console.log('dial' + this.portalDial);
        }

        //sestupna hrana bude resetovat na 0, jen když držím déle 
        if(this.prev.dial && !this.actual.dial ){
            if(this.lastDial > this.dialResetAfter){

                //reset adresy do 0
                this.portalDial = 0;

                //reset času
                this.lastDial = 0;
                //console.log('dial' + this.portalDial);
            }
        }

        if(this.portalDial !== prevPortalDial)
        {
            //změna, update
            this.game.updatePlayersInfo();
        }


        //efekt blesků
        this.toStorm -= this.game.delay;

        //přechod okolo 1000ms
        if(this.toStorm<= 700 && this.StormPositionSet===false){
            
            //this.storm1x = this.storm2x = this.x;
            //this.storm1y = this.storm2y = this.y;

            this.StormPositionSet = true;
        }
        
        if(this.toStorm<=0){
            this.toStorm = Math.floor(Math.random() * 1000);
            this.StormPositionSet = false;
        }

        this.prev.dial = this.actual.dial;

    }


    /**
     * nezastavitelné procesy
     */
    timeoutsProcess () {

        //poslední pauza
        this.lastPause += this.game.delay;

        //odpočet pro pokyny v menu
        if(this.playerPhase === playerPhaseEnum.INMENU  && this.menuActionTimeout >0){
            this.menuActionTimeout -= this.game.delay;
        }

        //zvuk ready po 500ms
        if(this.playerPhase === playerPhaseEnum.INMENU  && this.controlledBy !== controls.AI)
        {
            
            if(this.inMenuCurrent === menuControlItems.indexOf('ready'))
            {
                //zvuk jsme rdy
                if (!this.soundReadyPlayed)
                {
                    if(this.soundReadyTimeout > 0)
                    {
                        this.soundReadyTimeout -= this.game.delay;
                    }
                    else
                    {
                        this.soundReadyPlayed = true;

                        this.game.playSound(13,1);
                        //console.log('snd');
                    }
                }
            }
            else
            {
                this.soundReadyPlayed = false;
                this.soundReadyTimeout = this.soundReadyTimeoutDefault;
            }
        }


        //pauza, kazda nabezna hrana
        if(!this.prev.pause && this.actual.pause ){

            this.game.pauseGame();

            if(this.lastPause < this.tutorialBefore)
            {
                this.game.tutorial();
            }

            //reset času
            this.lastPause = 0;
        }

        //odložení předchozího stavu, pro sledování hrany
        this.prev.pause = this.actual.pause;
    };


    /**
     * určení nového směru pro hráče
     * řeší:
     * priority jízdy, tedy aby vždy vyšel nový směr jízdy jako poslední nový pokyn ovladače
     */
    playerNewWay(){
        
        let nsmer = null;
        
        //z univerzálního formátu ted vytahneme o co jde todo: dle priority
        let actArr = [this.actual.up, this.actual.down, this.actual.left, this.actual.right];
        let prevArr = [this.prev.up, this.prev.down, this.prev.left, this.prev.right];

        //březinův algorytmus pro prioritizaci pohybu

        //1.kontrola změny
        let checksum = actArr[0] * 8 + actArr[1] * 4 + actArr[2] * 2 + actArr[3] * 1;

        if (checksum === 0 || this.prevPriorityChecksum !== checksum) {

            //reset priorit a nové sesstavení pole
            this.priorityArray = [];

            if (checksum !== 0) {
                //2. test na 1ky
                for (let f = 0; f < actArr.length; f++) {
                    if (prevArr[f] === true && actArr[f] === true) {
                        //zapíšu přioritu
                        this.priorityArray.push(f);
                    }
                }

                //3. test na prioritní přechody 0->1
                for (let f = 0; f < actArr.length; f++) {
                    if (prevArr[f] === false && actArr[f] === true) {
                        //zapíšu přioritu
                        this.priorityArray.push(f);
                    }
                }
            }
        }

        //4. poslední priorita je nový směr
        let move = null;
        if (this.priorityArray.length > 0) {
            //console.log(this.priorityArray);

            //získáme pohyb s nejvyšší prioritou
            move = this.priorityArray[this.priorityArray.length - 1];
        }

        //uložení předchozího checksumu
        this.prevPriorityChecksum = checksum;

        if (move !== null) {

            //nahoru
            if (move === 0) {
                nsmer = 0;
            }
            //dolů
            if (move === 1) {
                nsmer = 2;
            }
            //doleva
            if (move === 2) {
                nsmer = 3;
            }
            //napravo
            if (move === 3) {
                nsmer = 1;
            }
        }


        //uložení předchozích hodnot pohybu pro příští stanovení priorit
        this.prev.left = this.actual.left;
        this.prev.right = this.actual.right;
        this.prev.up = this.actual.up;
        this.prev.down = this.actual.down;

        return nsmer;
    }


    /**
     * zprocesování ovládání hráčů
    */
    processControlls(gamepads){
        
        //nezmacknuto nic
        let notMappedPressAny = false;

        //ovládání gamepady
        if (this.controlledBy === controls.GAMEPAD) {

            //let gamepads = navigator.getGamepads();
            
            let gp = gamepads[this.controllerId];
            
            if (gp !== undefined && gp !== null && gp.connected === true) {

                //tento gamepad je právě používán
                gp.devIsUsed = true;

                //standardní gamepady
                if(gp.mapping !=null && gp.mapping === 'standard')
                {
                    //Analogové OSY   
                    let gamepadX, gamepadY;
                    if (gp.axes[0] !== 0)
                    {
                        gamepadX = gp.axes[0];
                    }

                    if (gp.axes[1] !== 0)
                    {
                        gamepadY = gp.axes[1];
                    }

                    //nahoře a dole
                    let c= Math.sqrt(Math.pow(gamepadX,2)+Math.pow(gamepadY,2));
                    if(c>1) c=1;

                    let angle = Math.asin(gamepadY/c);
                    let actionAngle = Math.PI /10; //18stupnu
                    
                    if (gamepadX > 0.3 && -actionAngle < angle && angle < actionAngle) //doprava
                    {
                        this.actual.right = true;
                    }
                    if (gamepadX < -0.3 && -actionAngle < angle && angle < actionAngle) //doleva
                    {
                        this.actual.left = true;
                    }
                    if (gamepadY > 0.3 && ((Math.PI/2) -actionAngle) < angle && angle < ((Math.PI/2) +actionAngle)) //dolů
                    {
                        this.actual.down = true;
                    }
                    if (gamepadY < -0.3 && (-(Math.PI/2) -actionAngle) < angle && angle < (-(Math.PI/2) +actionAngle)) //nahoru
                    {
                        this.actual.up = true;
                    }

                    //Tlačítka
                    if (gp.buttons[12].pressed) {
                        this.actual.up = true;
                    }
                    if (gp.buttons[13].pressed) {
                        this.actual.down = true;
                    }
                    if (gp.buttons[14].pressed) {
                        this.actual.left = true;
                    }
                    if (gp.buttons[15].pressed) {
                        this.actual.right = true;
                    }
                    if (gp.buttons[0].pressed) {
                        this.actual.gate = true;
                    }
                    if (gp.buttons[1].pressed) {
                        this.actual.blockade = true;
                    }
                    if (gp.buttons[3].pressed) {
                        this.actual.ram = true;
                    }
                    if (gp.buttons[2].pressed) {
                        this.actual.dial = true;
                    }
                    if(gp.buttons[9].pressed){
                        this.actual.pause = true;
                        //this.game.pauseGame();
                    }
                }
                else
                {
                    //pro nestandardní gamepady

                    //LOADING ULOŽENÉHO MAPPINGU
                    if(this.isMapped === false && this.mappingAutoReload === true)
                    {
                        //pokusím se jej sehnat ze storage
                        let mapping = this.game.loadMapping(gp.id);

                        if(mapping!=null)
                        {
                            //nastavím uložený mapping
                            this.mappingArray = mapping;

                            //přenastavím
                            this.isMapped = true;
                            this.isSetCustomMapping = true;
                            this.setControlTexts(this.controlledBy, this.controllerId);

                            //updatuju state
                            this.game.updatePlayersInfo();
                        }
                    }

                    //1. MANUÁLNÍ MAPPING
                    if(this.isMapped === false)
                    {
                        //zapauzuju hru, pokud není pauza (je potřeba nakonfigurovat ovládání hráče)
                        if(this.game.pause === false){
                            this.game.pauseGame();
                        }
                        
                        //Analogové OSY   
                        let axesLength = gp.axes.length;
                        if(axesLength > 4)
                        {
                          axesLength = 4;
                        }
                        for (let a = 0; a < axesLength; a++) {
                            if(gp.axes[a] != null && (gp.axes[a] > 0.3 || gp.axes[a] < -0.3))
                            {
                                notMappedPressAny = true;

                                if(a !== this.lastPressedAxisId){
                                
                                //reset posledě držené klávesy
                                this.lastPressedAxisId = a;
                                this.mappingStepTimeout = this.mappingStepTimeoutDefault;
                                }
                                else{
                                    
                                    //zmacknuto stejne tlacitko, odpočet
                                    this.mappingStepTimeout -= this.game.delay;

                                    if(this.mappingStepTimeout <= 0)
                                    {
                                        if(gp.axes[a] > 0.3)
                                        {
                                            //uložíme klávesu a přepneme se v pořadí na další
                                            this.mappingArray.push(new Mapping(mappingType.AXIS, a, true));
                                        }
                                        else
                                        {
                                            this.mappingArray.push(new Mapping(mappingType.AXIS, a, false));
                                        }
                                        this.mappingStepTimeout = this.mappingStepTimeoutDefault;
                                        this.mappingCurrent ++;
                                    }
                                }
                            }
                        }


                         //buttony
                        for (let c = 0; c < gp.buttons.length; c++) {
                            if(gp.buttons[c] != null && gp.buttons[c].pressed)
                            {
                                notMappedPressAny = true;

                                if(c !== this.lastPressedKeyId)
                                {
                                    //reset posledě držené klávesy
                                    this.lastPressedKeyId = c;
                                    this.mappingStepTimeout = this.mappingStepTimeoutDefault;
                                }
                                else
                                {
                                    //zmacknuto stejne tlacitko, odpočet
                                    this.mappingStepTimeout -= this.game.delay;

                                    if(this.mappingStepTimeout <= 0)
                                    {
                                        //uložíme klávesu a přepneme se v pořadí na další
                                        this.mappingArray.push(new Mapping(mappingType.BUTTON, c, null));
                                        this.mappingStepTimeout = this.mappingStepTimeoutDefault;
                                        this.mappingCurrent ++;
                                    }
                                }
                            }
                        }

                        if(this.mappingCurrent > mappingItems.indexOf('pause'))
                        {
                            //mapování je dokončeno, přepínám do true

                            this.isMapped = true;
                            this.isSetCustomMapping = true;
                            this.setControlTexts(this.controlledBy, this.controllerId);

                            //uložím nové mapování, případně updatuju staré
                            this.game.saveOrUpdateMapping(gp.id, this.mappingArray);

                            //povolíme opět automatické načítání mappingu
                            this.mappingAutoReload = true;
                        }

                        if(notMappedPressAny === false)
                        {
                            //resetuju
                            this.lastPressedKeyId = null;

                            if(this.mappingStepTimeout !== this.mappingStepTimeoutDefault){
                                this.mappingStepTimeout = this.mappingStepTimeoutDefault;
                                this.game.updatePlayersInfo();
                            }
                        }
                        else
                        {
                            //updatuju state
                            this.game.updatePlayersInfo();
                        }

                    }
                    else
                    {
                        //už je namapovaný, provádím ovládání dle mappingu

                        //Analogové OSY   
                        let gamepadX, gamepadY;
                        
                        //vlevo a vpravo
                        if (this.mappingArray[2].type === mappingType.AXIS && gp.axes[this.mappingArray[2].index] !== 0)
                        {
                            //podpora inverzního ovládání
                            if(this.mappingArray[2].isPositiveAxis){
                                gamepadX = -gp.axes[this.mappingArray[2].index];
                            }
                            else{
                                gamepadX = gp.axes[this.mappingArray[2].index];
                            }
                        }

                        //nahoru a dolu
                        if (this.mappingArray[0].type === mappingType.AXIS && gp.axes[this.mappingArray[0].index] !== 0)
                        {
                            //podpora inverzního ovládání
                            if(this.mappingArray[0].isPositiveAxis){
                                gamepadY = -gp.axes[this.mappingArray[0].index];
                            }
                            else{
                                gamepadY = gp.axes[this.mappingArray[0].index];
                            }
                        }

                        //nahoře a dole
                        let c= Math.sqrt(Math.pow(gamepadX,2)+Math.pow(gamepadY,2));
                        if(c>1) c=1;

                        let angle = Math.asin(gamepadY/c);
                        let actionAngle = Math.PI /10; //18stupnu
                        
                        if (gamepadX > 0.3 && -actionAngle < angle && angle < actionAngle) //doprava
                        {
                            this.actual.right = true;
                        }
                        if (gamepadX < -0.3 && -actionAngle < angle && angle < actionAngle) //doleva
                        {
                            this.actual.left = true;
                        }
                        if (gamepadY > 0.3 && ((Math.PI/2) -actionAngle) < angle && angle < ((Math.PI/2) +actionAngle)) //dolů
                        {
                            this.actual.down = true;
                        }
                        if (gamepadY < -0.3 && (-(Math.PI/2) -actionAngle) < angle && angle < (-(Math.PI/2) +actionAngle)) //nahoru
                        {
                            this.actual.up = true;
                        }

                        //Tlačítka [0-up, 1-down, 2-left, 3-right, 4-portal, 5-mines, 6-ram, 7-dial, 8-pause
                        if (this.mappingArray[0].type === mappingType.BUTTON && gp.buttons[this.mappingArray[0].index].pressed) {
                            this.actual.up = true;
                        }
                        if (this.mappingArray[1].type === mappingType.BUTTON && gp.buttons[this.mappingArray[1].index].pressed) {
                            this.actual.down = true;
                        }
                        if (this.mappingArray[2].type === mappingType.BUTTON && gp.buttons[this.mappingArray[2].index].pressed) {
                            this.actual.left = true;
                        }
                        if (this.mappingArray[3].type === mappingType.BUTTON && gp.buttons[this.mappingArray[3].index].pressed) {
                            this.actual.right = true;
                        }
                        if (this.mappingArray[4].type === mappingType.BUTTON && gp.buttons[this.mappingArray[4].index].pressed) {
                            this.actual.gate = true;
                        }
                        if (this.mappingArray[5].type === mappingType.BUTTON && gp.buttons[this.mappingArray[5].index].pressed) {
                            this.actual.blockade = true;
                        }
                        if (this.mappingArray[6].type === mappingType.BUTTON && gp.buttons[this.mappingArray[6].index].pressed) {
                            this.actual.ram = true;
                        }
                        if (this.mappingArray[7].type === mappingType.BUTTON && gp.buttons[this.mappingArray[7].index].pressed) {
                            this.actual.dial = true;
                        }
                        if(this.mappingArray[8].type === mappingType.BUTTON && gp.buttons[this.mappingArray[8].index].pressed) {
                            this.actual.pause = true;
                            //this.game.pauseGame();
                        }

                    }
                }
            }
        }

        //ovládání klávesnice 0
        if (this.controlledBy === controls.KEYBOARD && this.controllerId === 0) {

            //nahoru
            if (this.game.keydown.up) {
                this.actual.up = true;
            }
            //dolů
            if (this.game.keydown.down) {
                this.actual.down = true;
            }
            //doleva
            if (this.game.keydown.left) {
                this.actual.left = true;
            }
            //napravo
            if (this.game.keydown.right) {
                this.actual.right = true;
            }
            //brána
            if (this.game.keydown.del) {
                this.actual.gate = true;
            }
            //blokáda
            if (this.game.keydown.home) {
                this.actual.blockade = true;
            }
            //beranidlo
            if (this.game.keydown.insert) {
                this.actual.ram = true;
            }
            //vytáčení
            if (this.game.keydown.end) {
                this.actual.dial = true;
            }
        }

        //ovládání klávesnice 1 wasd
        if (this.controlledBy === controls.KEYBOARD && this.controllerId === 1) {

            //nahoru
            if (this.game.keydown.w) {
                this.actual.up = true;
            }
            //dolů
            if (this.game.keydown.s) {
                this.actual.down = true;
            }
            //doleva
            if (this.game.keydown.a) {
                this.actual.left = true;
            }
            //napravo
            if (this.game.keydown.d) {
                this.actual.right = true;
            }
            //brána
            if (this.game.keydown.z || this.game.keydown.y) {
                this.actual.gate = true;
            }
            //blokáda
            if (this.game.keydown.e) {
                this.actual.blockade = true;
            }
            //beranidlo
            if (this.game.keydown.q) {
                this.actual.ram = true;
            }
            //vytáčení
            if (this.game.keydown.x) {
                this.actual.dial = true;
            }
        }

        //ovládání klávesnice 2 tfgh
        if (this.controlledBy === controls.KEYBOARD && this.controllerId === 2) {

            //nahoru
            if (this.game.keydown[8]) {
                this.actual.up = true;
            }
            //dolů
            if (this.game.keydown[5]) {
                this.actual.down = true;
            }
            //doleva
            if (this.game.keydown[4]) {
                this.actual.left = true;
            }
            //napravo
            if (this.game.keydown[6]) {
                this.actual.right = true;
            }
            //brána
            if (this.game.keydown[1]) {
                this.actual.gate = true;
            }
            //blokáda
            if (this.game.keydown[9]) {
                this.actual.blockade = true;
            }
            //beranidlo
            if (this.game.keydown[7]) {
                this.actual.ram = true;
            }
            //vytáčení
            if (this.game.keydown[0]) {
                this.actual.dial = true;
            }
        }

        //ovládání klávesnice 3 ijkl
        if (this.controlledBy === controls.KEYBOARD && this.controllerId === 3) {

            //nahoru
            if (this.game.keydown.i) {
                this.actual.up = true;
            }
            //dolů
            if (this.game.keydown.k) {
                this.actual.down = true;
            }
            //doleva
            if (this.game.keydown.j) {
                this.actual.left = true;
            }
            //napravo
            if (this.game.keydown.l) {
                this.actual.right = true;
            }
            //brána
            if (this.game.keydown.n) {
                this.actual.gate = true;
            }
            //blokáda
            if (this.game.keydown.o) {
                this.actual.blockade = true;
            }
            //beranidlo
            if (this.game.keydown.u) {
                this.actual.ram = true;
            }
            //vytáčení
            if (this.game.keydown.m) {
                this.actual.dial = true;
            }
        }

        //detekce zmáčknutí tlačítka uživatelem
        if(this.controlledBy !== controls.AI){

            this.anyAction = this.actual.up || this.actual.down || this.actual.left || this.actual.right || this.actual.gate || this.actual.blockade || this.actual.ram || this.actual.dial || notMappedPressAny;
        }

        return this.playerNewWay();

                
    }


    /**
     * určení nového směru pro AI
     */
    AInewWay () {

        //dokud se nezmění směr
        let nsmer = Math.floor(Math.random() * 4);
        if (nsmer !== this.smer && ((this.smer === 0 && nsmer !== 2) || (this.smer === 1 && nsmer !== 3) || (this.smer === 2 && nsmer !== 0) || (this.smer === 3 && nsmer !== 1))) {

            //nový směr
            this.smer = nsmer;
            //mením směr, nemůže jít o portál
            this.currentGateIsPortal = false;

            this.strightLenght = 0;
            this.strightTime = 0;
            this.maxLine = 0;
        }
    };


    /**
     * zprocesování ovládání AI
    */
    processAIControlls(){
        
        //ovládání AI
        if (this.controlledBy === controls.AI) {

            //náhodné změny
            if (this.AIactionTimeout <= 0) {

                this.AInewWay();
                this.AIactionTimeout = Math.floor(Math.random() * 2000);
            }
            else {
                //odpočet
                this.AIactionTimeout -= this.game.delay;
            }

            //hledání překážek ve směru jízdy

            let aiDetector = null;

            let wayOK = false;
            let pokusu = 0;

            while (wayOK === false && pokusu < 5) {


                if (this.smer === 0) {
                    aiDetector = this.game.ctx.getImageData(Math.floor(this.x), Math.floor(this.y) - 10, 1, 9).data;
                }
                if (this.smer === 1) {
                    aiDetector = this.game.ctx.getImageData(Math.floor(this.x) + 1, Math.floor(this.y), 9, 1).data;
                }
                if (this.smer === 2) {
                    aiDetector = this.game.ctx.getImageData(Math.floor(this.x), Math.floor(this.y) + 1, 1, 9).data;
                }
                if (this.smer === 3) {
                    aiDetector = this.game.ctx.getImageData(Math.floor(this.x) - 10, Math.floor(this.y), 9, 1).data;
                }

                let detect = false;
                if (aiDetector !== null) {
                    //kontroluju detektor 1
                    for (let i = 3; i < aiDetector.length; i = i + 4) {
                        if (aiDetector[i] > 150) {
                            detect = true;
                            //console.log('detekce kolize' + pokusu);
                            //console.log(aiDetector);

                            break;
                        }
                    }
                }

                if (detect === false) {
                    wayOK = true;
                }
                else {
                    this.AInewWay();
                }

                pokusu++;

                if (pokusu === 5 && wayOK === false)
                {
                    //zkusím použít ram na zácranu AI

                    if (this.ramCooldownTime <= 0) {

                        this.ramDuration = this.ramDurationDefault;
                        this.ramCooldownTime = this.ramCooldownTimeDefault;
                    }

                }
            }
        }

    }


    /**
     * ovládání menu
     */
    controlMenu(nsmer){

        if(this.menuActionTimeout <=0){
            //zabráníme znovuzmáčknutí
            this.menuActionTimeout = this.menuActionTimeoutDefault;

            //zvuk pohyb v menu
            this.game.playSound(0,1);
            
            if(nsmer === 0 || nsmer === 2)
            {
                //nahoru a dolů
                if(nsmer === 0)
                {
                    if(this.inMenuCurrent>0){
                        this.inMenuCurrent --;
                    }                        
                }
                if(nsmer === 2)
                {
                    if(this.inMenuCurrent < menuControlItems.length-1){
                        this.inMenuCurrent ++;
                    }
                }
            }
            
            if(nsmer === 1 || nsmer === 3)
            {
                if(this.inMenuCurrent === menuControlItems.indexOf('tracer')){

                    //změna typu vozidla 3 cyber-trike, 4 cyber-truck, 
                    if(this.type === 3) {
                        this.type = 4;
                    }
                    else{
                        this.type = 3;
                    }
                }

                if(this.inMenuCurrent === menuControlItems.indexOf('color')){
                    
                    //změna barev
                    if(nsmer === 1)
                    {
                        this.color ++;
                        if(this.color > greatColors.length-1){
                            this.color = 0;
                        }   
                    }
                    else
                    {
                        this.color --;

                        if(this.color<0){
                            this.color = greatColors.length-1;
                        }
                    }
                }

                if(this.inMenuCurrent === menuControlItems.indexOf('team'))
                {
                    //změna týmu
                    if(nsmer === 1)
                    {
                        this.team ++;  
                    }
                    else
                    {
                        this.team --;
                    }

                    if(this.team < 0){
                        this.team = 0;
                    }   
                }

                if(this.inMenuCurrent === menuControlItems.indexOf('AI count')){
                    
                    //změna barev
                    if(nsmer === 1)
                    {
                        this.game.addAI(1);
                    }
                    else
                    {
                        this.game.removeAI();
                    }
                }

                if(this.inMenuCurrent === menuControlItems.indexOf('screen')){
                    
                    this.game.toggleFullScreen();
                }


                if(this.inMenuCurrent === menuControlItems.indexOf('AI color')){
                    
                    //změna barev
                    if(nsmer === 1)
                    {
                        this.game.updateAIColor(+1);
                    }
                    else
                    {
                        this.game.updateAIColor(-1);
                    }
                }


                if(this.inMenuCurrent === menuControlItems.indexOf('lang')){
                    
                    //změna barev
                    if(nsmer === 1)
                    {
                        this.game.updateGameLang(+1);
                    }
                    else
                    {
                        this.game.updateGameLang(-1);
                    }
                }


                if(this.inMenuCurrent === menuControlItems.indexOf('time')){
                    
                    //změna barev
                    if(nsmer === 1)
                    {
                        this.game.updateGameTime(+150000);
                    }
                    else
                    {
                        this.game.updateGameTime(-150000);
                    }
                }

                if(this.inMenuCurrent === menuControlItems.indexOf('leave')){

                    //označení hráče pro smazání
                    this.markedForRemove = true;
                }

                if(this.inMenuCurrent === menuControlItems.indexOf('controls') && this.isSetCustomMapping ){

                    //vynutím ruční nastavení
                    this.mappingAutoReload = false;
                    //odstranění mapování
                    this.isMapped = false;
                    this.isSetCustomMapping = false;
                    //odstranění existujícího nastavení
                    this.mappingArray = []; 
                    //reset počítadla
                    this.mappingCurrent = mappingItems.indexOf('up');
                    this.mappingStepTimeout = this.mappingStepTimeoutDefault;
                }


                //hudební přehrávač
                if(this.inMenuCurrent === menuControlItems.indexOf('music')){
                    
                    //změna hudby a nebo její zastaveni
                    if(nsmer === 1)
                    {
                        this.game.musicPlayerNext();
                    }
                    else
                    {
                        this.game.musicPlayerStop();
                    }
                }

                //hudební přehrávač - hlasitost
                if(this.inMenuCurrent === menuControlItems.indexOf('musicVol')){
                    
                    //změna hudby a nebo její zastaveni
                    if(nsmer === 1)
                    {
                        this.game.musicPlayerVolumeUp();
                    }
                    else
                    {
                        this.game.musicPlayerVolumeDown();
                    }
                }
            }

            //update state
            this.game.updatePlayersInfo();
        }

    }


    /**
     * aplikace ovládání mna vozítko
     * řeší:
     * aplikování nového směru jízdy
     * brždění
     * a bonusovou akceleraci pokud jedu podlel jiné čáry
     */
    controlPlayer(nsmer){

        //nastavení nového směru
        if (nsmer !== this.smer && ((this.smer === 0 && nsmer !== 2) || (this.smer === 1 && nsmer !== 3) || (this.smer === 2 && nsmer !== 0) || (this.smer === 3 && nsmer !== 1))) {

            //nový směr
            this.smer = nsmer;
            //mením směr, nemůže jít o portál
            this.currentGateIsPortal = false;
            this.strightLenght = 0;
            this.strightTime = 0;
            this.maxLine = 0;

            this.cycleWithoutNewSmer = false;
        }
        
        //brzdění
        if ((this.smer === 0 && nsmer === 2) || (this.smer === 1 && nsmer === 3) || (this.smer === 2 && nsmer === 0) || (this.smer === 3 && nsmer === 1)) {

            this.breaking = true;

            if (this.bonusSpeed - (this.game.movestep * 0.02) > -1.5) {
                this.bonusSpeed -= this.game.movestep * 0.02;
            }
            else {
                this.bonusSpeed = -1.5;
            }
        }

        //turbo, jen pokud jedu dost blízko vedle někoho jiného, a držím klávesu ve směru jízdy
        if (this.smer === nsmer) {


            if(this.LastNeighborDetection <= 0)
            {

                //resetuju počítadlo a zkontroluju kolize
                this.LastNeighborDetection = this.neighborDetectionInterval;

                //kontrola přítomnosti vedlejší linky pomocí dvou detektorů do stran oproti směru jízdy
                this.neighborClose1 = false;
                this.neighborClose2 = false;

                let detect1 = null;
                let detect2 = null;

                let colX = this.x;
                let colY = this.y;

                if(this.smer === 0 || this.smer === 2) {
                    if (this.smer === 0) {
                        //nahoru
                        colY = this.y -2;
                    }
                    if (this.smer === 2) {
                        //dolu
                        colY = this.y +2;
                    }    
                    detect1 = this.game.ctx.getImageData(Math.floor(this.x + 2), Math.floor(colY), 20, 1).data;
                    detect2 = this.game.ctx.getImageData(Math.floor(this.x - 22), Math.floor(colY), 20, 1).data;   
                }

                if (this.smer === 3 || this.smer === 1) {
                    if(this.smer === 3){
                        //nalevo
                        colX = this.x - 2;
                    }
                    if (this.smer === 1) {
                        //napravo
                        colX = this.x + 2;                        
                    }                    
                    detect1 = this.game.ctx.getImageData(Math.floor(colX), Math.floor(this.y + 2), 1, 20).data;
                    detect2 = this.game.ctx.getImageData(Math.floor(colX), Math.floor(this.y - 22), 1, 20).data;
                }

                if (detect1 !== null) {
                    //kontroluju detektor 1
                    for (let i = 3; i < detect1.length; i = i + 4) {
                        if (detect1[i] > 150) {
                            this.neighborClose1 = true;
                            
                            let colision = (i+1) / 4;
                            if(this.smer === 0 || this.smer === 2)
                            {
                                colX = this.x + 2 + colision;
                            }
                            else
                            {
                                colY = this.y + 2 + colision;
                            }

                            this.storm1x = colX;
                            this.storm1y = colY;
                            //this.game.addConnection(this.x,this.y,colX,colY,this.color, connectionType.PORTAL);
                            break;
                        }
                    }
                }

                if(!this.neighborClose1)
                {
                    this.storm1x = this.x;
                    this.storm1y = this.y;
                }


                if (detect2 !== null ) {
                    //kontroluju detektor 2
                    for (let i = detect1.length-1; i >=0 ; i = i - 4) {
                        if (detect2[i] > 150) {
                            this.neighborClose2 = true;

                            let colision = (detect1.length / 4) - ((i+1) / 4);
                            if(this.smer === 0 || this.smer === 2)
                            {
                                colX = this.x - 2 - colision;
                            }
                            else
                            {
                                colY = this.y - 2 - colision;
                            }

                            this.storm2x = colX;
                            this.storm2y = colY;
                            //this.game.addConnection(this.x,this.y,colX,colY,this.color, connectionType.PORTAL);
                            break;
                        }
                    }
                }
            }

            if(!this.neighborClose2)
            {
                this.storm2x = this.x;
                this.storm2y = this.y;
            }




            if (this.neighborClose1 || this.neighborClose2 || (this.cycleWithoutNewSmer && this.energy > 0) || this.overDriveCooldown === true) {

                if(!this.neighborClose1 && !this.neighborClose2 && this.overDriveCooldown === false && this.cycleWithoutNewSmer && this.energy > 0)
                {
                    this.cycleWithoutNewSmer = false;
                    //vyplýtvám jeden overdrive
                    this.energy -=1;
                    this.overDriveDuration = this.overDriveDurationDefault;
                    this.overDriveCooldown = true;
                    this.game.playSound(19,1);  

                    //update state
                    this.game.updatePlayersInfo();
                }

                //bonus aktivní (jedu kolem něčeho, zrychluju)
                if (this.bonusSpeed + (this.game.movestep * 0.04) < this.maxBonusSpeed) {
                    this.bonusSpeed += this.game.movestep * 0.04;
                }
                else {
                    //maximum je 1.5
                    this.bonusSpeed = this.maxBonusSpeed;
                }
            }
            else{
                this.storm1x = this.storm2x = this.x;
                this.storm1y = this.storm2y = this.y;
            }
        }
    }


    /**
     * zpracuje novou polohu hráče na základě nastaveného směru a okolností
     */
    processNewPosition()
    {
        //staré hodnoty odložím (pro potřeby kreslení)
        this.oldx = this.x;
        this.oldy = this.y;

        //opožděný přesun portálem
        if(this.transferDelay <= 0 && this.transferX!= null && this.transferY !=null)
        {
            //vymažeme prostor okolo vstupního portálu
            //experiment
            //this.game.addExplosion(this.x,this.y, 0, explosionType.CLEAR,0,0,0);
         
            //přesun
            this.moveToXY(this.transferX, this.transferY);

            //smažu nové místo na přesun
            this.transferX = this.transferY = null;
        }


        //zpomalování 
        if (this.bonusSpeed > 0) {
          
            if (this.bonusSpeed - (this.game.movestep * 0.01) > 0) {
                this.bonusSpeed -= this.game.movestep * 0.01;
            }
            else {
                this.bonusSpeed = 0;
            }
        }

        //zrychlování (turbo)
        if (this.bonusSpeed < 0) {
          
            if (this.bonusSpeed + (this.game.movestep * 0.005) < 0) {
                this.bonusSpeed += this.game.movestep * 0.005;
            }
            else {
                this.bonusSpeed = 0;
            }
        }

        //update polohy vozítka podle předchozí hodnoty
        this.maxLine = this.strightLenght;
        if(this.maxLine > 30)
        {
            this.maxLine = 30;
        }

        let stepAmount = (this.baseSpeed + this.bonusSpeed) * this.game.movestep;

        let newx = this.x;
        let newy = this.y;

        if (this.smer === 0) {
            newx = Math.floor(this.x);
            newy -= stepAmount;
        }

        if (this.smer === 1) {
            newx += stepAmount;
            newy = Math.floor(this.y);
        }

        if (this.smer === 2) {
            newx = Math.floor(this.x);
            newy += stepAmount;

        }

        if (this.smer === 3) {
            newx -= stepAmount;
            newy = Math.floor(this.y);
        }

        //výpočet ujeté vzdálenosti rovně
        this.strightLenght += stepAmount;


        //zaokrouhlíme novou polohu
        let floorX = Math.floor(newx);
        let floorY = Math.floor(newy);

        //zbírání bonusů
        let points = this.game.colectPoints(newx,newy, this.team);
        if(points!= null)
        {
            console.log(points);

            if(this.team > 0){
                //navýším score celému týmu
                this.game.updateTeamScore(this.team,points);
                //energii, jen hráči
                this.energy += points.sumEnergy;
            }
            else{
                //mavýším score jen sobě
                this.score += points.sumScore;
                this.energy += points.sumEnergy;
            }
            
            this.game.updatePlayersInfo();
        }

        //kolize s překážkama se kontroluje vždy, když se změní zaokrouhlená poloha (nemá smysl počítat když jsme se posunul o 0,1)
        if (this.lastCheckX !== floorX || this.lastCheckY !== floorY) {

            let detector = null;

            //nová detekce kolize
            if (Math.abs(this.lastCheckX - floorX) >= 1) {
                //rozdíl v x
                if (floorX > this.lastCheckX) {
                    //kontrola kolize
                    detector = this.game.ctx.getImageData(this.lastCheckX + 1, floorY, floorX - this.lastCheckX, 1).data;
                } else {
                    //kontrola kolize floorX < this.lastCheckX
                    detector = this.game.ctx.getImageData(floorX, floorY, this.lastCheckX - floorX, 1).data;
                }
            }
            if (Math.abs(this.lastCheckY - floorY) >= 1) {
                //rozdíl v y
                if (floorY > this.lastCheckY) {
                    //kontrola kolize
                    detector = this.game.ctx.getImageData(floorX, this.lastCheckY + 1, 1, floorY - this.lastCheckY).data;
                } else {
                    //kontrola kolize floorY < this.lastCheckY
                    detector = this.game.ctx.getImageData(floorX, floorY, 1, this.lastCheckY - floorY).data;
                }
            }

            //uložíme zaokrouhlený moment poslední kontorly
            this.lastCheckX = floorX;
            this.lastCheckY = floorY;

            //kontrola kolize
            let boom = false;
            let gate = false;

            if (detector !== null) {
                //kontroluju detektor
                for (let i = 3; i < detector.length; i = i + 4) {
                    
                    //poloprůhledná cesta
                    if (detector[i] > 30 && detector[i] < 100) {
                        gate = true;
                        break;
                    }
                    
                    //pevná barva
                    if (detector[i] > 150) {
                        boom = true;
                        break;
                    }
                }
            }

            //výbuch v případě detekování kolize
            if (boom) {

                this.playerPhase = playerPhaseEnum.DEATH;
                this.deaths++;
                //aktivuju výbuch
                this.explosion = true;

            }
            else {

                //hledání portálu
                if(gate && this.transferDelay<=0){
                    
                    //vyhledám portály
                    let portal = this.game.findPortal(this.x, newx, this.y, newy);


                    if (portal!=null)
                    {

                        //dohledám nějaký portál na přesun
                        let randPortal = this.game.getRandomPortal(portal.ori, portal.id, this.portalDial);

                        if(randPortal!=null){

                            let plusX = 0;
                            let plusY = 0;

                            if(portal.ori === orientation.HORIZONTAL){

                                //spočítáme, kde vstupuju do portálu
                                let diff = portal.endx - portal.beginx; 
                                let procX = 1 - ((portal.endx - newx) / diff);
                                
                                //spočítám kde vystupuju z náhodného portálu
                                let randDiff = randPortal.endx - randPortal.beginx;
                                plusX = procX * randDiff;

                                //poskočím o 3 px
                                if (this.smer === 0) {
                                    plusY -= 3;
                                }
                                if (this.smer === 2) {
                                    plusY += 3;
                                }

                            }
                            else{
                                //spočítáme, kde vstupuju do portálu
                                let diff = portal.endy - portal.beginy; 
                                let procY = 1 - ((portal.endy - newy) / diff);
                                
                                //spočítám kde vystupuju z náhodného portálu
                                let randDiff = randPortal.endy - randPortal.beginy;;
                                plusY = procY * randDiff;

                                //poskočím o 3 px
                                if (this.smer === 1) {
                                    plusX += 3;
                                }
                                if (this.smer === 3) {
                                    plusX -= 3;
                                }
                            }

                            //nastavím, kam se budu odložene přesouvat
                            this.transferX = Math.floor(randPortal.beginx + plusX);
                            this.transferY = Math.floor(randPortal.beginy + plusY);

                            //přidám propojku, která na chvilku ukáže kam jsme letěl portálem
                            this.game.addConnection(newx,newy,this.transferX,this.transferY,this.color, connectionType.PORTAL);
                            this.game.playSound(3,1);  

                            //odložený přesun
                            this.transferDelay = this.transferDelayDefault;

                            //resetuju portal dial na 0
                            if(this.portalDial!==0)
                            {
                                this.portalDial = 0;
                                //změna, update
                                this.game.updatePlayersInfo();
                            }

                        }
                    }
                }

                //zapsání nové polohy, posuv na novou souřadnici bez výbuchu a přesunu
                if(this.transferDelay <=0)
                {
                    this.x = newx;
                    this.y = newy;
                }
            }
        }
        else {

            //poloha se nezměnila, zapíšu nový malinký posuv (mikropohyb)
            if(this.transferDelay <=0)
            {
                this.x = newx;
                this.y = newy;
            }
        }

        let bottomHudsHeight = this.game.getBottomHudsHeight();

        //teleport na druhou stranu
        if (this.y < 15) {
            this.y = this.storm1y = this.storm2y = this.oldy = this.lastCheckY = this.game.canvas.height - (bottomHudsHeight + 15);
        }
        if (this.x > this.game.canvas.width - 15) {
            this.x = this.storm1x = this.storm2x = this.oldx = this.lastCheckX = 15;
        }
        if (this.y > this.game.canvas.height - (bottomHudsHeight + 15)) {
            this.y = this.storm1y = this.storm2y = this.oldy = this.lastCheckY = 15;
        }
        if (this.x < 15) {
            this.x = this.storm1x = this.storm2x = this.oldx = this.lastCheckX = this.game.canvas.width - 15;
        }

    }




    /**
     * update hráče a jeho činnost ve hře, 
     * vrací informaci o tom, zda hráč něco stiskl
     * @param {*} pause - informace o tom zda hra aktuálně beží
     * @param {*} gamepads - pole aktuálního stavu kontrolerů
     */
    update (pause, gamepads) {

        //reset ovládání
        this.actual.left = false;
        this.actual.right = false;
        this.actual.up = false;
        this.actual.down = false;
        this.actual.gate = false;
        this.actual.blockade = false;
        this.actual.ram = false;
        this.actual.dial = false;
        this.actual.pause = false;
        this.anyAction = false;

        //nový směr
        let nsmer = null;
        this.breaking = false;

        //zprocesuju ovládání hráčů
        nsmer = this.processControlls(gamepads);
        if(!pause){
            this.processAIControlls();
        
            //zprocesuju odpočty
            this.timeoutPausableProcess();
        }
        
        this.timeoutsProcess();

        //řízení menu
        if(this.playerPhase === playerPhaseEnum.INMENU && nsmer!=null){
            this.controlMenu(nsmer);
        }

        if(!pause){

            //otočení před hrou
            if (this.playerPhase === playerPhaseEnum.READY && nsmer!=null) {
                this.smer = nsmer;
            }

            //změna směru
            if (this.playerPhase === playerPhaseEnum.GAME){
                if(nsmer !== null) {
                    this.controlPlayer(nsmer);
                }
                else{
                    this.cycleWithoutNewSmer = true;
                }
            }

            //obecné zpracování pohybu všech hráčů, včetně AI
            if (this.playerPhase === playerPhaseEnum.GAME ) {
                this.processNewPosition();
            }
        }

        if(this.isFirst)
        {
            if(this.playerPhase === playerPhaseEnum.GAME && !this.soundFirstPlayed)
            {
                this.soundFirstPlayed = true;
                this.game.playSound(10,1);
            }
        }
        else
        {
            this.soundFirstPlayed = false;
        }

        return this.anyAction;
    };


    drawVehicle (x, y) {

        this.game.infoctx.fillStyle = greatColors[this.color];
        this.game.infoctx.filter = "blur(7px)";
        this.game.infoctx.fillRect(x-4, y-4, 8, 8);
        this.game.infoctx.filter = "none";

        if(this.isFirst)
        {
            this.game.infoctx.filter = "blur(7px)";
            this.game.infoctx.fillRect(x-6, y-6, 12, 12);
            this.game.infoctx.filter = "none";
        }





        //příprava efektu rychlé jízdy
        let speedLineOpacity = 0;
        if(this.bonusSpeed>0){
            speedLineOpacity = (this.maxLine / 30) * (this.bonusSpeed / this.maxBonusSpeed) * 150;
        }
        let speedLineColor = '#FFFFFF' + rgbToHex(Math.floor(speedLineOpacity));
        

        if (this.playerPhase === playerPhaseEnum.READY) {
            this.game.infoctx.fillStyle = greatColors[this.color];
        }

        if (this.playerPhase === playerPhaseEnum.GAME) {
            this.game.infoctx.fillStyle = '#FFFFFFFF';
        }


        if (this.smer === 0) {

            //kola
            if (this.type === 3) {
                this.game.infoctx.fillRect(x, y - 1, 1, 2);
            }
            else {
                this.game.infoctx.fillRect(x - 3, y, 2, 2);
                this.game.infoctx.fillRect(x + 2, y, 2, 2);
            }
            this.game.infoctx.fillRect(x - 3, y + 4, 2, 2);
            this.game.infoctx.fillRect(x + 2, y + 4, 2, 2);

            //rychlé pruhy
            this.game.infoctx.fillStyle = speedLineColor;

            this.game.infoctx.fillRect(x - 3, y + 8, 2, this.maxLine * 0.25 + Math.floor(Math.random() * this.maxLine) * 0.75) ;
            this.game.infoctx.fillRect(x + 2, y + 8, 2, this.maxLine * 0.25 + Math.floor(Math.random() * this.maxLine) * 0.75) ;

            //brzdovky
            if(this.breaking){

                this.game.infoctx.fillStyle = "#FF0000" + rgbToHex(55+ Math.floor(Math.random()*200));
                this.game.infoctx.fillRect(x - 3, y + 7, 7, 4) ;
            }

        }

        if (this.smer === 1) {

            //kola
            if (this.type === 3) {
                this.game.infoctx.fillRect(x, y, 2, 1);
            }
            else {
                this.game.infoctx.fillRect(x - 1, y - 3, 2, 2);
                this.game.infoctx.fillRect(x - 1, y + 2, 2, 2);
            }
            this.game.infoctx.fillRect(x - 5, y - 3, 2, 2);
            this.game.infoctx.fillRect(x - 5, y + 2, 2, 2);

            //rychlé pruhy
            this.game.infoctx.fillStyle = speedLineColor;

            this.game.infoctx.fillRect(x - 8, y - 3, - (this.maxLine * 0.25 + Math.floor(Math.random() * this.maxLine) * 0.75) ,2) ;
            this.game.infoctx.fillRect(x - 8, y + 2, - (this.maxLine * 0.25 + Math.floor(Math.random() * this.maxLine) * 0.75) ,2) ;

            //brzdovky
            if(this.breaking){

                this.game.infoctx.fillStyle = "#FF0000" + rgbToHex(55+ Math.floor(Math.random()*200));
                this.game.infoctx.fillRect(x - 6, y - 3, - 4 ,7) ;
            }
        }

        if (this.smer === 2) {

            //kola
            if (this.type === 3) {
                this.game.infoctx.fillRect(x, y - 1, 1, 2);
            }
            else {
                this.game.infoctx.fillRect(x - 3, y - 2, 2, 2);
                this.game.infoctx.fillRect(x + 2, y - 2, 2, 2);
            }

            this.game.infoctx.fillRect(x - 3, y - 6, 2, 2);
            this.game.infoctx.fillRect(x + 2, y - 6, 2, 2);

            //rychlé pruhy
            this.game.infoctx.fillStyle = speedLineColor;

            this.game.infoctx.fillRect(x - 3, y - 8, 2, -(this.maxLine * 0.25 + Math.floor(Math.random() * this.maxLine) * 0.75)) ;
            this.game.infoctx.fillRect(x + 2, y - 8, 2, -(this.maxLine * 0.25 + Math.floor(Math.random() * this.maxLine) * 0.75)) ;

            //brzdovky
            if(this.breaking){

                this.game.infoctx.fillStyle = "#FF0000" + rgbToHex(55+ Math.floor(Math.random()*200));
                this.game.infoctx.fillRect(x - 3, y - 7, 7, -4) ;
            }
        }

        if (this.smer === 3) {

            //kola
            if (this.type === 3) {
                this.game.infoctx.fillRect(x - 1, y, 2, 1);
            }
            else {
                this.game.infoctx.fillRect(x, y - 3, 2, 2);
                this.game.infoctx.fillRect(x, y + 2, 2, 2);
            }

            this.game.infoctx.fillRect(x + 4, y - 3, 2, 2);
            this.game.infoctx.fillRect(x + 4, y + 2, 2, 2);

            //rychlé pruhy
            this.game.infoctx.fillStyle = speedLineColor;

            this.game.infoctx.fillRect(x + 8, y - 3, this.maxLine * 0.25 + Math.floor(Math.random() * this.maxLine) * 0.75 ,2) ;
            this.game.infoctx.fillRect(x + 8, y + 2, this.maxLine * 0.25 + Math.floor(Math.random() * this.maxLine) * 0.75 ,2) ;

            //brzdovky
            if(this.breaking){

                this.game.infoctx.fillStyle = "#FF0000" + rgbToHex(55+ Math.floor(Math.random()*200));
                this.game.infoctx.fillRect(x + 7, y - 3, + 4 ,7) ;
            }
        }

    };

    drawRam (x, y) {

        if (this.playerPhase === playerPhaseEnum.GAME) {
            this.game.infoctx.fillStyle = '#FFFFFFFF';

            //this.game.infoctx.fillStyle = greatColors[this.color];
        }


        if (this.smer === 0) {

            this.game.infoctx.fillRect(x - 6, y - 4, 13, 2);

            this.game.ctx.clearRect(x - 6, y - 4, 13, 2);
        }

        if (this.smer === 1) {

            this.game.infoctx.fillRect(x + 3, y - 6, 2, 13);            

            this.game.ctx.clearRect(x + 3, y - 6, 2, 13);  
        }

        if (this.smer === 2) {

            this.game.infoctx.fillRect(x - 6, y + 4, 13, 2);

            this.game.ctx.clearRect(x - 6, y + 4, 13, 2);
        }

        if (this.smer === 3) {

            this.game.infoctx.fillRect(x - 3, y - 6, 2, 13);

            this.game.ctx.clearRect(x - 3, y - 6, 2, 13);
        }
    };


    /**
     * tohle umí vymazat kruh skrz nastavení kreslení
     */
    clearCircle(context, x, y, radius){

        context.globalCompositeOperation = 'destination-out';
        context.beginPath();
        context.arc(x, y, radius, 0, Math.PI*2, true);
        context.fill();
        context.globalCompositeOperation = 'source-over';

    };



    draw () {

        let fillStyle = this.game.ctx.fillStyle;

        //vykreslování bude jen v celých číselch (výpočet však bude v přesnějších)
        let x = Math.floor(this.x);
        let y = Math.floor(this.y);

        let oldx = Math.floor(this.oldx);
        let oldy = Math.floor(this.oldy);


        if (this.controlledBy === controls.GAMEPAD || this.controlledBy === controls.KEYBOARD) {

            //this.game.infoctx.fillStyle = '#FFF';
            //this.game.infoctx.textAlign = "begin";
            //this.game.infoctx.font = "10px arial";
            //this.game.infoctx.fillText((this.baseSpeed + this.bonusSpeed).toFixed(2), x + 5, y);

        }

        if (this.explosion) {
            //vymazání kruhu

            //výbuch
            this.game.addExplosion(x,y, this.color , explosionType.PLAYER, this.smer, this.baseSpeed + this.bonusSpeed, this.team);
            this.explosion = false;
        }

        if (this.playerPhase === playerPhaseEnum.READY) {

            let size = (1 / this.restartTimeoutDefault) * this.restartTimeout;
            if (size < 0) {
                size = 0;
            }

            //barva
            let size255 = 255; //30 + Math.floor(size * 40);

            //velikost kolečka
            let sizeBall = size * 20;
            if (sizeBall < 10)
                sizeBall = 10;

            //barva pozadí
            if (size > 0.03) {
                this.game.infoctx.fillStyle = greatColors[this.color] + rgbToHex(size255);
            } else
            {
                this.game.infoctx.fillStyle = greatColors[this.color];
            }

            
            //barva hráče
            if(this.clearBehindPlayer === true)
            {
                //pokus o kruhové mazání
                clearCircle(this.game.ctx, this.x, this.y, 40);
                this.clearBehindPlayer = false;
            }

            this.drawVehicle(x, y);

            this.game.infoctx.strokeStyle = greatColors[this.color];

            this.game.infoctx.lineWidth = 2;
            this.game.infoctx.beginPath();
            this.game.infoctx.arc(x, y, sizeBall * 2, 0, 2 * Math.PI);
            this.game.infoctx.stroke();

            this.game.infoctx.lineWidth = 4;
                        
            //šipka

            //this.game.infoctx.strokeStyle = "#FFFFFF";
            this.game.infoctx.lineWidth = 4;
            if (this.smer === 0) {
                this.game.infoctx.beginPath();
                this.game.infoctx.moveTo(this.x + sizeBall * 1.1, this.y + sizeBall * 1.1);
                this.game.infoctx.lineTo(this.x, this.y - sizeBall * 1.4);
                this.game.infoctx.lineTo(this.x - sizeBall * 1.1, this.y + sizeBall * 1.1);
                this.game.infoctx.stroke();
            }

            if (this.smer === 1) {
                this.game.infoctx.beginPath();
                this.game.infoctx.moveTo(this.x - sizeBall * 1.1, this.y - sizeBall * 1.1);
                this.game.infoctx.lineTo(this.x + sizeBall * 1.4, this.y);
                this.game.infoctx.lineTo(this.x - sizeBall * 1.1, this.y + sizeBall * 1.1);
                this.game.infoctx.stroke();
            }

            if (this.smer === 2) {
                this.game.infoctx.beginPath();
                this.game.infoctx.moveTo(this.x - sizeBall * 1.1, this.y - sizeBall * 1.1);
                this.game.infoctx.lineTo(this.x, this.y + sizeBall * 1.4);
                this.game.infoctx.lineTo(this.x + sizeBall * 1.1, this.y - sizeBall * 1.1);
                this.game.infoctx.stroke();
            }

            if (this.smer === 3) {
                this.game.infoctx.beginPath();
                this.game.infoctx.moveTo(this.x + sizeBall * 1.1, this.y + sizeBall * 1.1);
                this.game.infoctx.lineTo(this.x - sizeBall * 1.4, this.y);
                this.game.infoctx.lineTo(this.x + sizeBall * 1.1, this.y - sizeBall * 1.1);
                this.game.infoctx.stroke();
            }            
        }

        //běžné vykreslení hráče
        if (this.playerPhase !== playerPhaseEnum.READY) {
            
            
            //barva hráče
            this.drawVehicle(x, y);

            //beranidlo
            if (this.ramDuration > 0)
            {
                this.drawRam(x,y);
            }

            //kreslení linky
            if (this.gateDuration > 0) {
                //barva brány
                this.game.ctx.fillStyle = gateColor;
            }
            else {
                //barva hráče
                this.game.ctx.fillStyle = greatColors[this.color];
            }

            if (this.smer === 0) {
                this.game.ctx.fillRect(x - 1, y + 1, 3, oldy - y);
            }

            if (this.smer === 1) {
                this.game.ctx.fillRect(oldx, oldy - 1, x - oldx, 3);
            }

            if (this.smer === 2) {
                this.game.ctx.fillRect(oldx - 1, oldy - 1, 3, y - oldy);
            }

            if (this.smer === 3) {
                this.game.ctx.fillRect(x + 1, y - 1, oldx - x, 3);
            }

            //blokáda
            if (this.blockadeDuration > 0) {
                let zbyva = this.blockadeDurationDefault - this.blockadeDuration;

                if (this.blockadeSpawnCurrentStep * 41 < zbyva) {
                    //vyhodí tecku kazdych 41 ms. (celkem pak 12)
                    this.blockadeSpawnCurrentStep++;

                    let dropx = 0;
                    let dropy = 0;

                    if (this.smer === 0) {
                        dropy = 20;
                    }

                    if (this.smer === 1) {
                        dropx = -20;
                    }

                    if (this.smer === 2) {
                        dropy = -20;
                    }

                    if (this.smer === 3) {
                        dropx = 20;
                    }

                    var varX = -18 + Math.floor(Math.random() * 36);
                    var varY = -18 + Math.floor(Math.random() * 36);

                    //make it happend
                    this.game.ctx.fillRect(x + dropx + varX, y + dropy + varY, 2, 2);

                }
            }

            //storm

            if(this.toStorm <= 500){

                let stormx = null;
                let stormy = null;

                let ran = Math.floor(Math.random() * 2);
                if(ran === 0){
                    stormx = this.storm1x;
                    stormy = this.storm1y;                        
                }
                if(ran === 1){
                    stormx = this.storm2x;
                    stormy = this.storm2y;    
                }

                let a = this.x - stormx;
                let b = this.y - stormy;
                let c = Math.sqrt( a*a + b*b );

                if(c<=30)
                {
                    //barva hráče
                    this.game.infoctx.strokeStyle = greatColors[this.color];
                    this.game.infoctx.lineWidth = 1;
                    this.game.infoctx.beginPath();
                    this.game.infoctx.moveTo(x, y);
                    this.game.infoctx.lineTo(x - 5  + Math.floor(Math.random() * 10), y - 5  + Math.floor(Math.random() * 10));
                    this.game.infoctx.lineTo(stormx - 5  + Math.floor(Math.random() * 10), stormy - 5  + Math.floor(Math.random() * 10));
                    this.game.infoctx.lineTo(stormx, stormy);
                    this.game.infoctx.stroke();
                }
            }
        }


        this.game.ctx.fillStyle = fillStyle;
    };

}
