import React from 'react';
import { controls } from '../objects/Enums';
import { withTranslation } from 'react-i18next';

export class Hud extends React.Component {

    render() {

        const { t } = this.props;

        const style = {
            backgroundColor : this.props.color 
        }

        //miny
        const blockadeClassName = this.props.blockadeCooldown ? 'PowerUp animuj10s' : 'PowerUp'
        const blockadeStyle = {
            backgroundColor : this.props.blockadeCooldown ? this.props.color : "black"
        }

        //portál
        const portalClassName = this.props.portalCooldown ? 'PowerUp animuj15s' : 'PowerUp'
        const portalStyle = {
            backgroundColor : this.props.portalCooldown ? this.props.color : "black"
        }

        //beranidlo
        const ramClassName = this.props.ramCooldown ? 'PowerUp animuj30s' : 'PowerUp'
        const ramStyle = {
            backgroundColor : this.props.ramCooldown ? this.props.color : "black"
        }

        //overdrive
        const overDriveClassName = this.props.overDriveCooldown ? 'PowerUp animuj10sback' : 'PowerUp'
        const overDriveStyle = {
            backgroundColor : this.props.overDriveCooldown ? this.props.color : "black"
        }

        const isFirst = this.props.isFirst;

        return(
        <div className="PlayerInfo">
            
            <div className="ColorDotLine">
                <div className="ColorDot" style={style}>
                    { isFirst && <div className="WinnFlash" />}
                    { !isFirst && <div className="ColorDotText" >P{this.props.index}</div>}
                </div>

                { this.props.controlledBy !== controls.AI && 
                    <>
                        <img src={process.env.PUBLIC_URL + '/magic-portal.svg'} alt="powerup portal" className={portalClassName} style={portalStyle} />
                        <img src={process.env.PUBLIC_URL + '/missile-swarm.svg'} alt="powerup 1" className={blockadeClassName} style={blockadeStyle}/>
                        <img src={process.env.PUBLIC_URL + '/thor-hammer.svg'} alt="powerup 3" className={ramClassName} style={ramStyle}/>
                        <div className="powerupWithCount">
                            <img src={process.env.PUBLIC_URL + '/overdrive.svg'} alt="powerup 4" className={overDriveClassName} style={overDriveStyle}/>
                            <span className="overdriveCount">{this.props.energy}</span>
                        </div>
                    </>
                }
            </div>
            <span className="scoreSpan">{this.props.controlledBy !== controls.AI && <>{t('game.score')}:</>} {this.props.score}</span>
            <div className="keyDiv">
                { this.props.portalDial > 0 && <span className="keySpan" style={style}></span>}
                { this.props.portalDial > 1 && <span className="keySpan" style={style}></span>}
                { this.props.portalDial > 2 && <span className="keySpan" style={style}></span>}
                { this.props.portalDial > 3 && <span className="keySpan" style={style}></span>}
            </div>
        
        </div>
        );
    }
}

export default withTranslation('common')(Hud);