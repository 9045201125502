import React from 'react';
import { withTranslation } from 'react-i18next';

export class ShareAndPaypal extends React.Component {


    openThat(url, event){
        window.open(url,'','menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=450,width=550,left=300,top=300');
    }

    render() {
        
        const { t } = this.props;
        let twitterURL = 'https://twitter.com/intent/tweet?url=https://cybertracers.devbob.cz/&amp;hashtags=cybertracers,couchgame';
        let facebookURL = 'https://www.facebook.com/sharer.php?u=https://cybertracers.devbob.cz/&amp;hashtag=%23cybertracers';
        let vkURL = 'https://vk.com/share.php?url=https://cybertracers.devbob.cz/';
        //vyresit problem https://mathiasbynens.github.io/rel-noopener/


        return(
            <div className="shareAndDonation noselect">

                <div>
                    {t('game.donateText')}
                </div>

                <div className="shareAndDonationEncapsuler">
                    <a title={t('game.shareTwitter')} target="_blank" rel="noopener noreferrer" className="shareButton twButton" 
                    href={twitterURL} 
                    onClick={this.openThat.bind(this, twitterURL)}></a>
                </div>

                <div className="shareAndDonationEncapsuler">
                    <a title={t('game.shareFacebook')} target="_blank" rel="noopener noreferrer" className="shareButton fbButton" 
                    href={facebookURL} 
                    onClick={this.openThat.bind(this, facebookURL)}></a>
                </div>


                <div className="shareAndDonationEncapsuler">
                    <a title={t('game.shareVK')} target="_blank" rel="noopener noreferrer" className="shareButton vkButton" 
                    href={vkURL} 
                    onClick={this.openThat.bind(this, vkURL)}></a>
                </div>
                
                {/* <div className="shareAndDonationEncapsuler">
                    <a title={t('game.paypalButtonCodeCZK')} className="shareButton itchLink" href="https://devbobcz.itch.io/" onClick="window.open(this.href,'','menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=450,width=550,left=300,top=300');return false;"></a>
                </div> */}

                <div className="shareAndDonationEncapsuler">
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input type="hidden" name="hosted_button_id" value={t('game.paypalButtonCodeCZK')} />
                        <input type="image" className="donateImg" src={process.env.PUBLIC_URL + '/donate.svg'} border="0" name="submit" title={t('game.donateTitle')} alt={t('game.donateInfo')} />
                    </form>
                </div>
                <div className="shareAndDonationEncapsuler">
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input type="hidden" name="hosted_button_id" value={t('game.paypalButtonCodeEUR')} />
                        <input type="image" className="donateImg" src={process.env.PUBLIC_URL + '/donateEUR.svg'} border="0" name="submit" title={t('game.donateTitle')} alt={t('game.donateInfo')} />
                    </form>
                </div>
                <div className="shareAndDonationEncapsuler">
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input type="hidden" name="hosted_button_id" value={t('game.paypalButtonCodeUSD')} />
                        <input type="image" className="donateImg" src={process.env.PUBLIC_URL + '/donateUSD.svg'} border="0" name="submit" title={t('game.donateTitle')} alt={t('game.donateInfo')} />
                    </form>
                </div>
                {/* <div>{t('game.thankYou')}</div> */}
            </div>
        );
    }
}

export default withTranslation('common')(ShareAndPaypal);