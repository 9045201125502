import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import TitleScreen from './components/TitleScreen';
import Results from './components/Results';
import Hud from './components/Hud'
import Countdown from './components/Countdown';
import { controls, gamePhaseEnum, greatColors, orientation, zoneType, menuControlItems} from './objects/Enums';
import { keyName } from './objects/Support'
import Player from './objects/Player';
import Portal from './objects/Portal';
import Connection from './objects/Connection';
import SpecialZone from './objects/SpecialZone';
import Explosion from './objects/Explosion';
import BonusPoint from './objects/BonusPoint';
import Rotator from './objects/Rotator';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import ReactGA from "react-ga";
import Tutorial from './components/Tutorial';
import MusicPlayer from './components/MusicPlayer';
import * as serviceWorker from './serviceWorker';




export class App extends React.Component {
  
  constructor(props){  

    super(props);  
    this.hudsRef = React.createRef();
    this.playerRef = React.createRef();
    
    //proměnné hry
    this.delay = 0;
    this.time = 0;
    this.gameSpeed = 50;

    this.pause = false;
    this.prevEsc = false;
    this.lastEsc = 0;
    this.tutorialBefore = 500;

    //pole pro stisknuté klávesy
    this.keydown = {};
    //pole pro zjištění aktuálních stavů gamepadů
    this.gamepads = null;
    this.gamepadsMappingsArray = [];

    //pole hráčů
    this.players = [];
    this.AIDemoPlayers = 10;
    this.AIplayers = 0;

    //první stejná barva
    this.sameAIColor = 0;

    //pole portálů
    this.portals = [];
    this.portalCounter = 0;

    //pole bonusů
    this.bonusPoints = [];
    this.bonusPointsCounter = 0;

    //pole propojení
    this.connections = [];

    //pole speciálních zón
    this.zones = [];
    this.zoneCounter = 0;
    
    this.eraserZoneCount = 0;
    this.eraserZoneDelay = 0;


    //pole rotátorů
    this.rotators = [];
    this.rotatorCounter = 0;


    //pole explozí
    this.explosions = [];

    //odpočet pro spawning speciálních zón
    this.specialZoneTimeout = 0;
    this.specialZoneKillerTimeout = 0;

    this.startCountdownRunning = false;
    this.startCountdown = 5000;
    this.startCountdownDefault = 5000;


    //odpočet hry (aktuálně 5minut)
    this.gameCountdown = 300000;
    this.gameCountdownDefault = 300000;
    
    //dočasná viditelnost odpočtu
    this.countDownTempVisible = 0;
    this.countDownTempVisibleDefault = 10000;

    //zajisťuje automatické přepínání pohledů v menu
    this.menuAutoMoveCountdown = 60000;
    this.menuAutoMoveCountdownDefault = 20000;
    // this.menuAutoMoveCountdown = 10000000;
    // this.menuAutoMoveCountdownDefault = 10000000;

    //timeout změn portálů
    this.targetCodeChangingCountdown = 15000;
    this.targetCodeChangingCountdownDefault = 15000;

    //dočasné znemožnění opuštění výsledkové listiny
    this.menuFreezeCountdown = 0;
    this.menuFreezeCountdownDefault = 2000;

    //čas pro povel ovládání v menu
    this.menuActionTimeout = 200;
    this.menuActionTimeoutDefault = 200;

    //zvuky
    this.soundEnabled = true;

    this.soundPlayer1 = document.createElement("audio");  
    this.soundPlayer1.src = "sounds.mp3";
    this.soundPlayer2 = document.createElement("audio");  
    this.soundPlayer2.src = "sounds.mp3";
    this.soundPlayer3 = document.createElement("audio");  
    this.soundPlayer3.src = "sounds.mp3";
    this.soundPlayer4 = document.createElement("audio");  
    this.soundPlayer4.src = "sounds.mp3";
    this.soundPlayer5 = document.createElement("audio");  
    this.soundPlayer5.src = "sounds.mp3";    

    this.soundCountdownPlayed = false;

    this.sound5MLeftPlayed = true;
    this.sound1MLeftPlayed = true;
    this.sound321GameOverPlayed = false;

    //multi color after start
    this.MixRunColor = Math.floor(Math.random() * 2)===0? true : false;

    //zvuky ze hry
    //pouzity 0,8,12,13,14
    this.soundPositions = 
    [
      ['0 menu click', 0.0, 0.2], ['1 exploze', 4.0, 5.5], ['2 create portal', 8.0, 9.0]
      , ['3 portal jump', 12.0, 14.5], ['4 point', 16.0, 17.5], ['5 3points', 20.0, 21.5]
      , ['6 mines', 24.0, 24.95], ['7 hammer', 28.0, 28.6], ['8 cybertracers', 32.0, 33.4]
      , ['9 game over', 36.0, 38.0 ], ['10 new powertracer', 40.0, 41.8], ['11 prepare your tracer', 44.0, 46.3]
      , ['12 3 2 1 lets go ', 48.0, 52.0], ['13 player ready', 52.0, 53.5], ['14 pause', 56.0, 58.0]
      , ['15 new player', 60.0, 61.3], ['16 five minutes left', 64.0, 66.5], ['17 1 minute left', 68.0, 70.0] , ['18 321 game over', 72.0, 76.5]
      , ['19 overdrive', 80.0, 81.1]
    ];



    //self pro eventy
    var self = this;


    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.register({
      onSuccess: () => { 
        console.log("APP.js service worker inicialozován");

        this.setServiceWorkerInitialized(true);
      },
      onUpdate: registration => { 
        console.log("APP.js service worker byl aktualizován");

        this.setServiceWorkerUpdated(true, registration);
      },
    });

    window.addEventListener('keydown', function (event) {

      self.keydown[keyName(event)] = true;

      if (self.keydown.up || self.keydown.down || self.keydown.left || self.keydown.right || self.keydown.space) {
          
        event.preventDefault();
      }
    }, false);


    window.addEventListener('keyup', function (event) {

      self.keydown[keyName(event)] = false;

    }, false);


    //podpora gamepadů
    window.addEventListener("gamepadconnected", (event) => {
      console.log("A gamepad connected:");
      console.log(event.gamepad);
    });

    window.addEventListener("gamepaddisconnected", (event) => {
      console.log("A gamepad disconnected:");
      console.log(event.gamepad);
    });

    this.soundPlayer5.onloadeddata = function() {
      self.playSound(8,1);
    };


    //ošetření 
    this.state = {
      canvasSize :{width: window.innerWidth, height: window.innerHeight}
      ,bottomHudsHeight: 100
      ,gamePhase: gamePhaseEnum.LOBBY
      ,serviceWorkerInitialized: false
      ,serviceWorkerUpdated: false
      ,serviceWorkerRegistration: null
      ,startCountdownRunning: this.startCountdownRunning
      ,startCountdown: (this.startCountdown / 1000).toFixed(0)
      ,gameCountdownMin: (Math.floor((this.gameCountdown / 1000) /60)).toFixed(0)
      ,gameCountdownSec: ((this.gameCountdown / 1000) %60).toFixed(0)
      ,countDownVisible: this.countDownTempVisible > 0
      ,playersInfo:[]
      ,Results:[]
      ,AIplayers: this.AIplayers
    };
    //this.handleEvent = this.handleEvent.bind(this);  
  }
  

  /**
   * týmový update score
   * @param {*} team - updatovaný tým
   * @param {*} points - počet získaných bodů
   */
  updateTeamScore(team,points){

    let teamPlayers = this.players.filter(pl=>pl.controlledBy !== controls.AI && pl.team === team)
    if(teamPlayers!== undefined && teamPlayers !== null){
      
      //updatuju score všem v týmu
      teamPlayers.forEach(pl => {
        pl.score+=points.sumScore;
      });
    }
  }


  //https://reactjs.org/tutorial/tutorial.html
  //změny na hráči, které afektují UI
  updatePlayersInfo(){
    
    //zjistím, kdo je první
    let nonDemoPlayers = this.players.filter(fi=>fi.isDemo === false);

    if(nonDemoPlayers.length > 0)
    {
      let plaFirst = nonDemoPlayers[0];

      //1.reset prvenství a určení prvého
      for (let i = 0 ; i < nonDemoPlayers.length ; i++) {
        nonDemoPlayers[i].isFirst = false;

        if(nonDemoPlayers[i].score > plaFirst.score)
        {
          plaFirst = nonDemoPlayers[i];
        }
      }

      //2.všichni se stejným score jako nejvyšší
      let firstGroup = nonDemoPlayers.filter(pl=>pl.score === plaFirst.score);
      if(firstGroup!== undefined && firstGroup !== null){
        if(firstGroup.length === 1){
          //existuje jeden powertracer
          plaFirst.isFirst = true;
        }
        else if(firstGroup.length > 1){
          //pokud je jich více, jsou první pouze pokud jsou stejný tým
          let teamMates = firstGroup.filter(pl=>pl.team >0 && pl.team === plaFirst.team);
          if(teamMates!== undefined && teamMates !== null){
            if(firstGroup.length === teamMates.length)
            {
              //všichni v týmu jsou powertraceři
              teamMates.forEach(pl => {
                pl.isFirst = true;
              });
            }  
          }
        }
      }

      //mazání hráčů určených pro výmaz
      let someRemoved = false;
      do{
        someRemoved = false;
        for (let i = 0 ; i < this.players.length ; i++) {
          if(this.players[i].markedForRemove)
          {
            this.players.splice(i,1);
            someRemoved = true;
            break;
          }
        }
      }while(someRemoved);
    }

    //zjistím, kdo je první
    nonDemoPlayers = this.players.filter(fi=>fi.isDemo === false);    

    this.setState({
      playersInfo: nonDemoPlayers.map((pla, index)=>{ return {
        index: index
        , guid: pla.guid
        , color: pla.color
        , type: pla.type
        , team: pla.team
        , inMenuCurrent: pla.inMenuCurrent
        , isMapped: pla.isMapped
        , isSetCustomMapping: pla.isSetCustomMapping
        , mappingCurrent: pla.mappingCurrent
        , mappingStepTimeout: pla.mappingStepTimeout
        , controlledBy: pla.controlledBy
        , controllerId: pla.controllerId
        , score: pla.score
        , isFirst: pla.isFirst
        , deaths: pla.deaths
        , blockadeCooldown: pla.blockadeCooldown
        , gateCooldown: pla.gateCooldown
        , ramCooldown: pla.ramCooldown
        , portalCooldown: pla.portalCooldown
        , controlTextMove: pla.controlTextMove
        , controlTextMoveAlt: pla.controlTextMoveAlt
        , controlTextPortal: pla.controlTextPortal
        , controlTextRam: pla.controlTextRam
        , controlTextMines: pla.controlTextMines
        , controlTextPauseMenu: pla.controlTextPauseMenu
        , controlTextDial: pla.controlTextDial
        , specialFontClass: pla.specialFontClass
        , portalDial: pla.portalDial
        , energy: pla.energy
        , overDriveCooldown: pla.overDriveCooldown
        };
      }),
      AIplayers: this.AIplayers
    }, ()=> {

      //update výšky zakázané oblasti
      let hudsRefHeight = this.hudsRef.current.clientHeight;
      if(this.state.bottomHudsHeight !== hudsRefHeight)
      {
        //nechám výšku zapsat znova (pozor na nekonečnou rekurzi)
        this.updateCanvasSize();
      }

    });
  }


  //změny na hráči, které afektují Results UI
  updateResults(){
    //zjistím, kdo je první

    if(this.players.length > 0)
    {
      for (var i = 0 ; i < this.players.length ; i++) {
        //nastavím unikátní index hráče dle indexu v poli
        this.players[i].SetUniqueIndex(i);
      }
    }

    //zjistím, kdo je první
    let nonDemoPlayers = this.players.filter(fi=>fi.isDemo === false);

    //kopie setřízená
    let shortedPlayers = [...nonDemoPlayers].sort((a,b) => b.score - a.score);

    this.setState({
      Results: shortedPlayers.map((pla, index)=>{ return {
        index: pla.uniqueIndex
        , color: pla.color
        , type: pla.type
        , team: pla.team
        , inMenuCurrent: pla.inMenuCurrent
        , controlledBy: pla.controlledBy
        , score: pla.score
        , deaths: pla.deaths
        };
      })
    }, ()=> {
      //zatím nic
    });
  }


  /**
   * update odpočtu
   */
  updateCountdown(){
    this.setState({
      startCountdownRunning: this.startCountdownRunning
      ,startCountdown: (this.startCountdown / 1000).toFixed(0)
      ,gameCountdownMin: (Math.floor((this.gameCountdown / 1000) /60)).toFixed(0)
      ,gameCountdownSec: ((this.gameCountdown / 1000) %60).toFixed(0)
      ,countDownVisible: this.countDownTempVisible > 0
    }, ()=> {
    });
  }

  /**
   * update stavu service workeru
   */
  setServiceWorkerUpdated(updated, registration){
    this.setState({
      serviceWorkerUpdated: updated
      ,serviceWorkerRegistration: registration
    }, ()=> {
    });
  }

  /**
   * update stavu service workeru
   */
  setServiceWorkerInitialized(initialized){
    this.setState({
      serviceWorkerInitialized: initialized
    }, ()=> {
    });
  }

  /**
   * update stavu hry, a nastavení state pro zobrazení jednotlivých částí
   * možné pohyby : 
   * 
   * LOBBY->GAME (všichni hráči jsou rdy)
   * LOBBY->RESULTS (uplynulo 10 s bez aktivity)
   * RESULTS->LOBBY (uplynulo 10 s bez aktivity, nebo kterýkoliv uživatel udělal nějakou akci)
   * GAME->RESULTS (splněny podmínky konce hry)
   * GAME->PAUSE (pauza)
   * PAUSE->GAME (pokračování hry po pauze)
   * 
   * @param {*} phase dle gamePhaseEnum, jde o požadovanou fázi hry
   */
  updateGamePhase(phase){
    

    let previousPhase = this.state.gamePhase;
    console.log('update fáze hry: ' + previousPhase + '->' + phase);

    this.setState({
      gamePhase: phase
    }, ()=> {

      //LOBBY -> GAME
      if(previousPhase === gamePhaseEnum.LOBBY && phase === gamePhaseEnum.GAME)
      {
        //reset odpočtu hry
        this.gameCountdown = this.gameCountdownDefault;
        //standardný resety (pohyb menu, čas do startu, chvilkové zastavení menu na snímku výsledků)
        this.menuAutoMoveCountdown = this.menuAutoMoveCountdownDefault;
        this.startCountdown = this.startCountdownDefault;
        this.menuFreezeCountdown = this.menuFreezeCountdownDefault;

        //start hry
        console.log('start hry');

        //start hry GA
        ReactGA.event({
          category: 'game',
          action: 'start',
          label: 'info'
        });


        //všecko smažu
        this.clearAllCanvas();

        //updatuju velikost okna (součást je vyrobení nových portálů
        this.updateCanvasSize();

        //odeberu všechny demo hráče
        this.removeAllDemoAI();

        //update stavu hráčů na hru n READY
        for (let p = 0; p < this.players.length; p++) {
          this.players[p].playerRespawn();
        }
        this.updatePlayersInfo();

        //vyrobím portály updateCanvasSize to již v sobě provolává
        //this.reCreateBasicPortals();

      }

      //GAME -> RESULTS
      if(previousPhase === gamePhaseEnum.GAME && phase === gamePhaseEnum.RESULTS)
      {
        //aktualizuju výsledkovou listinu
        this.updateResults();

        //update stavu hráčů na hru na INMENU
        for (let p = 0; p < this.players.length; p++) {
          this.players[p].playerToMenuPhase();
          //bum
          this.players[p].playerExplode();
        }
        this.updatePlayersInfo();

        //konec hry GA
        ReactGA.event({
          category: 'game',
          action: 'end',
          label: 'info'
        });

      }

      //GAME -> PAUSE
      if(previousPhase === gamePhaseEnum.GAME && phase === gamePhaseEnum.PAUSE)
      {
        //start hry
        console.log('pauza');

        this.playSound(14,1);

        //update stavu hráčů na hru na INMENU
        for (let p = 0; p < this.players.length; p++) {
          this.players[p].playerToMenuPhase();
        }
        this.updatePlayersInfo();

      }

      //PAUSE -> GAME
      if(previousPhase === gamePhaseEnum.PAUSE && phase === gamePhaseEnum.GAME)
      {
        //standardný resety (pohyb menu, čas do startu, chvilkové zastavení menu na snímku výsledků)
        this.menuAutoMoveCountdown = this.menuAutoMoveCountdownDefault;
        this.startCountdown = this.startCountdownDefault;
        this.menuFreezeCountdown = this.menuFreezeCountdownDefault;

        //start hry
        console.log('pokračování po pauze');

        //update stavu hráčů na hru na INMENU
        for (let p = 0; p < this.players.length; p++) {
          this.players[p].playerToPreviousPhase();
        }
        this.updatePlayersInfo();

        //deaktivuju pauzu
        this.pause = false;

      }


      //GAME -> TUTORIAL
      //TUTORIAL -> PAUSE
      if(previousPhase === gamePhaseEnum.TUTORIAL && phase === gamePhaseEnum.PAUSE)
      {
        //start hry
        console.log('opouštím tutorial');

        //update stavu hráčů na hru na INMENU
        for (let p = 0; p < this.players.length; p++) {
          this.players[p].toMenuItemControls();
        }
        this.updatePlayersInfo();
      }

    });
  }


  /**
   * vyrábí sadu základních permanentních portálů
   */
  reCreateBasicPortals()
  {
    if(this.state.gamePhase !== gamePhaseEnum.LOBBY)
    {
      //1. smažu staré portály
      let notPermanentPortals = this.portals.filter(portal=> 
        portal.permanent === false);
      this.portals = notPermanentPortals;

      //spočítám rozsahy
      let height = this.state.canvasSize.height - this.state.bottomHudsHeight;
      let heightDiff = height/4;

      let width = this.state.canvasSize.width;
      let widthDiff = width/4;

      //2. vyrobín nové permanentní portály
      this.addPortal(this.stredx, this.stredy - heightDiff, this.stredx, this.stredy -heightDiff + 40, orientation.VERTICAL, true, 30000, 0);
      this.addPortal(this.stredx, this.stredy + heightDiff -40, this.stredx, this.stredy + heightDiff, orientation.VERTICAL, true, 30000, 0);

      this.addPortal(this.stredx - widthDiff, this.stredy, this.stredx - widthDiff+40, this.stredy, orientation.HORIZONTAL, true, 30000, 0);
      this.addPortal(this.stredx + widthDiff -40, this.stredy, this.stredx +widthDiff, this.stredy, orientation.HORIZONTAL, true, 30000, 0);
      }
  }


  addPlayer (controlledBy, controllerId) {
    
    if(this.players.length < 50){

      //barva
      let colorId = this.players.length;
      while(colorId > greatColors.length-1)
      {
        colorId -= greatColors.length-1;
      }

      //vyhledám non AI hráče
      let realPlayers = this.players.filter(pl=>pl.controlledBy !== controls.AI)
      let count = realPlayers.length;

      //vyhledám demo AI hráče
      let demoPlayers = this.players.filter(pl=>pl.controlledBy === controls.AI && pl.isDemo === true)
      let demoPlayersCount = demoPlayers.length;

      let isDemo = false;
      //AIčka před hráčema jsou DEMO hráči
      if(count === 0 && controlledBy === controls.AI){
        isDemo = true;
        colorId = 0;
        if(demoPlayersCount>4 && this.MixRunColor){
          colorId = 1;
        }
      }

      //přidání hráče před AIčka
      this.players.splice(count, 0, new Player(controlledBy, controllerId, colorId, this, isDemo));
    
      if(controlledBy!== controls.AI){
        this.playSound(15,1);
      }

      //update state
      this.updatePlayersInfo();
    }
  }

  //přidání AI hráčů
  addAI(count){
    for(let a=0;a<count;a++){
      this.addPlayer(controls.AI, null);
    }
  }

  //odebrání AI hráče
  removeAI(){ 
    let forErase = this.players.filter(pl=>pl.controlledBy === controls.AI && pl.isDemo === false)
    if(forErase.length > 0){
      forErase[0].markedForRemove = true;
    }
  }

  //odebrání všech demo AI hráčů
  removeAllDemoAI()
  {
    let forErase = this.players.filter(pl=>pl.controlledBy === controls.AI && pl.isDemo === true)
    for(let i=0;i<forErase.length;i++){
      forErase[i].markedForRemove = true;
    }
  }


  //upravování barev AI hráčů (+ - všem stejná barva v řadě, - všem náhodná barva)
  updateAIColor(diff)
  {
    let ais = this.players.filter(pl=>pl.controlledBy === controls.AI)
    if(ais!== undefined && ais !== null){
      
      if(diff>0){
        this.sameAIColor++;
        if(this.sameAIColor >= greatColors.length)
        {
          this.sameAIColor = 0;
        }
      }

      ////barva
      ais.forEach(ai => {

        if(diff>0){
          
          //stejná barva
          ai.color = this.sameAIColor;
        }
        else
        {
          //náhodná barva
          ai.color = Math.floor(Math.random() * greatColors.length);
        }
      });
    }
  }

  /**
   * pustí další song
   */
  musicPlayerNext(){
    //pustím další track
    this.playerRef.next();
  }

  /**
   * pauzne nebo spustí přehrávač hudby
   */
  musicPlayerStop(){
    //spustím nebo pauznu playback hudby
    this.playerRef.playStop();
  }

  /**
   * pauzne nebo spustí přehrávač hudby
   */
  musicPlayerVolumeUp(){
    //spustím nebo pauznu playback hudby
    this.playerRef.volumeUp();
  }

  /**
   * pauzne nebo spustí přehrávač hudby
   */
  musicPlayerVolumeDown(){
    //spustím nebo pauznu playback hudby
    this.playerRef.volumeDown();
  }



  /*
    změna času hry
  */
  updateGameTime(diff)
  {
    this.gameCountdownDefault +=diff;

    if(this.gameCountdownDefault <150000){
      this.gameCountdownDefault = 150000;
      
    }

    //maximum 60 minut
    if(this.gameCountdownDefault >=3600000){
      this.gameCountdownDefault = 3600000;
    }

    //přepíšu hodnotu
    this.gameCountdown = this.gameCountdownDefault;

    //na 10 s viditelné
    this.countDownTempVisible = this.countDownTempVisibleDefault;
    this.updateCountdown();
  }

  /**
   * změna jazyku aplikace
   * @param {} diff 
   */
  updateGameLang(diff)
  {
    if(diff>0)
    {
      i18n
      .changeLanguage('en')
      .then((t) => {
        t('key');
      });
    }
    else
    {
      i18n
      .changeLanguage('cs')
      .then((t) => {
        t('key');
      });      
    }
  }

  addPortal (bx,by,ex,ey, ori, permanent, duration, dialCode){

    //inkrementuju
    this.portalCounter +=1;

    //portál musím vkládat aby nalevo byla ta menší strana a napravo ta větší
    if(ori === orientation.VERTICAL)
    {
      //vertikální
      this.portals.push(new Portal(this, bx, by < ey? by:ey , bx, by < ey? ey:by , ori, permanent, duration, this.portalCounter, dialCode ));
    }
    else
    {
      //horizontální
      this.portals.push(new Portal(this, bx<ex? bx:ex, by, bx<ex? ex:bx, by, ori, permanent, duration, this.portalCounter, dialCode ));
    }
  }

  //přidává vizuální propojku mezi portály
  addConnection (bx,by,ex,ey, color, type){

      this.connections.push(new Connection(this, bx, by, ex, ey , color, type ));
  }

  //přidává vizuální propojku mezi portály
  addSpecialZone (permanent, type, duration){

    //inkrementuju
    this.zoneCounter +=1;

    this.zones.push(new SpecialZone(this, permanent, type, duration, this.zoneCounter ));    
  }

  addBonusPoint (x,y, permanent , duration, score, energy, team){

    this.bonusPointsCounter +=1;
    this.bonusPoints.push(new BonusPoint(this, x,y, permanent , duration, score, energy, this.bonusPointsCounter, team))
  }

  //přidání krásné exploze
  addExplosion (x,y, greatColor ,type, smer, speed, team){

    this.explosions.push(new Explosion(this, x,y, greatColor, type, smer, speed, team));
    this.playSound(1,1);
  }

  //přidání vražedného rotátoru
  addRotator (x,y, r, permanent, duration){

    this.rotatorCounter +=1;
    this.rotators.push(new Rotator(this, x, y, r, permanent, duration, this.rotatorCounter ))
  }


  /**
   * hledá kolidující speciální zónu pro účel ochrany před dvěmi protínajícími se zónami
   * @param {*} x 
   * @param {*} y 
   * @param {*} width 
   * @param {*} height 
   */
  findZoneZoneColision (x,y, width, height, id)
  {
    let possibleZones = this.zones.filter(zone=> 
      zone.x + zone.width / 2 > x - width / 2 &&
      zone.x - zone.width < x + width / 2 &&
      zone.y + zone.height / 2 > y - height / 2 &&
      zone.y - zone.height / 2 < y + height / 2 &&
      zone.id !== id
      ) 

    if(possibleZones!=null && possibleZones.length>0){
      return true;
    }
    else{
      return false;
    }
  }

  /**
   * hledá kolize speciální zóny s hráčema (aby zóna nevznikla přímo na hráčích)
   * @param {*} x 
   * @param {*} y 
   * @param {*} width 
   * @param {*} height 
   */
  findPlayerColision(x,y, width, height)
  {
    let possiblePlayers = this.players.filter(player=> 
      player.x > x - 50 - width / 2 &&
      player.x < x + 50 + width / 2 &&
      player.y > y - 50 - height / 2 &&
      player.y < y + 50 + height / 2 ) 

      if(possiblePlayers!=null && possiblePlayers.length>0){
        return true;
      }
      else{
        return false;
      }
  }

  /**
   * hledá kolize speciální zóny s portálem (aby zóna nevznikla přímo na portálu)
   * @param {*} x 
   * @param {*} y 
   * @param {*} width 
   * @param {*} height 
   */
  findPortalColision(x,y, width, height)
  {
    let possiblePortals = this.portals.filter(portal=> 
      ((portal.beginx > x - 10 - width / 2) || (portal.endx > x - 10 - width / 2)) &&
      ((portal.beginx < x + 10 + width / 2) || (portal.beginx < x + 10 + width / 2 )) &&
      ((portal.beginy > y - 10 - height / 2) || (portal.endy > y - 10 - height / 2))  &&
      ((portal.beginy < y + 10 + height / 2) || (portal.endy < y + 10 + height / 2))) 

      if(possiblePortals!=null && possiblePortals.length>0){
        return true;
      }
      else{
        return false;
      }
  }



  /**
   * hledá kolize hráče se speciální zónou (aby hráč nevznikl přímo v speciální zóně)
   * @param {*} x 
   * @param {*} y 
   */
  findSpecialZoneColision(x,y)
  {
    let possibleZones = this.zones.filter(zone=> 
      zone.x + zone.width / 2 > x &&
      zone.x - zone.width < x &&
      zone.y + zone.height / 2 > y &&
      zone.y - zone.height / 2 < y
      ) 

      if(possibleZones!=null && possibleZones.length>0){
        return true;
      }
      else{
        return false;
      }
  }





  /**
   * najde kolizi s bonusovými body a vrátí jejich počet
   * @param {*} x - poloha hráče x
   * @param {*} y - poloha hráče y
   * @param {*} team - team hráče zběrače, nelze zebrat body vaniklé smrtí teamového hráče
   */
  colectPoints (x,y, team)
  {
    let colision = this.bonusPoints.filter(points=> 
        //nelze zebrat týmový bod (vzájemné obohacovaní se v týmu)
        (points.team === 0 || points.team !==team)
        //zjednodušený výpočet polohy
        && Math.abs(points.x - x) + Math.abs(points.y - y) < 10
        && (points.score > 0
        || points.energy > 0)
      ) 

    if(colision!=null && colision.length>0){

      let sumScore = 0;
      let sumEnergy = 0;

      colision.forEach(po=>{

        if(po.score === 3)
        {
          this.playSound(4,1);
        }
        else
        {
          this.playSound(5,1);
        }

        //sečtu
        sumScore += po.score;
        sumEnergy += po.energy;
        //vynuluju body
        po.score = 0;
        po.energy = 0;
        po.permanent = false;
        po.duration = 500;
      })

      return {sumScore: sumScore, sumEnergy: sumEnergy};      
    }

    return null;
  }


  /**
   * //nalezení portálu, který projíždím podle polohy xy v obdelníku portálu
   * @param {*} x old x position
   * @param {*} nx new x position
   * @param {*} y old y position
   * @param {*} ny new y position
   */
  findPortal (x, nx,y, ny){


    //přesnější kontrola kolizí s portálem
    let foundedPortals = this.portals.filter(portal=> 
      portal.delayedInit <=0
      && (
        (x===nx && portal.ori === orientation.HORIZONTAL && portal.beginx <= x && x <= portal.endx && ((y <= portal.beginy && portal.beginy <= ny) || (y>= portal.beginy && portal.beginy >= ny )))
        ||
        (y===ny && portal.ori === orientation.VERTICAL && portal.beginy <= y && y <= portal.endy && ((x <= portal.beginx && portal.beginx <= nx) || (x>= portal.beginx && portal.beginx >= nx )))
      )      
      );

    for(let id =0;id< foundedPortals.length; id++)
    {
        //vracím nalezený portál
        return foundedPortals[id];
    }

    return null;
  }

  /**
   * určuje portál, do kterého se přesuneme
   * @param {*} ori - vybírám jen stejně orientované portály
   * @param {*} portalId - které nejsou stejné, jako ten, do kterého jsem vjel
   * @param {*} portalDial - klíč portálu, který může nově redukovat náhodu při skoku
   */
  getRandomPortal (ori, portalId, portalDial)
  {
    //portály se stejnou orientací
    let possiblePortals = this.portals.filter(portal=> 
      portal.delayedInit <=0
      && portal.ori === ori 
      && portal.id !== portalId);


    let filteredPossiblePortals = null;
    //pokud jich je více, vyberu dle dialu
    if(portalDial>0 && possiblePortals!=null && possiblePortals.length > 1)
    {
      filteredPossiblePortals = possiblePortals.filter(portal=> 
        portal.targetCode === portalDial);
    }

    //pokud existuje nějaký portál se stejným počtem čárek
    if(filteredPossiblePortals!=null && filteredPossiblePortals.length >0)
    {
      possiblePortals = filteredPossiblePortals;
    }

    if(possiblePortals!=null && possiblePortals.length > 0)
    {
      //náhodná volba
      let portId = Math.floor(Math.random() * possiblePortals.length);
      //vracím vybraný portál
      return possiblePortals[portId];
    }

    //není co vrátit
    return null;
  }



  recognizePlayersAndControlls (gamepads) {

    for (var i = 0; i < gamepads.length; i++) {

        //získám gamepad
        let gp = gamepads[i];
        
        //gp.devIsUsed je můj vlastní parametr, optimalizace odstraňující zbytečné testování již používaného ovladače
        if (gp !== null && gp.connected === true && gp.devIsUsed !== true) { 

          let addPlayer = false;

          //pro STANDARD gamepady, viz odstavec 9, https://www.w3.org/TR/gamepad/ 
          if(gp.mapping !=null && gp.mapping === 'standard')
          {
            let gamepadY = 0;
            if (gp.axes[1] !== 0)
            {
                gamepadY = gp.axes[1];
            }
            
            //změna, tlačítko 12 (dpad nahoru), nebo levá analogová páčka
            if ((gp.buttons[12] != null && gp.buttons[12].pressed) || (gp.buttons[13] != null && gp.buttons[13].pressed) || gamepadY < -0.3 || gamepadY > 0.3) {
                
              addPlayer = true;
            }

          }
          else //pro nestandardní gamepady
          {
            //buttony
            for (let c = 0; c < gp.buttons.length; c++) {
              if(gp.buttons[c] != null && gp.buttons[c].pressed)
              {
                addPlayer = true;
              }
            }

            //analogové páčky
            let axesLength = gp.axes.length;
            if(axesLength > 4)
            {
              axesLength = 4;
            }
            for (let a = 0; a < axesLength; a++) {
              if(gp.axes[a] != null && (gp.axes[a] > 0.3 || gp.axes[a] < -0.3))
              {
                addPlayer = true;
              }
            }

          }

          //přidání nového hráče
          if(addPlayer)
          {
            this.addPlayer(controls.GAMEPAD, i);
          }
        }
    }





    //klavesak sipky 0
    if (this.keydown.up || this.keydown.down) {
        let used = false;
        for (let p = 0; p < this.players.length; p++) {
            if (this.players[p].controlledBy === controls.KEYBOARD && this.players[p].controllerId === 0) {
                used = true;
            }
        }

        if (used === false) {
            //přidání nového hráče
            this.addPlayer(controls.KEYBOARD, 0);
        }
    }

    //klavesak wsad 1
    if (this.keydown.w || this.keydown.s) {
        let used = false;
        for (let p = 0; p < this.players.length; p++) {
            if (this.players[p].controlledBy === controls.KEYBOARD && this.players[p].controllerId === 1) {
                used = true;
            }
        }

        if (used === false) {
            //přidání nového hráče
            this.addPlayer(controls.KEYBOARD, 1);
        }
    }

    //klavesak 8456 2
    if (this.keydown[8] || this.keydown[5]) {
        let used = false;
        for (let p = 0; p < this.players.length; p++) {
            if (this.players[p].controlledBy === controls.KEYBOARD && this.players[p].controllerId === 2) {
                used = true;
            }
        }

        if (used === false) {
            //přidání nového hráče
            this.addPlayer(controls.KEYBOARD, 2);
        }
    }

    //klavesak ijkl 3
    if (this.keydown.i || this.keydown.k) {
        let used = false;
        for (let p = 0; p < this.players.length; p++) {
            if (this.players[p].controlledBy === controls.KEYBOARD && this.players[p].controllerId === 3) {
                used = true;
            }
        }

        if (used === false) {
            //přidání nového hráče
            this.addPlayer(controls.KEYBOARD, 3);
        }
    }


    //obecné klávesy, pauza hry
    if(!this.prevEsc && this.keydown.esc){   
      
      this.pauseGame();

      if(this.lastEsc < this.tutorialBefore)
      {
        this.tutorial();
      }

      //reset času
      this.lastEsc = 0;
    }


    this.prevEsc = this.keydown.esc;
  };


  /**
   * všechny časovače, které musí být zastavitelné dle pauzy
   */
  timeoutPausableProcess(){

    if(this.state.gamePhase === gamePhaseEnum.GAME){
      
      //odpočet hry
      if (this.gameCountdown > 0){
        this.gameCountdown -= this.delay;
        if(this.gameCountdown <0){
            this.gameCountdown = 0;
        }

        if(this.gameCountdown <3300){

          if(this.sound321GameOverPlayed === false){
            this.sound321GameOverPlayed = true;          
            this.playSound(18,1);
          }
        }
        else{
          this.sound321GameOverPlayed = false; 
        }

        if(this.gameCountdown <300000){

          if(this.sound5MLeftPlayed === false){
            this.sound5MLeftPlayed = true;          
            this.playSound(16,1);
          }
        }
        else{
          this.sound5MLeftPlayed = false; 
        }

        if(this.gameCountdown <60000){

          if(this.sound1MLeftPlayed === false){
            this.sound1MLeftPlayed = true;          
            this.playSound(17,1);
          }
        }
        else{
          this.sound1MLeftPlayed = false; 
        }

        //výměna kódů na portálech
        if(this.targetCodeChangingCountdown >0){
          this.targetCodeChangingCountdown -= this.delay;
        }
        else
        {
          this.targetCodeChangingCountdown = this.targetCodeChangingCountdownDefault;
        }

      }
      else
      {
        //konec hry
        this.updateGamePhase(gamePhaseEnum.RESULTS);
        console.log('konec hry');
      }
      //update state
      this.updateCountdown();


      //odpočet vyrobení speciální zóny
      if (this.specialZoneTimeout > 0){
          this.specialZoneTimeout -= this.delay;
      }
      else
      {
        //interval něco mezi 2 - 3 minutama
        this.specialZoneTimeout = 20000 + Math.floor(Math.random() * 20000);

        let zone = Math.floor(Math.random() * 10); //(0-9) 7/10 = 70% sance spawnu

        switch(zone) {
          case 0:
            if(Math.floor(Math.random() * 2) === 0){
              this.addSpecialZone(false, zoneType.SNAKEU, 120000);    
            }
            else{
              this.addSpecialZone(false, zoneType.SNAKED, 120000);    
            }
            break;
          case 1:
          this.addSpecialZone(false, zoneType.SPEEDTUNNEL, 120000);    
            break;
          case 2:
            if(Math.floor(Math.random() * 2) === 0){
              this.addSpecialZone(false, zoneType.ROTODEATHR, 120000);    
            }
            else{
              this.addSpecialZone(false, zoneType.ROTODEATHR, 120000);    
            }
            break;  
          case 3:
            this.addSpecialZone(false, zoneType.HEARTH, 120000);    
            break;
          case 4:
              this.addSpecialZone(false, zoneType.MAZE, 120000);    
              break;      
          case 5:
            this.addSpecialZone(false, zoneType.MONKEY, 120000);
            break;
          case 6:
              this.addSpecialZone(false, zoneType.OWL, 120000);
              break;  
          case 7:
              this.addSpecialZone(false, zoneType.RABBIT, 120000);
              break;                              
          default:
            break;        
        }
      }

      //odpočet vyrobení speciální zóny killer (výplň)
      if (this.specialZoneKillerTimeout > 0){
        this.specialZoneKillerTimeout -= this.delay;
      }
      else
      {
        //interval něco mezi 10 - 20 sekundama
        this.specialZoneKillerTimeout = 10000 + Math.floor(Math.random() * 5000);

        // let zone = Math.floor(Math.random() * 2); //(0-9) 7/10 = 70% sance spawnu
        this.addSpecialZone(false, zoneType.KILLER, 30000);
      }


      if(this.eraserZoneCount < 3){
        
        if(this.eraserZoneDelay > 2500)
        {
          //speciální zóna
          this.addSpecialZone(true, zoneType.ERASER, 5000);
          this.eraserZoneCount++;
          this.eraserZoneDelay = 0;
        }
        else{
          this.eraserZoneDelay +=this.delay;
        }

      }
    }


  }





  /**
   * zprocesování většiny časovačů a odpočtů hry
   */
  timeoutsProcess () {

    //odpočet začátku hry
    //gamePhaseEnum
    if(this.state.gamePhase === gamePhaseEnum.LOBBY || this.state.gamePhase === gamePhaseEnum.PAUSE)
    {
      let readyIndex = menuControlItems.indexOf('ready');


      let notReadyPlayers = 0;
      let players = this.players.filter(pl => pl.controlledBy !== controls.AI);
      if(players.length > 0){
        notReadyPlayers = players.filter(pl=>pl.inMenuCurrent !== readyIndex);
      }

      if(players.length > 0)
      {
        //čekám na to až budou všichni ready
        if(notReadyPlayers.length === 0) 
        {
          if (this.startCountdown > 0){
            
            this.startCountdown -= this.delay;

            if(this.startCountdown <0)
            this.startCountdown = 0;

            if(this.startCountdown <3100 && !this.soundCountdownPlayed)
            {
              this.soundCountdownPlayed = true;
              this.playSound(12,1);
            }

            this.startCountdownRunning = true;
          }
          else
          {
            this.updateGamePhase(gamePhaseEnum.GAME);
          }    
        }
        else
        {
          //reset odpočtu
          this.startCountdown = this.startCountdownDefault;
          this.startCountdownRunning = false;
          this.soundCountdownPlayed = false;
        }

        //update state
        this.updateCountdown();
      }
    }


    if(this.state.gamePhase !== gamePhaseEnum.GAME)
    {

      //dočasné zastavení autoposuvu v menu
      if(this.menuFreezeCountdown >0)
      {
        this.menuFreezeCountdown -= this.delay;
      }

      //automatické přesuny v menu
      if (this.menuAutoMoveCountdown > 0){

        this.menuAutoMoveCountdown -= this.delay;
      }


      if(this.state.gamePhase === gamePhaseEnum.LOBBY || this.state.gamePhase === gamePhaseEnum.RESULTS){

        //automatické přesuny v menu
        if(this.menuAutoMoveCountdown <=0 && this.menuFreezeCountdown <=0)
        {
          this.menuAutoMoveCountdown = this.menuAutoMoveCountdownDefault;
          
          let newPhase = null
          if(this.state.gamePhase === gamePhaseEnum.LOBBY){
            newPhase = gamePhaseEnum.RESULTS;
          }
          else{
            newPhase = gamePhaseEnum.LOBBY;
          }

          //změním fázi
          this.updateGamePhase(newPhase);          
        }
      }
    }

    //odpočet pro pokyny v menu
    if(this.menuActionTimeout >0){
      this.menuActionTimeout -= this.delay;
    }

    //odp oslední pauzy
    this.lastEsc += this.delay;

    //odpočet viditelnosti změněné hodnoty v menu
    if (this.countDownTempVisible > 0){
      this.countDownTempVisible -= this.delay;

      if(this.countDownTempVisible <0)
          this.countDownTempVisible = 0;

      //update state
      this.updateCountdown(); 
    }
  };


  update () {

    let date = new Date();
    let actTime = date.getTime();

    //vyčtu aktuální stav gamepadů
    this.gamepads = navigator.getGamepads();

    //spočtu prodlevu
    this.delay = actTime - this.time;
    if (this.delay === actTime) {
        //pokud se měří první běh, není ještě nastavena hodnota time, proto delay ještě neplatí.
        this.delay = 0;
    }

    //odložím aktuální čas
    this.time = actTime;

    // krok pohybu postav cim vetsi cislo tim pujdou pomaleji
    this.movestep = this.delay / this.gameSpeed;
    //limiter maximalniho pohybu postav pri velmi nizkem fps (sekani hry)
    if (this.movestep > 1) this.movestep = 1;


    if(!this.pause){
      //zpracování odpočtů
      this.timeoutPausableProcess();
    }

    this.timeoutsProcess();


    let anyAction = false;
    //update hráčů
    for (let p = 0; p < this.players.length; p++) {

        let plAction = this.players[p].update(this.pause, this.gamepads);
        //todo: přidělat okamžité vyskočení z menu
        if (plAction)
        {
          anyAction = true;
        }
    }


    //přidávání nových hráčů přesunuto sem, z důvodu většího výkonu 
    //(po zpracování hráčů přesně vím, který gamepad je potřeba kontrolovat na start, a který je použit ve hře)
    this.recognizePlayersAndControlls(this.gamepads);




    if(anyAction)
    {
      //okamžitý návrat do lobby
      this.menuAutoMoveCountdown = this.menuAutoMoveCountdownDefault;
      if(this.state.gamePhase === gamePhaseEnum.RESULTS && this.menuFreezeCountdown <=0)
      {
        //přesun na obrazovku lobby
        this.updateGamePhase(gamePhaseEnum.LOBBY);
      }
    }


    if(!this.pause){

      //mazání starých portálů
      for (let p = 0; p < this.portals.length; p++) {
        if(this.portals[p].permanent === false && this.portals[p].portalDuration <=0)
        {
          this.portals.splice(p,1);
          //console.log('mažu portál');
          break;
        }
      }


      //update portálů
      for (let p = 0; p < this.portals.length; p++) {
        this.portals[p].update();

        if(this.targetCodeChangingCountdown <=0){
          //update adresy jedno uza 10s
          this.portals[p].changeTargetCode();
        }
      }


      //mazání starých propojení
      for (let c = 0; c < this.connections.length; c++) {
        if(this.connections[c].conectionDuration <=0)
        {
          this.connections.splice(c,1);
          //console.log('mažu spojení');
          break;
        }
      }

      //update propojení
      for (let c = 0; c < this.connections.length; c++) {
        this.connections[c].update();
      }


      //mazání starých speciálních zón
      for (let z = 0; z < this.zones.length; z++) {
        
        if(this.zones[z].toErase)
        {
          this.zones.splice(z,1);
          //console.log('mažu zónu');
          break;
        }
      }

      //update propojení
      for (let z = 0; z < this.zones.length; z++) {
        this.zones[z].update();
      }


      //mazání starých explozí
      for (let e = 0; e < this.explosions.length; e++) {
        if(this.explosions[e].duration <=0)
        {
          this.explosions.splice(e,1);
          break;
        }
      }

      //update explozí
      for (let e = 0; e < this.explosions.length; e++) {
        this.explosions[e].update();
      }


      //mazání starých bonusů
      for (let b = 0; b < this.bonusPoints.length; b++) {
        if(this.bonusPoints[b].permanent !== true && this.bonusPoints[b].duration <=0)
        {
          this.bonusPoints.splice(b,1);
          break;
        }
      }

      //update bonusů
      for (let b = 0; b < this.bonusPoints.length; b++) {
        this.bonusPoints[b].update();
      }

      //mazání starých rotátorů
      for (let r = 0; r < this.rotators.length; r++) {
        if(this.rotators[r].permanent !== true && this.rotators[r].duration <=0)
        {
          this.rotators.splice(r,1);
          break;
        }
      }

      //update rotátorů
      for (let r = 0; r < this.rotators.length; r++) {
        this.rotators[r].update();
      }
    }
  };


  /**
   * vrátí existující uložený mapping gamepadu, nebo null, pokud takový mapping neexistuje
   * @param {*} gamapadIdName 
   */
  loadMapping(gamapadIdName) {

    //struktura dat je pole, kde každý záznam má vždy klíč: "gamapadIdName" a data "mappingArray"
    let storageKeyGamepadsMappings = 'GamepadsMappings';

    
    if (typeof localStorage[storageKeyGamepadsMappings] !== 'undefined') {
      //načtení mappingů
      this.gamepadsMappingsArray = JSON.parse(localStorage[storageKeyGamepadsMappings]);
    }

    //pokud nebylo načteno, použije se przdné pole (viz konstruktor)
    let mapping = this.gamepadsMappingsArray.find(ma=>ma.gamapadIdName === gamapadIdName);

    if(mapping!== undefined && mapping!==null)
    {
      //vrátí uložené mapování
      return mapping.mappingArray;
    }
    
    return null;
  }

  /**
   * uloží, nebo aktualizuje uložený mapping gamepadu
   * @param {*} gamapadIdName 
   * @param {*} mappingArray 
   */
  saveOrUpdateMapping(gamapadIdName, mappingArray){

    //struktura dat je pole, kde každý záznam má vždy klíč: "gamapadIdName" a data "mappingArray"
    //this.gamepadsMappingsArray = [];
    let storageKeyGamepadsMappings = 'GamepadsMappings';

    if (typeof localStorage[storageKeyGamepadsMappings] !== 'undefined') {
      //načtení všech uložených mappingů
      this.gamepadsMappingsArray = JSON.parse(localStorage[storageKeyGamepadsMappings]);
    }

    //vyhledání mapingu a jeho update, nebo zapsání
    //pokud nebylo načteno, použije se przdné pole (viz konstruktor)
    let mapping = this.gamepadsMappingsArray.find(ma=>ma.gamapadIdName === gamapadIdName);
    
    if(mapping!== undefined && mapping!==null)
    {
      //UPDATE mappingu
      mapping.mappingArray = mappingArray;
    }
    else
    {
      //INSERT nového mapingu, objekt sestavuju až zde (pro jednoduchost)
      this.gamepadsMappingsArray.push({gamapadIdName:gamapadIdName, mappingArray: mappingArray});
    }

    
    //update hodnot do storage
    localStorage[storageKeyGamepadsMappings] = JSON.stringify(this.gamepadsMappingsArray);
  }




  //smazání celého canvasu
  clearAllCanvas()
  {
        //přemazání info canvasu
        this.infoctx.clearRect(0, 0, this.infocanvas.width, this.infocanvas.height);

        //přemazání info canvasu
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }



  draw () {

    //přemazání info canvasu
    this.infoctx.clearRect(0, 0, this.infocanvas.width, this.infocanvas.height);


    //vykreslování hráčů
    for (let p = 0; p < this.players.length; p++) {
        this.players[p].draw();
    }

    //vykreslování propojení
    for (let c = 0; c < this.connections.length; c++) {
      this.connections[c].draw();
    }

    //kreslení bonusů
    for (let b = 0; b < this.bonusPoints.length; b++) {
      this.bonusPoints[b].draw();
    }

    //vykreslení zóny
    for (let z = 0; z < this.zones.length; z++) {
      this.zones[z].draw();
    }

    //vykreslování portálů (nad zónami)
    for (let p = 0; p < this.portals.length; p++) {
      this.portals[p].draw();
    }


    //vykreslení explozí
    for (let e = 0; e < this.explosions.length; e++) {
      this.explosions[e].draw();
    }

    //kreslení rotátorů
    for (let r = 0; r < this.rotators.length; r++) {
      this.rotators[r].draw();
    }

  };


    //přehrání samostatného zvuku
  playSound (soundIndex, distance) {
      
    if (this.soundEnabled) {
          
      //hlasitost podle vvzdálenosti od objektu
      var volumeNew = 1 - (0.3 * distance);
      if (volumeNew <= 0) volumeNew = 0;
      if (volumeNew > 1) volumeNew = 1;

      //volba aktuálního přehrávače kvůli rozložení množství naráz přehrávaných zvuků
      var actPlayer = null;
      if (this.soundPlayer1.paused) {
          actPlayer = this.soundPlayer1;
      }
      else if (this.soundPlayer2.paused) {
          actPlayer = this.soundPlayer2;
      }
      else if (this.soundPlayer3.paused) {
          actPlayer = this.soundPlayer3;
      }
      else if (this.soundPlayer4.paused) {
        actPlayer = this.soundPlayer4;
      }
      else if (this.soundPlayer5.paused) {
        actPlayer = this.soundPlayer5;
      }

      if (actPlayer !== null && !isNaN(actPlayer.duration)) {
          actPlayer.volume = volumeNew;

          actPlayer.currentTime = this.soundPositions[soundIndex][1];

          //console.log(Date.now());
          actPlayer.play();

          //nastavíme pauzu
          var k = setInterval(
            function (player) {
                player.pause();
                clearInterval(k);

            }, (this.soundPositions[soundIndex][2] - this.soundPositions[soundIndex][1]) * 1000, actPlayer
          );
      }
    }
  };


  //pauzování hry
  pauseGame(){
      
      //pauzovat se dá akorát hra, v loby pauza nebude fungovat
      if(!this.pause && (this.state.gamePhase === gamePhaseEnum.GAME)){
        this.pause = true;
        this.updateGamePhase(gamePhaseEnum.PAUSE);
      }

      //z tutorialu přecházím pouze do pauzy
      if((this.state.gamePhase === gamePhaseEnum.TUTORIAL)){
        this.updateGamePhase(gamePhaseEnum.PAUSE);
      }
  }


  //zobrazení tutorialu hry
  tutorial(){

    this.updateGamePhase(gamePhaseEnum.TUTORIAL);
    console.log('tutorial');
  }


  runLoop() {

    window.requestAnimationFrame(step);

    var self = this;

    function step() {

        self.update();
        self.draw();

        window.requestAnimationFrame(step);
    }
  };

  //změny na velikosti okna
  updateCanvasSize(){

    if(this.canvas.width !== window.innerWidth || this.canvas.height !== window.innerHeight)
    {
      //jen při změnách velikosti - canvas size
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight;

      this.infocanvas.width = window.innerWidth;
      this.infocanvas.height = window.innerHeight;

      this.bgcanvas.width = this.canvas.width / 4;
      this.bgcanvas.height = this.canvas.height / 4;

      //stredy canvasu
      this.stredx = Math.round(this.canvas.width / 2);
      this.stredy = Math.round(this.canvas.height / 2);
      this.bgstredx = Math.round(this.bgcanvas.width / 2);
      this.bgstredy = Math.round(this.bgcanvas.height / 2);
    }

    //vždy - update zakázané oblasti
    this.setState({
      canvasSize :{width: window.innerWidth, height: window.innerHeight}
      ,bottomHudsHeight: this.hudsRef.current.clientHeight
    }, ()=> {

      //update portálů
      this.reCreateBasicPortals();
    });
    
  }


  
  /**
   * Přepíná okno hry do full screenu, volitelně umí také udělat full screen nad vybraným elementem.
   */
  toggleFullScreen() {
    if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen(); 
      }
    }
  }



  componentDidMount(){

    //úprava velikosti canvasu
    this.canvas.width = this.state.canvasSize.width;
    this.canvas.height = this.state.canvasSize.height;

    //úprava velikosti canvasu
    this.infocanvas.width = this.state.canvasSize.width;
    this.infocanvas.height = this.state.canvasSize.height;

    //úprava velikosti canvasu v pozadí
    this.bgcanvas.width = this.state.canvasSize.width /4;
    this.bgcanvas.height = this.state.canvasSize.height /4;

    //stredy canvasu
    this.stredx = Math.round(this.canvas.width / 2);
    this.stredy = Math.round(this.canvas.height / 2);
    this.bgstredx = Math.round(this.bgcanvas.width / 2);
    this.bgstredy = Math.round(this.bgcanvas.height / 2);

    //kontexty canvasů
    this.ctx = this.canvas.getContext('2d');
    this.infoctx = this.infocanvas.getContext('2d');
    this.bgctx = this.bgcanvas.getContext('2d');   
    
    //přidám demo hráče
    this.addAI(this.AIDemoPlayers);
    
    //listener na resize
    window.addEventListener("resize", this.updateCanvasSize.bind(this));
    this.updateCanvasSize();

    ReactGA.initialize('UA-27542164-3');

    //načtení hry
    ReactGA.event({
      category: 'game',
      action: 'load',
      label: 'info'
    });

    this.runLoop();
  }


  
  //vrací aktuální výšku zakázáné oblasti
  getBottomHudsHeight()
  {
    return this.state.bottomHudsHeight;
  }

  //aktualizace service workera
  updateServiceWorker (){
    const serviceWorkerRegistration = this.state.serviceWorkerRegistration;

    if(serviceWorkerRegistration)
    {
      const registrationWaiting = serviceWorkerRegistration.waiting;

      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
        registrationWaiting.addEventListener('statechange', e => {
          if (e.target.state === 'activated') {
            window.location.reload();
          }
        });
      }
    }
  };

  updateSW (){
    this.updateServiceWorker();
  };

  setSW (){
    this.setServiceWorkerUpdated(false,null);
  };

  
  render() {

    const { t } = this.props;

    const phase = this.state.gamePhase;
    const serviceWorkerUpdated = this.state.serviceWorkerUpdated;

    return(
    <div className="App">

      <div className="bgImage1"></div>      
      <div className="bgImage2"></div>

      <canvas id="bgcanvas" ref={bgcanvas => this.bgcanvas = bgcanvas} ></canvas>
      <canvas id="canvas" ref={canvas => this.canvas = canvas}></canvas>
      <canvas id="infocanvas" ref={infocanvas => this.infocanvas = infocanvas}></canvas>

      <MusicPlayer onRef={ref => (this.playerRef = ref)} />

      {serviceWorkerUpdated && 
      <div className="gameUpdateAvailible">
        <div className="gameUpdateButton" onClick={() => this.updateSW()}>{t('game.updateNow')}</div>
        <div className="gameUpdateButton" onClick={() => this.setSW()}>{t('game.updateLater')}</div>
      </div>}



      {phase !== gamePhaseEnum.GAME && phase!== gamePhaseEnum.TUTORIAL &&
      <div className="logo">
        <h1>
            Cybertracers
        </h1>

        <span className="developer"><a className="devLink" href="https://twitter.com/devbobcz">#devbobcz 2020</a></span>
      </div>}

      <div className="TitleScreen">
        {(phase === gamePhaseEnum.LOBBY || phase === gamePhaseEnum.PAUSE) && 
          <TitleScreen 
          players={this.state.playersInfo} 
          startCountdownRunning = {this.state.startCountdownRunning} 
          startCountdown= {this.state.startCountdown} />}

        {phase === gamePhaseEnum.RESULTS && 
          <Results 
          players={this.state.Results} />}        
      </div>

      {(phase === gamePhaseEnum.GAME || phase === gamePhaseEnum.PAUSE || this.state.countDownVisible) && 
        <>
          <Countdown gameCountdownMin={this.state.gameCountdownMin} gameCountdownSec={this.state.gameCountdownSec} phase={phase} />
          <div className="tutorialShow noselect">
            <img src={process.env.PUBLIC_URL + '/help.svg'} alt="powerup portal" className="help" />
            &nbsp;
            {t('tutorial.tutorialShow')}
          </div>
        </>
      }

      {phase === gamePhaseEnum.TUTORIAL &&
        <Tutorial />}

      <div className="bottomHuds noselect" ref={this.hudsRef}>
        {phase === gamePhaseEnum.GAME && this.state.playersInfo.map( pi=> { 
          return (
            <Hud 
              key={pi.index}
              color={greatColors[pi.color]} 
              type={pi.type}
              team={pi.team}
              index={pi.index} 
              score={pi.score} 
              isFirst = {pi.isFirst}
              deaths={pi.deaths}
              blockadeCooldown={pi.blockadeCooldown} 
              gateCooldown={pi.gateCooldown} 
              ramCooldown={pi.ramCooldown}
              portalCooldown={pi.portalCooldown}
              controlledBy={pi.controlledBy}
              portalDial={pi.portalDial}
              energy={pi.energy}
              overDriveCooldown={pi.overDriveCooldown}
              />
          );
          })
        }
      </div>
    </div>
    );
  }
}

export default withTranslation('common')(App);
