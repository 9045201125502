// import { greatColors } from './Enums';

export default class BonusPoint {

    constructor(game, x, y, permanent , duration, score, energy, id, team){

        this.game = game;

        //vzdy menší hodnota, než má konec
        this.x = Math.floor(x);
        this.y = Math.floor(y);
    
        this.color = "#FF8C00";

        if(score === 1)
        {
            this.color = "#00FF00";
        }

        if(score === 3)
        {
            this.color = "#ffe119";
        }

        if(energy > 0)
        {
            this.color = "#e7e4f9";
        }


        //odměna
        this.score = score;
        this.energy = energy;
    
        //životnost
        this.duration = duration;
        //doba kdy se spouští efekt zebrání
        this.takeEfectStart = 500;

        //nezničitelný
        this.permanent  = permanent;

        //identifikátor, pro vyhledání
        this.id = id;

        //team, udává ve kterém byl hráč týmu, hráči nesmí být odměnění za body z vlastního týmu
        this.team = team;
    }


    timeoutsProcess () {

        //odpočet pauzy brány
        if (this.permanent === false && this.duration > 0){
            this.duration -= this.game.delay;
        }

    };

    //update portálů
    update () {

        //zprocesuju odpočty
        this.timeoutsProcess();

    };

    //kreslení portálů
    draw () {

        let fillStyle = this.game.ctx.fillStyle;
        let strokeStyle = this.game.ctx.strokeStyle;
        let InfofillStyle = this.game.infoctx.fillStyle;
        let InfostrokeStyle = this.game.infoctx.strokeStyle;


        //vykreslování bude jen v celých číselch (výpočet však bude v přesnějších)
        let x = this.x;
        let y = this.y;


        if (this.permanent === true || this.duration > 0) {
            

            let size = 1;
            if(this.permanent === false && this.duration < this.takeEfectStart)
            {
                size = (this.duration)/500;
            }


            //exploze pouze v info canvasu
            this.game.infoctx.fillStyle = "#FFFFFF";
            this.game.infoctx.strokeStyle = this.color;

            if(this.score>0)
            {
                //kříž
                this.game.infoctx.beginPath();
                this.game.infoctx.lineWidth = 3;
                this.game.infoctx.moveTo(x - 5 * size, y);
                this.game.infoctx.lineTo(x + 5 * size, y);
                this.game.infoctx.moveTo(x, y - 5* size);
                this.game.infoctx.lineTo(x, y + 5* size);
                this.game.infoctx.stroke();
            }

            if(this.energy>0)
            {
                this.game.infoctx.lineWidth = 2;
                this.game.infoctx.beginPath();
                this.game.infoctx.arc(x, y, 4* size, 0.25* Math.PI , 0 * Math.PI );
                this.game.infoctx.stroke();

                this.game.infoctx.beginPath();
                this.game.infoctx.lineWidth = 2;
                this.game.infoctx.moveTo(x, y);
                this.game.infoctx.lineTo(x + 5 *size, y);
                this.game.infoctx.stroke();

            }


            var angle = 2 * Math.PI;
            if(this.duration < 10000)
            {
                //zmenšující se angle
                angle = (2 * Math.PI / 10000) * this.duration;

                this.game.infoctx.strokeStyle = "#444444";
                //kruh
                this.game.infoctx.lineWidth = 2;
                this.game.infoctx.beginPath();
                this.game.infoctx.arc(x, y, 8* size, 0, 2 * Math.PI);
                this.game.infoctx.stroke();
            }

            this.game.infoctx.strokeStyle = this.color;
            //kruh
            this.game.infoctx.lineWidth = 2;
            this.game.infoctx.beginPath();
            this.game.infoctx.arc(x, y, 8* size, 0, angle);
            this.game.infoctx.stroke();
        }

        this.game.infoctx.fillStyle = InfofillStyle;
        this.game.infoctx.strokeStyle = InfostrokeStyle;

        this.game.ctx.fillStyle = fillStyle;
        this.game.ctx.strokeStyle = strokeStyle;
    };

}
